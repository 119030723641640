import { Component } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-yeast-infection-prevention',
  templateUrl: './yeast-infection-prevention.component.html',
  styleUrls: ['./yeast-infection-prevention.component.scss'],
})
export class YeastInfectionPreventionComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.Fluconazole;

  constructor() {}
}
