import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { InHomeCollectionService, ResultService } from '@Medology/ng-findalab';
import { NewConsultationRequestResponse } from '@models/consultation-request/new-consultation-request-response';
import { TreatmentConsultationRequest } from '@models/consultation-request/treat-my-uti/treatment-consultation-request';
import { LoadCartOptions } from '@models/load-cart-options';
import { OrderResponse } from '@models/order-response';
import { PlaceOrderRequest } from '@models/place-order-request';
import { STDcheckLoadCartResponse } from '@models/stdcheck-load-cart-response';
import { TmuPlaceOrderRequest } from '@models/tmu-place-order-request';
import { TreatMyUTILoadCartRequest } from '@models/treatmyuti-load-cart-request';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { AuthorizationService } from '@services/authorization.service';
import { DataLayerService } from '@services/data-layer.service';
import { DomainService } from '@services/domain.service';
import { FormService } from '@services/form.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';
import { catchError, finalize, Observable, of, retry, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderConsultationRequestService extends OrderService {
  protected override orderPlacedRedirectPage: string = '/order-complete.php';

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected http: HttpClient,
    protected router: Router,
    protected storage: StorageService,
    protected results: ResultService,
    protected formService: FormService,
    protected domainService: DomainService,
    protected authService: AuthorizationService,
    protected inHomeService: InHomeCollectionService,
    protected dataLayerService: DataLayerService
  ) {
    super(
      config,
      document,
      http,
      router,
      storage,
      results,
      formService,
      domainService,
      authService,
      inHomeService,
      dataLayerService
    );
  }

  /**
   * Gets the coupon code from the storage.
   */
  override get couponCode(): string | null {
    return this.storage.coupon?.coupon_code || null;
  }

  /**
   * Avoids the order completed request to be sent.
   */
  override orderCompleted(): Observable<any> {
    return of({});
  }

  /** {@inheritDoc OrderService} */
  override listenForLabSelection(): Subscription {
    return of({}).subscribe();
  }

  /** {@inheritDoc OrderService} */
  override loadCart(options?: LoadCartOptions): Observable<STDcheckLoadCartResponse> {
    const request = new TreatMyUTILoadCartRequest({
      tests: this.storage.tests,
      coupon: this.couponCode,
    });

    return this.loadCartRequest(request);
  }

  /** {@inheritDoc OrderService} */
  override parseCartResponse(response: STDcheckLoadCartResponse): Observable<STDcheckLoadCartResponse> {
    return this.parseCoupon(response);
  }

  /**
   * Posts a treatment consultation request and finalizes the order placement.
   *
   * @param {OrderResponse} response the order response
   */
  override afterOrderPlacement(response: OrderResponse): void {
    const transactionId = response.transaction_id;
    const consultationRequest = this.createConsultationRequest(transactionId);

    this.captureSentryEvent(
      `A request will be made to create a consultation request for a ${this.domainService.getSiteDomain()} order.`,
      'info',
      transactionId,
      consultationRequest
    );

    this.postTreatmentConsultationRequest(consultationRequest, response.hash)
      .pipe(
        retry({
          count: 3,
          delay: 1000,
        }),
        catchError((error) => this.handleConsultationRequestError(error, transactionId)),
        finalize(() => {
          this.storage.treatmentType = consultationRequest.treatment_type;
          this.storage.addressComplete = true;
          super.afterOrderPlacement(response);
        })
      )
      .subscribe();
  }

  /** {@inheritDoc OrderService} */
  protected override getTotalDiscount(): number {
    return 0;
  }

  /**
   * Gets the order request with additional data.
   *
   * @param {string} deviceData the device data for the order request
   *
   * @returns {PlaceOrderRequest} the customized order request
   */
  protected override getOrderRequest(deviceData: string): PlaceOrderRequest {
    const placeOrderRequest = super.getOrderRequest(deviceData) as TmuPlaceOrderRequest;
    placeOrderRequest.tests = this.storage.tests.map((test) => test.id);
    placeOrderRequest.coupon = this.storage.coupon?.coupon_code ?? null;
    placeOrderRequest.lab_id = +this.config.defaultOrderLabId;
    placeOrderRequest.address = this.storage.center.address;
    placeOrderRequest.city = this.storage.center.city;
    placeOrderRequest.state = this.storage.center.state;
    placeOrderRequest.zip = this.storage.center.zip_code;

    return placeOrderRequest;
  }

  /**
   * Posts a consultation request for an order whose main product is a treatment instead of a test.
   *
   * @param {TreatmentConsultationRequest} details the details of the consultation request
   * @param {string} orderHash the hash associated with the order
   *
   * @returns {Observable<NewConsultationRequestResponse>} an observable representing the HTTP post request
   */
  private postTreatmentConsultationRequest(
    details: TreatmentConsultationRequest,
    orderHash: string
  ): Observable<NewConsultationRequestResponse> {
    return this.http.post<any>(`${this.config.analyteCareApi}/api/v1/consultation/treatment/request`, details, {
      headers: new HttpHeaders({ 'X-API-Hash': orderHash, 'X-API-Id': details.order_id }),
    });
  }

  /**
   * Creates a TreatmentConsultationRequest.
   *
   * @param {string} transactionId the order ID
   *
   * @returns {TreatmentConsultationRequest} the consultation request to be sent
   */
  private createConsultationRequest(transactionId: string): TreatmentConsultationRequest {
    return new TreatmentConsultationRequest(
      this.formService.checkout.get('patient') as FormGroup,
      this.formService.checkout.get('medicalHistory') as FormGroup,
      this.storage.center.id,
      transactionId,
      this.storage.treatmentType
    );
  }

  /**
   * Handles errors from the post treatment consultation request.
   *
   * @param {any} error the error from the API
   * @param {string} transactionId the order ID
   *
   * @returns {Observable<null>} an observable that emits null
   */
  private handleConsultationRequestError(error: any, transactionId: string): Observable<null> {
    this.captureSentryEvent(
      `Failed to create a consultation request for a ${this.domainService.getSiteDomain()} order.`,
      'error',
      transactionId,
      error
    );

    return of(null);
  }

  /**
   * Captures an event and sends it to Sentry. This is a temporary measure to debug this issue:
   * https://github.com/Medology/treat-my-uti-wp-theme/issues/329
   *
   * @param {string} message the message to be captured
   * @param {Sentry.SeverityLevel} level the severity level of the event
   * @param {string} orderId the order ID associated with the event
   * @param {any} data additional data to be captured
   */
  private captureSentryEvent(message: string, level: Sentry.SeverityLevel, orderId: string, data?: any): void {
    Sentry.captureEvent({ message, level, extra: { orderId, ...(data && { context_info: data }) } });
  }
}
