import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Result, ResultService } from '@Medology/ng-findalab';
import { Center } from '@models/center';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { OrderService } from '@services/order.service';
import { PartialResultsDeliveryService } from '@services/partial-results-delivery.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-order-better-lab',
  templateUrl: './order-better-lab.component.html',
  styleUrls: ['./order-better-lab.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrderBetterLabComponent implements OnInit {
  /**
   * Center stored in local storage
   */
  center: Center = null;

  /**
   * The order transaction_id
   */
  transaction: string = '';

  /**
   * Flag to determine if current order is free
   */
  free: boolean = false;

  /**
   * Current order payment type
   */
  paymentType: string = '';

  /**
   * Error message to display if lab update/keep process fails
   */
  betterLabError: string = '';

  hasError: boolean = false;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    public domainService: DomainService,
    private storage: StorageService,
    private orderService: OrderService,
    private route: Router,
    private resultService: ResultService,
    private partialResultsDeliveryService: PartialResultsDeliveryService
  ) {
    this.storage.inHomeCollection = false;
  }

  ngOnInit(): void {
    this.orderService.labResultSubscription.unsubscribe();

    this.transaction = this.storage.transactionId;
    this.center = this.storage.center;
    this.free = this.storage.free;
    this.paymentType = this.storage.paymentType;

    this.resultService.resultSelected$.subscribe((result: Result) => {
      this.onLabUpdate(result);
    });
  }

  /**
   * Select the Lab and redirect to the order page
   *
   * @param result
   */
  public onLabUpdate(result: Result): void {
    this.betterLabError = '';
    this.hasError = false;

    this.orderService.updateLab(result.id).subscribe({
      next: this.onSuccessLabUpdate.bind(this),
      error: this.onErrorLabUpdate.bind(this),
    });
  }

  /**
   * Handle Keep Current lab
   */
  public onKeepCurrentLab(): void {
    this.storage.remove('betterlab');
    this.redirect();
  }

  /**
   * Handle the lab update success
   */
  onSuccessLabUpdate(): void {
    this.storage.labChanged = 'yes';
    this.storage.remove('betterlab');
    this.redirect();
  }

  /**
   * Handle the lab update error
   * @param { HttpErrorResponse } error
   */
  onErrorLabUpdate(error): void {
    this.hasError = true;
    this.betterLabError = error.error.message;
  }

  /**
   * Performs redirect.
   */
  redirect(): void {
    if (this.partialResultsDeliveryService.shouldRedirectToPartialResultsDelivery()) {
      this.redirectPartialResultsDelivery();

      return;
    }

    this.redirectDoxyPep();
  }

  /**
   * Redirects to the DoxyPEP treatment page.
   */
  private redirectDoxyPep(): void {
    this.route.navigateByUrl('doxy-pep-treatment');
  }

  /**
   * Redirects to the Partial results delivery page.
   */
  private redirectPartialResultsDelivery(): void {
    this.route.navigateByUrl('partial-results-delivery');
  }
}
