import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionIdGuard {
  constructor(
    private router: Router,
    private storageService: StorageService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {}

  /**
   * Determines if the route page should be loaded.
   *
   * @returns {boolean} true if the user is allowed to activate the route, false otherwise
   */
  canActivate(): boolean {
    if (!!this.storageService.transactionId) {
      return true;
    }

    window.location.href = this.config.siteUrls.tests;

    return false;
  }
}
