import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { FormService } from '@services/form.service';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-upsell-card',
  templateUrl: './upsell-card.component.html',
  styleUrls: ['./upsell-card.component.scss'],
})
export class UpsellCardComponent implements OnInit {
  readonly controlName: string = 'upsell';

  @Input() htmlTitle: string;
  @Input() options: SelectableOption[];
  @Input() question: string;
  @Input() slug: UpsellSlugs;
  @Input() hints: string[];
  @Input() additionalFormsToValidate: FormGroup[] = [];
  @Output() continueButtonPressed: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup;
  processing: boolean = false;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private navigationService: NavigationService,
    private formService: FormService,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Initializes the component by adding the validators to the form control.
   */
  ngOnInit(): void {
    this.processing = false;
    this.setOptions();
    this.setHints();
    this.form = this.formService.getUpsellCardForm();
    this.markSelectedUpsell();
  }

  /**
   * Gets the form control with the name set in the controlName property.
   *
   * @returns {FormControl} the form control
   */
  get control(): FormControl {
    return this.form.get(this.controlName) as FormControl;
  }

  /**
   * Asserts that the control is valid and navigates to the next page.
   */
  async continue(): Promise<void> {
    const formsToValidate = [...this.additionalFormsToValidate, this.form];

    if (formsToValidate.some((form) => form.invalid)) {
      formsToValidate.forEach((form) => form.markAllAsTouched());

      return;
    }

    this.processing = true;
    this.sessionStorageService.upsells = this.updateUpsells(this.getUpsells(), this.control.value);
    this.continueButtonPressed.emit(this.control.value);
    this.navigationService.navigateToNextConsultationRequestPage(this.sessionStorageService.treatmentType);
  }

  /**
   * Sets selectable options for the question if they are not already set.
   */
  private setOptions(): void {
    this.options = this.options || [
      new SelectableOption(QuestionnaireAnswers.Yes, this.slug),
      new SelectableOption(QuestionnaireAnswers.No),
    ];
  }

  /**
   * Sets hints for the question if they are not already set.
   */
  private setHints(): void {
    this.hints = this.hints || [
      `Most patients select this option, and it comes with a small $${
        this.config.consultationRequestUpsellPrices[this.slug]
      } additional charge.`,
    ];
  }

  /**
   * Returns an immutable copy of the upsells array.
   */
  private getUpsells(): string[] {
    return [...this.sessionStorageService.upsells];
  }

  /**
   * Updates the upsells array based on the answer.
   * If the answer is 'No', the upsells are removed from the array.
   * If the answer is 'Yes', the upsells are added to the array.
   *
   * @param {string[]} upsells The upsells array.
   * @param {string}   answer  The answer to the question.
   */
  private updateUpsells(upsells: string[], answer: string): string[] {
    upsells = this.removeUpsellOptions(upsells);

    return answer === QuestionnaireAnswers.No ? upsells : this.addUpsellOption(upsells, answer);
  }

  /**
   * Removes the upsell options from the upsells array.
   *
   * @param {string[]} upsells The upsells array.
   */
  private removeUpsellOptions(upsells: string[]): string[] {
    return upsells.filter((upsell) => !this.options.map((option) => option.value).includes(upsell));
  }

  /**
   * Adds the answer to the upsells array if it is not already included.
   *
   * @param {string[]} upsells The upsells array.
   * @param {string}   answer  The answer to the question.
   */
  private addUpsellOption(upsells: string[], answer: string): string[] {
    return [...upsells, answer];
  }

  /**
   * Marks the selected upsell option in the form control.
   * Useful when the user navigates back to the page.
   */
  private markSelectedUpsell(): void {
    const upsells = this.sessionStorageService.upsells;
    const selectedOption = this.options.find((option) => upsells.includes(option.value));

    if (selectedOption) {
      this.control.setValue(selectedOption.value);
    }
  }
}
