import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { VenmoService } from '@services/external-payments/venmo.service';

@Injectable()
export class VenmoMockService extends VenmoService {
  constructor(protected braintreeService: BraintreeService, protected errorHandlerService: ErrorHandlerService) {
    super(braintreeService, errorHandlerService);
  }

  /**
   * Mocks the Venmo session.
   *
   * @returns {Promise<string>} a promise that resolves with a fake nonce
   */
  override pay(): Promise<string> {
    return Promise.resolve('fake-venmo-account-nonce');
  }
}
