import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isPast, month, year } from 'creditcards/expiration';

export function cardExpirationValidator(monthControl: string, yearControl: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cardMonth = month.parse(control.get(monthControl).value);
    const cardYear = year.parse(control.get(yearControl).value);

    return cardMonth && cardYear && isPast(cardMonth, cardYear) ? { expired: true } : null;
  };
}
