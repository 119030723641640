import { NgForOf } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFormQuestionComponent } from '@components/dynamic-forms/dynamic-form-question/dynamic-form-question.component';
import { TextInputComponent } from '@components/dynamic-forms/text-input/text-input.component';
import { InputTypes } from '@enums/input-types';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { Question } from '@models/dynamic-forms/question';
import { QuestionnaireAnswers } from '@models/dynamic-forms/questionnaire-answers';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { SessionStorageService } from '@services/session-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dynamic-questionnaire-form',
  templateUrl: './dynamic-questionnaire-form.component.html',
  standalone: true,
  imports: [DynamicFormQuestionComponent, NgForOf, TextInputComponent],
})
export class DynamicQuestionnaireFormComponent implements OnInit, OnDestroy {
  @Input() sessionKey: string;
  @Input() questionIds: string[];
  @Input() externalData: ExternalData;
  @Output() showLabFinderModal: EventEmitter<void> = new EventEmitter();

  form: FormGroup;
  questions: Question[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private dynamicFormsService: DynamicFormsService, private sessionStorage: SessionStorageService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.dynamicFormsService.getQuestions(this.questionIds, this.externalData).then((questions) => {
      this.questions = questions;
      this.setAnswersFromStorage();
      this.form = this.dynamicFormsService.toFormGroup(this.questions);
      this.subscribeToFormChanges();
    });
  }

  /**
   * Unsubscribes from all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Loads answers from storage and assigns them to questions.
   */
  private setAnswersFromStorage(): void {
    const answers = this.sessionStorage.getQuestionnaireAnswers(this.sessionKey);
    if (!answers) {
      return;
    }

    this.questions.forEach((question) => (question.value = answers[question.id]));
  }

  /**
   * Subscribes to form changes and updates stored answers.
   */
  private subscribeToFormChanges(): void {
    this.subscriptions.push(
      this.form.valueChanges.subscribe((value) =>
        this.sessionStorage.setQuestionnaireAnswers(this.sessionKey, this.filterOutFileAnswers(value))
      )
    );
  }

  /**
   * Filters out file type answers from the provided questionnaire answers.
   *
   * @param {QuestionnaireAnswers} answers the questionnaire answers to filter
   *
   * @returns {QuestionnaireAnswers} a new object containing answers without file type responses
   */
  private filterOutFileAnswers(answers: QuestionnaireAnswers): QuestionnaireAnswers {
    const answersCopy = { ...answers };
    this.questions.filter(({ type }) => type === InputTypes.File).forEach(({ id }) => delete answersCopy[id]);

    return answersCopy;
  }
}
