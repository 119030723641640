import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ExternalPaymentScript } from '@interfaces/external-payment-script';
import { BitPayInvoice } from '@models/payments/bitpay-invoice';
import { BitPayInvoiceRequest } from '@models/payments/bitpay-invoice-request';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { DomainService } from '@services/domain.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import { ScriptService } from '@services/script.service';
import { Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BitPayService extends ExternalPaymentMethodService implements ExternalPaymentScript {
  bitpayStatus$: Observable<string | null>;
  protected bitpayStatusSubject = new Subject<string | null>();

  private amount: number;

  private readonly failedToLoadMessage = 'BitPay failed to load, please try again later.';
  private window: Window & typeof globalThis;

  constructor(
    protected domainService: DomainService,
    protected scriptService: ScriptService,
    protected http: HttpClient,
    @Inject(DOCUMENT) protected document: Document,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(errorHandlerService);
    this.window = this.document.defaultView;
    this.bitpayStatus$ = this.bitpayStatusSubject.asObservable();
  }

  /**
   * Gets whether BitPay is available.
   */
  override get isAvailable(): boolean {
    return !!this.globalObject;
  }

  /**
   * Gets the BitPay object from the window object. Injected by the external script.
   */
  protected override get globalObject(): any {
    return (this.window as any).bitpay;
  }

  /**
   * Initiates a BitPay session to create and handle an invoice for the specified amount.
   *
   * @param {number} amount the amount to charge the user
   *
   * @returns {Promise<string>} a promise that resolves with the created invoice ID on success
   */
  override pay(amount: number): Promise<string> {
    this.amount = amount;
    this.bitpayStatusSubject.next('Generating BitPay invoice, please wait...');

    return new Promise((resolve, reject) => {
      this.postBitPayInvoice(this.createBitPayInvoiceRequest(amount)).subscribe({
        next: (invoice: BitPayInvoice) => this.handleBitPayInvoiceCreationSuccess(invoice.invoice_id, resolve, reject),
        error: (err: any) => this.handleBitPayInvoiceCreationError(err, reject),
      });
    });
  }

  /**
   * BitPay does not have a payment button to render.
   *
   * @returns {Observable<void>} an empty observable
   */
  override renderPaymentButton(): Observable<void> {
    return of();
  }

  /**
   * Sets the nonce value for the external payment method to the form control.
   *
   * @param {AbstractControl} paymentMethodControl the form control for the payment method
   * @param {string} nonce the nonce value to set
   */
  override setNonceToForm(paymentMethodControl: AbstractControl, nonce: string): void {
    this.validateNonce(nonce);

    const bitPayControl = paymentMethodControl.get('bitpay');
    bitPayControl.get('invoice').setValue(nonce);
    bitPayControl.get('amount').setValue(this.amount * 100);
  }

  /**
   * Loads the BitPay script. It creates the window.bitpay global object.
   *
   * @returns {Observable<void>} an Observable that emits a void value when the BitPay script is loaded
   */
  initialize(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.isAvailable) {
        return observer.next();
      }

      this.scriptService
        .loadScriptWithRetries('https://bitpay.com/bitpay.min.js')
        .then(() => {
          if (!this.isAvailable) {
            Sentry.captureException(new Error('window.bitpay does not exist after BitPay script load'));

            return observer.error();
          }

          observer.next();
        })
        .catch((err: Event) => {
          Sentry.captureException(err);
          observer.error(err);
        });
    });
  }

  /**
   * Handles the success of BitPay invoice creation by showing the BitPay modal and resolving the promise when the
   * invoice is paid.
   *
   * @param {string} invoiceId the ID of the created invoice
   * @param {Function} resolve the function to call when the invoice is successfully handled
   * @param {Function} reject the function to call when the invoice handling is rejected
   */
  protected handleBitPayInvoiceCreationSuccess(invoiceId: string, resolve: Function, reject: Function): void {
    this.bitpayStatusSubject.next('Awaiting BitPay payment...');
    let isPaid = false;
    this.window.addEventListener('message', (event: MessageEvent) => (isPaid = event.data.status === 'paid'), false);
    this.globalObject.onModalWillLeave(() => {
      this.bitpayStatusSubject.next(null);
      if (isPaid) {
        resolve(invoiceId);
      } else {
        reject({ statusCode: 'CANCELED' });
      }
    });
    this.globalObject.showInvoice(invoiceId, {
      v3: true,
    });
  }

  /**
   * Handles errors that occur during the creation of a BitPay invoice.
   *
   * @param {any} err the error that occurred during invoice creation
   * @param {Function} reject the reject function to reject the promise
   */
  private handleBitPayInvoiceCreationError(err: any, reject: Function): void {
    Sentry.captureException(err);
    this.bitpayStatusSubject.next(null);
    reject(this.failedToLoadMessage);
  }

  /**
   * Posts a BitPay invoice to the API.
   *
   * @param {BitPayInvoiceRequest} invoice the BitPay invoice to be posted
   *
   * @returns {Observable<BitPayInvoice>} an observable that resolves to the created BitPay invoice
   */
  private postBitPayInvoice(invoice: BitPayInvoiceRequest): Observable<BitPayInvoice> {
    return this.http.post<BitPayInvoice>(`${this.config.analyteCareApi}/order/bitpay`, invoice);
  }

  /**
   * Creates a BitPay invoice request based on the specified amount.
   *
   * @param {number} amount the amount for which the BitPay invoice is created
   *
   * @returns {BitPayInvoiceRequest} the BitPay invoice request object
   */
  private createBitPayInvoiceRequest(amount: number): BitPayInvoiceRequest {
    return {
      item_name: `${this.domainService.getSiteDomain()} Total`,
      order_total: amount,
      email: '',
    };
  }
}
