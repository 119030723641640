import { Inject, Injectable } from '@angular/core';
import { Gender } from '@enums/gender';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class GenderService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private domainService: DomainService) {}

  /**
   * Determines the allowed genders based on the treatment type, if not set, it will return the default
   *
   * @param {StorageService} storageService The storage service that will be used to get the treatment type
   *
   * @returns a list of allowed gender
   */
  getAllowedTreatmentTypeGenders(storageService: StorageService): Gender[] {
    const allowedTreatmentTypeGender = this.config.order.allowedTreatmentGenders[storageService.treatmentType];

    return allowedTreatmentTypeGender ? [allowedTreatmentTypeGender] : [Gender.Female, Gender.Male];
  }

  /**
   * Gets the invalid gender error message
   *
   * @param {StorageService} storageService The storage service that will be used to get the treatment type
   *
   * @returns the invalid error message
   */
  getGenderInvalidError(storageService: StorageService): string {
    const gender =
      this.config.order.allowedTreatmentGenders[storageService.treatmentType] === Gender.Female ? 'females' : 'males';

    return `${this.domainService.getSiteDomain()}.com can only diagnose ${gender}`;
  }
}
