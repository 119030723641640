import { stdcheckCommonEnvironment } from '@environments/stdcheck/environment.common';

import { environment } from '../environment';

/**
 * Environment variables for use on the STDcheck site, in production environment.
 */
export const stdcheckEnvironment = {
  ...environment,
  ...stdcheckCommonEnvironment,
  googleMapApiKey: 'AIzaSyAVUfSfF7Tc_7UcnlVTF0U6-cboVLRLpsA',
  apiUrl: 'https://api.analytecare.com',
  siteUrls: {
    home: 'https://www.stdcheck.com',
    termsOfService: 'https://www.stdcheck.com/terms-of-service.php',
    myAccount: 'https://my-account.stdcheck.com',
    tests: 'https://www.stdcheck.com/std-test-pricing.php',
    notFound: 'https://www.stdcheck.com/404',
  },
  siteId: '1',
};
