import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { ConsultationStatus } from '@enums/consultation-status';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { LoadingService } from '@services/loading.service';
import { ScriptService } from '@services/script.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-schedule-consultation',
  templateUrl: './schedule-consultation.component.html',
  styleUrls: ['../consultation-request/consultation-request.component.scss', './schedule-consultation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleConsultationComponent implements OnInit, OnDestroy {
  scheduleUrl: SafeResourceUrl;
  scheduleOnceId: string;
  consultationId: string;
  orderId: string;
  hash: string;
  errorMessage: string;
  patientPhone: string;
  routeSubscription: Subscription;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private scriptService: ScriptService,
    private acRoute: ActivatedRoute,
    private element: ElementRef,
    private consultationRequestService: ConsultationRequestService,
    private loadingService: LoadingService,
    private titleService: Title,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.config.titles.consultationRequest);

    this.routeSubscription = this.acRoute.queryParams.subscribe({
      next: (params: Params) => {
        if (!params.scheduleOnceId || !params.order_id || !params.consultationId || !params.hash) {
          this.errorMessage = 'Invalid consultation data, please contact customer service.';
        }

        this.loadingService.toggleLoader(true);
        this.orderId = params.order_id;
        this.consultationId = params.consultationId;
        this.hash = params.hash;
        this.patientPhone = params.contact;
        this.consultationRequestValidation(params.scheduleOnceId);
      },
    });
  }

  /**
   * This method validate if the consultation request was created and initialize OnceHub
   *
   * @param scheduleOnceId Schedule once id
   */
  consultationRequestValidation(scheduleOnceId: string): void {
    //Validate if the order already has schedule a consultation
    this.consultationRequestService.getConsultationRequest(this.consultationId, this.orderId, this.hash).subscribe({
      next: (consultation) => {
        if (consultation.consultation_status === ConsultationStatus.Canceled) {
          this.loadingService.toggleLoader(false);
          this.errorMessage = `Consultation was canceled, please contact customer service.`;

          return;
        }

        this.scheduleOnceId = scheduleOnceId;
        this.scheduleUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(consultation.reschedule_url);
        this.loadingService.toggleLoader(false);
      },
      error: () => {
        this.errorMessage = 'Invalid consultation data, please contact customer service.';
        this.loadingService.toggleLoader(false);
      },
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }
}
