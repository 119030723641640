import { NgIf } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FileDragDropDirective } from '@directives/file-drag-drop.directive';
import { Question } from '@models/dynamic-forms/question';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
  standalone: true,
  imports: [FileDragDropDirective, NgIf, ReactiveFormsModule],
})
export class FileInputComponent {
  @Input() form: FormGroup;
  @Input() question: Question;
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef<HTMLInputElement>;

  constructor() {}

  /**
   * Gets the form control associated with the current question.
   */
  get control(): AbstractControl {
    return this.form.controls[this.question.id];
  }

  /**
   * Updates the form control with file details when a file is selected.
   *
   * @param {FileList} files the list of files selected by the user
   */
  handleFileChange(files: FileList): void {
    if (files.length !== 1) {
      return;
    }

    this.control.patchValue(files[0]);
    this.control.markAsTouched();
  }

  /**
   * Removes the selected file from the form control.
   *
   * @param {Event} event the event triggered when the remove button is clicked
   */
  removeFile(event: Event): void {
    event.stopPropagation();
    this.control.patchValue('');
  }

  /**
   * Triggers the file input dialog to open, allowing the user to select a file.
   */
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }
}
