import { APP_BASE_HREF, CurrencyPipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AddressInputsComponent } from '@components/address-inputs/address-inputs.component';
import { AnimatedButtonComponent } from '@components/animated-button/animated-button.component';
import { CardComponent } from '@components/card/card.component';
import { CheckboxArrayFieldComponent } from '@components/checkbox-array-field/checkbox-array-field.component';
import { ConsultationRequestPatientInfoComponent } from '@components/consultation-request/consultation-request-patient-info/consultation-request-patient-info.component';
import { ConsultationUpsellPaymentCardComponent } from '@components/consultation-request/consultation-upsell-payment-card/consultation-upsell-payment-card.component';
import { DynamicConsultationRequestFormComponent } from '@components/consultation-request/dynamic-consultation-request-form/dynamic-consultation-request-form.component';
import { ConsultationErrorPageComponent } from '@components/consultation-request/error-page/consultation-error-page.component';
import { HeightWeightInputComponent } from '@components/consultation-request/height-weight-input/height-weight-input.component';
import { LabFinderModalComponent } from '@components/consultation-request/lab-finder-modal/lab-finder-modal.component';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { ConsultationRequestHeaderComponent } from '@components/consultation-request-header/consultation-request-header.component';
import { ConsultationRequestTreatmentPreferencesCardComponent } from '@components/consultation-request-treatment-preferences-card/consultation-request-treatment-preferences-card.component';
import { StdPreventionConsultationRequestCompletedComponent } from '@components/doxy-pep/std-prevention-consultation-request-completed/std-prevention-consultation-request-completed.component';
import { DropdownSelectFieldComponent } from '@components/dropdown-select-field/dropdown-select-field.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { FileInputComponent } from '@components/dynamic-forms/file-input/file-input.component';
import { HealthLabsCouponComponent } from '@components/health-labs-coupon/health-labs-coupon.component';
import { IconAndButtonCardComponent } from '@components/icon-and-button-card/icon-and-button-card.component';
import { ModalComponent } from '@components/modal/modal.component';
import { MycoplasmaGenitaliumComponent } from '@components/mycoplasma-genitalium/mycoplasma-genitalium.component';
import { NullComponent } from '@components/null/null.component';
import { OrderComponent } from '@components/order/order.component';
import { AdditionalRecommendedTestingComponent } from '@components/order-address/additional-recommended-testing/additional-recommended-testing.component';
import { OrderAddressComponent } from '@components/order-address/order-address.component';
import { OrderCompleteComponent } from '@components/order-complete/order-complete.component';
import { OrderCompleteInstructionsComponent } from '@components/order-complete/order-complete-instructions/order-complete-instructions.component';
import { OrderCompleteSummaryComponent } from '@components/order-complete/order-complete-summary/order-complete-summary.component';
import { OrderMedicalHistoryComponent } from '@components/order-medical-history/order-medical-history.component';
import { OrderServiceAgreementComponent } from '@components/order-service-agreement/order-service-agreement.component';
import { OrderSummaryComponent } from '@components/order-summary/order-summary.component';
import { PartialResultsDeliveryComponent } from '@components/partial-results-delivery/partial-results-delivery.component';
import { PatientInfoComponent } from '@components/patient-info/patient-info.component';
import { PaymentInfoComponent } from '@components/payment-info/payment-info.component';
import { PaymentMethodIconsComponent } from '@components/payment-info/payment-method-icons/payment-method-icons.component';
import { BitpayButtonComponent } from '@components/place-order/bitpay-button/bitpay-button.component';
import { GooglePayButtonComponent } from '@components/place-order/google-pay-button/google-pay-button.component';
import { PaypalButtonComponent } from '@components/place-order/paypal-button/paypal-button.component';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { VenmoButtonComponent } from '@components/place-order/venmo-button/venmo-button.component';
import { QuestionAnswerFieldComponent } from '@components/question-answer-field/question-answer-field.component';
import { RadioButtonsQuestionCardComponent } from '@components/radio-buttons-question-card/radio-buttons-question-card.component';
import { RadiosErrorFieldComponent } from '@components/radios-error-field/radios-error-field.component';
import { ShopperApprovedComponent } from '@components/shopper-approved/shopper-approved.component';
import { StdConsultationRequestCompletedComponent } from '@components/std-consultation-request-completed/std-consultation-request-completed.component';
import { StdConsultationRequestFormComponent } from '@components/std-consultation-request-form/std-consultation-request-form.component';
import { StdPreventionConsultationRequestFormComponent } from '@components/std-prevention-consultation-request-form/std-prevention-consultation-request-form.component';
import { TestCenterComponent } from '@components/test-center/test-center.component';
import { TreatmentConsultationRequestCompletedComponent } from '@components/treatment-consultation-request-completed/treatment-consultation-request-completed.component';
import { UpgradeComponent } from '@components/upgrade/upgrade.component';
import { UpsellCardComponent } from '@components/upsell-card/upsell-card.component';
import { YesNoDetailsFieldComponent } from '@components/yes-no-details-field/yes-no-details-field.component';
import { YesNoErrorFieldComponent } from '@components/yes-no-error-field/yes-no-error-field.component';
import { AlleviateNauseaAndVomitingComponent } from '@consultation-upsells/alleviate-nausea-and-vomiting/alleviate-nausea-and-vomiting.component';
import { BePreparedForTheUnexpectedComponent } from '@consultation-upsells/be-prepared-for-the-unexpected/be-prepared-for-the-unexpected.component';
import { ClotrimazoleVaginalCreamComponent } from '@consultation-upsells/clotrimazole-vaginal-cream/clotrimazole-vaginal-cream.component';
import { FollowUpStdTestComponent } from '@consultation-upsells/follow-up-std-test/follow-up-std-test.component';
import { MedicationPickupDeliveryComponent } from '@consultation-upsells/medication-pickup-delivery/medication-pickup-delivery.component';
import { MensIntimateWashComponent } from '@consultation-upsells/mens-intimate-wash/mens-intimate-wash.component';
import { NeedADoctorsNoteComponent } from '@consultation-upsells/need-a-doctors-note/need-a-doctors-note.component';
import { ReplenishYourGoodBacteriaComponent } from '@consultation-upsells/replenish-your-good-bacteria/replenish-your-good-bacteria.component';
import { VaginalCreamComponent } from '@consultation-upsells/vagnial-cream/vaginal-cream.component';
import { YeastInfectionPreventionComponent } from '@consultation-upsells/yeast-infection-prevention/yeast-infection-prevention.component';
import { FileDragDropDirective } from '@directives/file-drag-drop.directive';
import { PhoneInputDirective } from '@directives/phone-input.directive';
import { environment } from '@environments/environment';
import { MapMarkerService, NgFindalabModule } from '@Medology/ng-findalab';
import { ImageFallback } from '@pipes/image-fallback';
import * as Sentry from '@sentry/angular';
import { ApplePayService } from '@services/external-payments/apple-pay.service';
import { BitPayService } from '@services/external-payments/bitpay.service';
import { GooglePayService } from '@services/external-payments/google-pay.service';
import { PaypalService } from '@services/external-payments/paypal.service';
import { VenmoService } from '@services/external-payments/venmo.service';
import { ApplePayMockService } from '@tests/services/apple-pay-mock.service';
import { BitPayMockService } from '@tests/services/bitpay-mock.service';
import { GooglePayMockService } from '@tests/services/google-pay-mock.service';
import { PaypalMockService } from '@tests/services/paypal-mock.service';
import { VenmoMockService } from '@tests/services/venmo-mock.service';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { LayoutModule } from './_layout/_layout.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CentsCurrencyPipe } from './common/centsCurrency.pipe';
import { ChooseAPharmacyComponent } from './components/consultation-request/choose-a-pharmacy/choose-a-pharmacy.component';
import { ConsultAttachmentComponent } from './components/consultation-request/consult-attachment/consult-attachment.component';
import { ConsultationRequestComponent } from './components/consultation-request/consultation-request.component';
import { ConsultationRequestMedicalHistoryComponent } from './components/consultation-request/consultation-request-medical-history/consultation-request-medical-history.component';
import { ConsultationOrderSummaryComponent } from './components/consultation-request/consultation-request-payment-info/consultation-order-summary/consultation-order-summary.component';
import { ConsultationRequestPaymentInfoComponent } from './components/consultation-request/consultation-request-payment-info/consultation-request-payment-info.component';
import { ContactInformationComponent } from './components/consultation-request/contact-information/contact-information.component';
import { PersonalInformationComponent } from './components/consultation-request/personal-information/personal-information.component';
import { PrescriptionInformationComponent } from './components/consultation-request/prescription-information/prescription-information.component';
import { TermsOfServiceComponent } from './components/consultation-request/terms-of-service/terms-of-service.component';
import { TreatmentConsultationSubmitComponent } from './components/consultation-request/treatment-consultation-submit/treatment-consultation-submit.component';
import { CookiesDisabledComponent } from './components/cookies-disabled/cookies-disabled.component';
import { DoxyPEPComponent } from './components/doxy-pep/doxy-pep.component';
import { EmbeddedOrderSummaryComponent } from './components/embedded-order-summary/embedded-order-summary.component';
import { ErrorNotificationComponent } from './components/error-notification/error-notification.component';
import { FindTestsModalComponent } from './components/find-tests-modal/find-tests-modal.component';
import { UpsellPaymentInfoComponent } from './components/order-address/upsell-payment-info/upsell-payment-info.component';
import { OrderBetterLabComponent } from './components/order-better-lab/order-better-lab.component';
import { OrderPhoneInputsComponent } from './components/order-phone-inputs/order-phone-inputs.component';
import { PayLaterInfoComponent } from './components/pay-later-info/pay-later-info.component';
import { PaymentStepTabsComponent } from './components/payment-step-tabs/payment-step-tabs.component';
import { ApplePayButtonComponent } from './components/place-order/apple-pay-button/apple-pay-button.component';
import { ScheduleConsultationComponent } from './components/schedule-consultation/schedule-consultation.component';
import { SelectedCenterCardComponent } from './components/selected-center-card/selected-center-card.component';
import { FakeBackendInterceptor } from './interceptors/fake-backend.interceptor';
import { ConfigModule } from './modules/config/config.module';
import { PhoneFormatPipe } from './pipes/phone-format.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    TestCenterComponent,
    AnimatedButtonComponent,
    PatientInfoComponent,
    PaymentInfoComponent,
    PaymentMethodIconsComponent,
    CentsCurrencyPipe,
    OrderSummaryComponent,
    UpgradeComponent,
    PlaceOrderComponent,
    OrderComponent,
    OrderAddressComponent,
    OrderCompleteComponent,
    OrderCompleteInstructionsComponent,
    OrderCompleteSummaryComponent,
    ShopperApprovedComponent,
    OrderBetterLabComponent,
    AdditionalRecommendedTestingComponent,
    NullComponent,
    PaypalButtonComponent,
    ConsultationRequestComponent,
    PersonalInformationComponent,
    ContactInformationComponent,
    ConsultationRequestMedicalHistoryComponent,
    ChooseAPharmacyComponent,
    ConsultationRequestPaymentInfoComponent,
    TermsOfServiceComponent,
    PhoneInputDirective,
    BitpayButtonComponent,
    AddressInputsComponent,
    OrderPhoneInputsComponent,
    ErrorNotificationComponent,
    HealthLabsCouponComponent,
    SelectedCenterCardComponent,
    PaymentStepTabsComponent,
    ScheduleConsultationComponent,
    PrescriptionInformationComponent,
    ConsultationOrderSummaryComponent,
    PhoneFormatPipe,
    SafeHtmlPipe,
    ImageFallback,
    FindTestsModalComponent,
    EmbeddedOrderSummaryComponent,
    UpsellPaymentInfoComponent,
    PayLaterInfoComponent,
    ApplePayButtonComponent,
    CookiesDisabledComponent,
    DoxyPEPComponent,
    OrderMedicalHistoryComponent,
    YesNoDetailsFieldComponent,
    GooglePayButtonComponent,
    PartialResultsDeliveryComponent,
    StdPreventionConsultationRequestFormComponent,
    YesNoErrorFieldComponent,
    RadiosErrorFieldComponent,
    QuestionAnswerFieldComponent,
    OrderServiceAgreementComponent,
    StdPreventionConsultationRequestCompletedComponent,
    StdConsultationRequestFormComponent,
    HeightWeightInputComponent,
    VenmoButtonComponent,
    ConsultationRequestPatientInfoComponent,
    ConsultAttachmentComponent,
    CheckboxArrayFieldComponent,
    ConsultationRequestTreatmentPreferencesCardComponent,
    DropdownSelectFieldComponent,
    YeastInfectionPreventionComponent,
    RadioButtonsQuestionCardComponent,
    MedicationPickupDeliveryComponent,
    TreatmentConsultationRequestCompletedComponent,
    IconAndButtonCardComponent,
    StdConsultationRequestCompletedComponent,
    ReplenishYourGoodBacteriaComponent,
    UpsellCardComponent,
    NeedADoctorsNoteComponent,
    BePreparedForTheUnexpectedComponent,
    TreatmentConsultationSubmitComponent,
    ConsultationRequestHeaderComponent,
    MycoplasmaGenitaliumComponent,
    AlleviateNauseaAndVomitingComponent,
    ModalComponent,
    LabFinderModalComponent,
    MensIntimateWashComponent,
    ClotrimazoleVaginalCreamComponent,
    DynamicConsultationRequestFormComponent,
    FollowUpStdTestComponent,
    VaginalCreamComponent,
    MedicalHistoryQuestionsComponent,
    ConsultationErrorPageComponent,
    ConsultationUpsellPaymentCardComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    NgFindalabModule,
    ReactiveFormsModule,
    ConfigModule.forRoot(),
    LayoutModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DynamicQuestionnaireFormComponent,
    FileDragDropDirective,
    FileInputComponent,
  ],
  providers: [
    provideNgxMask(),
    MapMarkerService,
    CurrencyPipe,
    {
      provide: PaypalService,
      useClass: (window as any).Cypress ? PaypalMockService : PaypalService,
    },
    {
      provide: BitPayService,
      useClass: environment.production ? BitPayService : BitPayMockService,
    },
    {
      provide: ApplePayService,
      useClass: environment.production ? ApplePayService : ApplePayMockService,
    },
    {
      provide: GooglePayService,
      useClass: environment.production ? GooglePayService : GooglePayMockService,
    },
    {
      provide: VenmoService,
      useClass: environment.production ? VenmoService : VenmoMockService,
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    { provide: HTTP_INTERCEPTORS, useClass: FakeBackendInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
