import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InHomeCollectionService, Result, ResultService } from '@Medology/ng-findalab';
import { AnalyteCareCouponDto } from '@models/analyte-care-coupon-dto';
import { Coupon } from '@models/coupon';
import { Fee } from '@models/fee';
import { HealthLabsLoadCartOptions } from '@models/healthlabs-load-cart-options';
import { HealthLabsLoadCartRequest } from '@models/healthlabs-load-cart-request';
import { HealthLabsLoadCartResponse } from '@models/healthlabs-load-cart-response';
import { PlaceOrderRequest } from '@models/place-order-request';
import { ReorderResponse } from '@models/reorder-response';
import { STDcheckLoadCartResponse } from '@models/stdcheck-load-cart-response';
import { Test } from '@models/test';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { AuthorizationService } from '@services/authorization.service';
import { DataLayerService } from '@services/data-layer.service';
import { catchError, finalize, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DomainService } from './domain.service';
import { FormService } from './form.service';
import { OrderService } from './order.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class HealthLabsOrderService extends OrderService {
  findTestsModalState = false;

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected http: HttpClient,
    protected router: Router,
    protected storage: StorageService,
    protected results: ResultService,
    protected formService: FormService,
    protected domainService: DomainService,
    protected authService: AuthorizationService,
    protected inHomeService: InHomeCollectionService,
    protected dataLayerService: DataLayerService
  ) {
    super(
      config,
      document,
      http,
      router,
      storage,
      results,
      formService,
      domainService,
      authService,
      inHomeService,
      dataLayerService
    );
    this.listenForInHomeCollectionSelection();
  }

  /**
   * Load Healthlabs categories.
   */
  getTestCategories(): Observable<any> {
    return this.http.get(`${this.config.siteUrls.tests}/categories?levels=1`);
  }

  /**
   * Remove test from cart.
   *
   * @param {number} entryId The test entry id
   */
  removeTestFromCart(entryId: number): Observable<any> {
    let body = new FormData();
    body.append('entry_id', entryId.toString());

    return this.http.post<any>(`${this.config.apiUrl}/remove`, body);
  }

  /**
   * Clear last order from storage and Craft session.
   */
  override clearLastOrder(): void {
    super.clearLastOrder();
    this.clearCartFromSession().subscribe();
  }

  /**
   * Parses HealthLabs a load cart JSON response to match the project structure.
   *
   * @param {HealthLabsLoadCartResponse} response the API response to parse
   *
   * @returns {Observable<STDcheckLoadCartResponse>} an Observable of the STDcheckLoadCartResponse
   */
  override parseCartResponse(response: HealthLabsLoadCartResponse): Observable<STDcheckLoadCartResponse> {
    const tests = this.mapHlCartResponseTests(response);

    return of({
      fee: response.compliance_fee ? new Fee(response.compliance_fee.name, +response.compliance_fee.value * 100) : null,
      center: response.lab,
      tests: tests,
      discount: response.discount ? response.discount : null,
      coupon: response.coupon ? new Coupon(response.coupon, this.getCartSubtotal(tests)) : null,
      total: 0,
    });
  }

  /**
   * Load HealthLabs cart
   *
   * @param {HealthLabsLoadCartOptions} options The HealthLabs options to load the cart
   *
   * @returns {Observable<STDcheckLoadCartResponse>} an Observable of the STDcheckLoadCartResponse.
   **/
  override loadCart(options?: HealthLabsLoadCartOptions): Observable<STDcheckLoadCartResponse> {
    const tests = this.parseTests(options?.tests || this.testsFromUrl);
    this.storage.remove('center');

    return this.loadCartRequest(
      new HealthLabsLoadCartRequest({
        labId: options?.labId,
        tests: tests,
        accountToken: this.storage.isBaOrder ? this.storage.authToken : null,
        coupon: this.couponCode,
        hasInHomeCollection: options ? options.hasInHomeCollection : this.storage.hasInHomeCollection,
      })
    );
  }

  /**
   * Get a Subscription to the lab selection that chooses the tests in each event.
   *
   * @returns a Subscription to resultSelected$ Observable
   */
  override listenForLabSelection(): Subscription {
    return this.results.resultSelected$.subscribe((result: Result) => {
      this.loadCart(new HealthLabsLoadCartOptions({ labId: result.id })).subscribe();
    });
  }

  /**
   * Update Current Order lab on Healthlabs Craft Session and on Order.
   *
   * @param {number} centerId The lab id to update
   */
  override updateLab(centerId: number): Observable<{ success: Boolean }> {
    return this.http.post(`${this.config.apiUrl}/set-lab`, { labId: centerId }).pipe(
      catchError((error) => {
        Sentry.captureException(error);

        return of(null);
      }),
      switchMap(() => super.updateLab(centerId))
    );
  }

  /**
   * Listen for the in-home collection selection.
   *
   * @returns a Subscription to resultSelected$ Observable
   */
  listenForInHomeCollectionSelection(): void {
    this.inHomeService.bookingSubmitted$.subscribe(() => {
      this.loadCart(new HealthLabsLoadCartOptions({ hasInHomeCollection: true })).subscribe();
    });
  }

  /**
   * Gets the details of the order to reorder.
   *
   * @param {string} reorderId the transaction ID of the order to reorder
   * @param {string} token the account token cookie value
   *
   * @returns {Observable<ReorderResponse>} an observable that emits a ReorderResponse object
   */
  override getReorder(reorderId: string, token: string): Observable<ReorderResponse> {
    return super.getReorder(reorderId, token).pipe(
      switchMap((reorderInfo) => {
        const request = new HealthLabsLoadCartRequest({
          labId: reorderInfo.reorder.center?.id,
          tests: reorderInfo.reorder.tests.map((test) => test.test_id),
          coupon: reorderInfo.reorder.coupon?.coupon_code,
        });

        return this.loadCartRequest(request).pipe(map(() => reorderInfo));
      })
    );
  }

  /**
   * Responds with the coupon code as an observable.
   *
   * TODO: https://github.com/Medology/ng-checkout/issues/1269
   * This method does nothing currently.
   *
   * @param {string} code the coupon code
   */
  protected override getCoupon(code: string): Observable<Coupon> {
    return of({ coupon_code: code } as Coupon);
  }

  /**
   * Calculates the total amount of the discount by adding the amount of discount for
   * buying multiple tests plus the partner testing discount if applicable.
   *
   * @returns the amount of the discount
   */
  protected override getTotalDiscount(): number {
    return (
      (this.storage.discount ? Number(this.storage.discount.value) * 100 : 0) +
      (this.storage.hasPartner ? this.partnerDiscount : 0)
    );
  }

  /**
   * Gets the total coupon discount amount in cents.
   */
  override getTotalCoupons(): number {
    if (!this.storage.coupon) {
      return 0;
    }

    return this.storage.coupon.value * 100;
  }

  /**
   * Navigates to home with the url param order-page-redirect set to true to display the error modal.
   */
  protected override navigateToOrderErrorPage(): void {
    this.document.location.href = `${this.config.siteUrls.tests}/?order-page-redirect=true`;
  }

  /**
   * Creates the order POST request body and add attributes to the object as required for HealthLabs.
   *
   * @param {string} deviceData the device data from Braintree
   *
   * @returns the request body to make a post request to /order
   */
  protected override getOrderRequest(deviceData: string): PlaceOrderRequest {
    const placeOrderRequest = super.getOrderRequest(deviceData);
    placeOrderRequest.tests = this.getOrderTests();
    placeOrderRequest.coupon = this.getOrderCoupons();
    placeOrderRequest.parseGuardianInfo(this.checkoutForm.value.patient);

    if (this.storage.inHomeBookingResult) {
      this.addInHomeCollectionBookingToOrderRequest(placeOrderRequest);
    }

    return placeOrderRequest;
  }

  /**
   * Map HealthLabs cart response tests to project structure.
   *
   * @param {HealthLabsLoadCartResponse} response load cart response from HealthLabs API
   */
  protected mapHlCartResponseTests(response: HealthLabsLoadCartResponse): Test[] {
    return response.tests.map(
      (test): Test => ({
        slug: test.slug,
        id: parseInt(test.test_id),
        name: test.name,
        type: test.type,
        price: parseFloat(test.price) * 100,
        entry_id: parseInt(test.id),
      })
    );
  }

  /**
   * Returns the subtotal based on the tests
   *
   * @param {Test[]} tests tests to calculate the subtotal
   */
  protected getCartSubtotal(tests: Test[]): number {
    return tests.reduce((sum, current) => sum + current.price, 0);
  }

  /**
   * Parses a string or an array of strings into an array of strings.
   *
   * @param {string | string[]} tests the input string or an array of strings to parse
   *
   * @returns {string[]} an array of strings resulting from parsing the input
   */
  private parseTests(tests: string | string[]): string[] {
    if (!tests) {
      return [];
    }

    return !Array.isArray(tests) ? tests.split(',') : tests;
  }

  /**
   * Gets an array of coupons from the coupon and the discount in storage.
   *
   * @returns an array of coupons or null if there are no coupons or discounts
   */
  private getOrderCoupons(): AnalyteCareCouponDto[] | null {
    const coupons = [];
    const storageCoupon = this.storage.coupon;
    if (storageCoupon) {
      coupons.push(new AnalyteCareCouponDto(storageCoupon.coupon_name, storageCoupon.coupon_code, storageCoupon.value));
    }

    const storageDiscount = this.storage.discount;
    if (storageDiscount) {
      coupons.push(storageDiscount);
    }

    return coupons.length > 0 ? coupons : null;
  }

  /**
   * Maps the tests in the storage to the structure required by HealthLabs, including the entry id (reference_id).
   *
   * @returns an array with the test ids and entry ids
   */
  private getOrderTests(): { test_id: string; reference_id: string }[] {
    return this.storage.tests.map((test) => ({ test_id: test.id.toString(), reference_id: test.entry_id.toString() }));
  }

  /**
   * Constructs a GET request to clear the cart from the Craft session via the
   * HealthLabs API.
   *
   * @returns an Observable of the HttpResponse, with a no content body.
   */
  private clearCartFromSession(): Observable<void> {
    return this.http.get<void>(`${this.config.apiUrl}/clear`);
  }

  /**
   * Update discounts in local storage.
   *
   * @param {STDcheckLoadCartResponse} response the load-cart response from HealthLabs
   */
  override saveCartResponse(response: STDcheckLoadCartResponse): void {
    super.saveCartResponse(response);

    if (this.storage.isBaOrder) {
      this.storage.coupon = null;
      this.storage.discount = null;

      return;
    }

    this.storage.discount = response.discount;
  }

  /**
   * Get tests from url.
   */
  override get testsFromUrl(): string | string[] {
    if (this.storage.isBaOrder) {
      return super.testsFromUrl;
    }

    return [];
  }
}
