import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MedicalHistoryQuestionIds } from '@enums/medical-history-question-ids';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { SessionStorageService } from '@services/session-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-medical-history-questions',
  templateUrl: './medical-history-questions.component.html',
})
export class MedicalHistoryQuestionsComponent implements OnInit, OnDestroy {
  @Input() allergiesDetailsInitialValue: string | null = null;
  @Input() medicationDetailsInitialValue: string | null = null;
  @Input() otherConditionsDetailsInitialValue: string | null = null;

  MedicalHistoryQuestionIds: typeof MedicalHistoryQuestionIds = MedicalHistoryQuestionIds;
  form: FormGroup;

  private subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder, private sessionStorage: SessionStorageService) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.initializeForm();
  }

  /**
   * Unsubscribes from all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Initializes the medical history form.
   */
  private initializeForm(): void {
    this.form = this.createMedicalHistoryForm();
    this.addValidatorsToDetailControls();
    this.prefillAnswers();
  }

  /**
   * Creates a form group for the medical history questions.
   *
   * @returns {FormGroup} the medical history form group with configured controls and validation
   */
  private createMedicalHistoryForm(): FormGroup {
    const requiredQuestionIds = [
      MedicalHistoryQuestionIds.OnMedication,
      MedicalHistoryQuestionIds.HasAllergies,
      MedicalHistoryQuestionIds.HasOtherConditions,
    ];

    const formGroupConfig = Object.values(MedicalHistoryQuestionIds).reduce((config, controlId) => {
      // Do not set the value from the beginning as we need the dynamic validators to have been added before
      config[controlId] = new FormControl(null, requiredQuestionIds.includes(controlId) ? Validators.required : null);

      return config;
    }, {});

    return this.formBuilder.group(formGroupConfig);
  }

  /**
   * Retrieves the initial value for a given medical history question ID.
   *
   * @param {MedicalHistoryQuestionIds} questionId the ID of the medical history question
   *
   * @returns {string | null} initial value for the question if available, otherwise null
   */
  private getInitialValue(questionId: MedicalHistoryQuestionIds): string | null {
    const initialValuesMap = {
      [MedicalHistoryQuestionIds.OnMedication]: this.getRadioQuestionInitialValue(this.medicationDetailsInitialValue),
      [MedicalHistoryQuestionIds.MedicationDetails]: this.medicationDetailsInitialValue,
      [MedicalHistoryQuestionIds.HasAllergies]: this.getRadioQuestionInitialValue(this.allergiesDetailsInitialValue),
      [MedicalHistoryQuestionIds.AllergiesDetails]: this.allergiesDetailsInitialValue,
      [MedicalHistoryQuestionIds.HasOtherConditions]: this.getRadioQuestionInitialValue(
        this.otherConditionsDetailsInitialValue
      ),
      [MedicalHistoryQuestionIds.OtherConditionsDetails]: this.otherConditionsDetailsInitialValue,
    };

    return initialValuesMap[questionId] || null;
  }

  /**
   * Retrieves the initial value for a radio question based on the corresponding textarea question's initial value.
   *
   * @param {string} textareaQuestionInitialValue the initial value of the textarea question
   *
   * @returns {QuestionnaireAnswers} "Yes" or "No" based on the textarea value
   */
  private getRadioQuestionInitialValue(textareaQuestionInitialValue: string): QuestionnaireAnswers {
    if (!textareaQuestionInitialValue) {
      return null;
    }

    return textareaQuestionInitialValue.trim().toLowerCase() === QuestionnaireAnswers.No.toLowerCase()
      ? QuestionnaireAnswers.No
      : QuestionnaireAnswers.Yes;
  }

  /**
   * Adds dynamic validators to the detail controls of specific medical history questions.
   */
  private addValidatorsToDetailControls(): void {
    this.addDynamicValidatorsToDetailsControl(
      MedicalHistoryQuestionIds.HasAllergies,
      MedicalHistoryQuestionIds.AllergiesDetails,
      1000
    );
    this.addDynamicValidatorsToDetailsControl(
      MedicalHistoryQuestionIds.OnMedication,
      MedicalHistoryQuestionIds.MedicationDetails,
      1000
    );
    this.addDynamicValidatorsToDetailsControl(
      MedicalHistoryQuestionIds.HasOtherConditions,
      MedicalHistoryQuestionIds.OtherConditionsDetails,
      397
    );
  }

  /**
   * Dynamically adds or removes validators to a control based on the value of a related yes/no question.
   *
   * @param {MedicalHistoryQuestionIds} yesNoQuestionId the ID of the yes/no question that triggers the validation
   * @param {MedicalHistoryQuestionIds} detailsQuestionId the ID of the details control that will have validators
   * added/removed
   * @param {number} maxLength the maximum allowed length for the details control input
   */
  private addDynamicValidatorsToDetailsControl(
    yesNoQuestionId: MedicalHistoryQuestionIds,
    detailsQuestionId: MedicalHistoryQuestionIds,
    maxLength: number
  ): void {
    const control = this.form.get(detailsQuestionId);

    this.subscriptions.push(
      this.form.get(yesNoQuestionId).valueChanges.subscribe((value) => {
        if (value === QuestionnaireAnswers.Yes) {
          control.setValidators([Validators.required, Validators.maxLength(maxLength)]);
        } else {
          control.clearValidators();
        }

        control.updateValueAndValidity();
      })
    );
  }

  /**
   * Prefills the form with answers from session storage or sets default values if no answers are available.
   */
  private prefillAnswers(): void {
    const answers = this.sessionStorage.medicalHistoryQuestionnaireAnswers;
    if (!answers) {
      this.form.patchValue(
        Object.values(MedicalHistoryQuestionIds).reduce((accumulator, questionId) => {
          accumulator[questionId] = this.getInitialValue(questionId);

          return accumulator;
        }, {})
      );

      return;
    }

    this.form.patchValue(answers);
  }
}
