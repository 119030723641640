import { InHomeBookingResult } from '@Medology/ng-findalab';

import { OrderSummary } from './order-summary';
import { ReviewsFeature } from './reviews-feature';

export class OrderCompleteDetails {
  summary: OrderSummary;
  transactionId: string;
  free: boolean;
  reviewsFeature: ReviewsFeature;
  sendSms: boolean;
  hasEmail: boolean;
  isPayLater: boolean;
  hash: string;
  inHomeBookingResult?: InHomeBookingResult;
  email?: string;

  constructor(
    summary: OrderSummary,
    transactionId: string,
    free: boolean,
    reviewsFeature: ReviewsFeature,
    sendSms: boolean,
    hasEmail: boolean,
    isPayLater: boolean,
    hash: string,
    inHomeBookingResult?: InHomeBookingResult,
    email?: string
  ) {
    this.summary = summary;
    this.transactionId = transactionId;
    this.free = free;
    this.reviewsFeature = reviewsFeature;
    this.sendSms = sendSms;
    this.hasEmail = hasEmail;
    this.isPayLater = isPayLater;
    this.hash = hash;
    this.inHomeBookingResult = inHomeBookingResult;
    this.email = email;
  }
}
