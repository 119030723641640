import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Validators } from '@angular/forms';
import {
  InHomeBookingResult,
  InHomeCollectionService as FindalabIHCService,
  Map,
  Result,
  ResultService,
} from '@Medology/ng-findalab';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { FormService } from '@services/form.service';
import { InHomeCollectionService } from '@services/in-home-collection.service';
import { LoadingService } from '@services/loading.service';
import { MapsLoadService } from '@services/maps-load.service';
import { OrderService } from '@services/order.service';
import { PscService } from '@services/psc.service';
import { StorageService } from '@services/storage.service';
import { Observable, of, Subscription } from 'rxjs';

@Component({
  selector: 'app-test-center',
  templateUrl: './test-center.component.html',
  styleUrls: ['./test-center.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestCenterComponent implements OnInit {
  @Input() apiUrl: string;
  @Input() showHeader: boolean = true;
  @Input() inHomeCollection: boolean = true;
  @Input() ihcProviderIds: number[];
  @Input() set reEnableButtons(_reEnableButtons: boolean) {
    if (_reEnableButtons) {
      this.allButtonsDisabled = false;
    }
  }
  allButtonsDisabled: boolean = false;

  map: Map = null;
  changingLab: boolean = false;
  appIsLoading: boolean = false;
  pscError: string | null = null;
  inHomeScheduling: boolean = false;
  previouslySelectedLab: Result = null;

  private readonly invalidLabError =
    'Sorry, unfortunately, the lab you chose cannot provide all of the tests in your cart. Please choose a new lab below.';
  private readonly invalidIHCProviderError =
    'Sorry, unfortunately, we cannot conduct an in-home collection at your chosen appointment time for some of the tests in your cart. Please try again or select a nearby lab to visit.';

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private resultService: ResultService,
    private inHomeService: FindalabIHCService,
    private storage: StorageService,
    private formService: FormService,
    private mapsLoadService: MapsLoadService,
    private orderService: OrderService,
    private domainService: DomainService,
    private loadingService: LoadingService,
    private pscService: PscService,
    private ihcService: InHomeCollectionService
  ) {}

  /**
   * Loads the map.
   */
  loadMap(): void {
    this.mapsLoadService.getGoogleMapApi(this.config.googleMapApiKey).subscribe(() => {
      this.getNetworksFromTestsAsObservable().subscribe((networks) => {
        if (this.storage.center) {
          this.validateSelectedLabNetwork(networks);
        }

        if (this.storage.inHomeBookingResult) {
          this.validateInHomeCollectionProviderNetwork(networks);
        }

        this.map = new Map(this.getMapConfig(this.resultService.getPostalCode(), networks));
      });
    });
  }

  /**
   * Returns an observable that gets the networks for the map configuration.
   */
  getNetworksFromTestsAsObservable(): Observable<string[]> {
    if (!this.config.getNetworksFromTests) {
      return of([]);
    }

    return this.pscService.getTestNetworks();
  }

  /**
   * Configures the map.
   */
  ngOnInit(): void {
    this.loadListeners();
    this.initReorder();
    this.setTestingLocationResult();
  }

  /**
   * Gets the selected center label set on environment.
   */
  get selectedCenterLabel(): string {
    return this.config.titles.selectedCenterLabel;
  }

  /**
   * Gets the stored lab.
   */
  get lab(): Result | null {
    const labProperties = this.storage.center;

    return labProperties ? new Result(labProperties) : null;
  }

  /**
   * Gets the In Home Booking Result.
   */
  get inHomeResult(): InHomeBookingResult | null {
    return this.storage.inHomeBookingResult;
  }

  /**
   * Determines whether a lab is stored already or not.
   */
  get hasLab(): boolean {
    return !!this.lab;
  }

  /**
   * Determine if better lab exists.
   */
  get hasBetterLab(): boolean {
    return !!this.storage.betterLab;
  }

  /**
   * Determine if in home booking result exists.
   */
  get hasInHomeBookingResult(): boolean {
    return !!this.storage.inHomeBookingResult;
  }

  /**
   * Determines if the map should be displayed or not.
   */
  get shouldDisplayMap(): boolean {
    return (
      ((!this.hasLab && !this.hasInHomeBookingResult) || this.changingLab || this.hasBetterLab) && !this.appIsLoading
    );
  }

  /**
   * Determines the title of the first step.
   */
  get step1Title(): string {
    return this.hasInHomeBookingResult || this.inHomeScheduling
      ? 'In-Home Collection Address'
      : `${this.config.titles.step1Title}`;
  }

  /**
   * Method to trigger the center change.
   */
  changeLab(): void {
    if (this.storage.zipcode) {
      this.map.postalCode = this.storage.zipcode;
    }

    this.storage.fee = null;
    this.storage.inHomeBookingResult = null;
    this.changingLab = !this.changingLab;
  }

  /**
   * Gets map config object.
   */
  getMapConfig(zipcode: string, networks: string[] = []): Map {
    let map = {
      searchUrl: {
        hostname: `${this.config.analyteCareApi}/api/v1`,
        path: this.config.ngFindalab.searchEndpoint,
      },
      inHomeServiceAreaUrl: {
        hostname: `${this.config.analyteCareApi}/api/v1`,
        path: '/in-home/service-area',
      },
      inHomeAvailabilityUrl: {
        hostname: `${this.config.analyteCareApi}/api/v1`,
        path: '/in-home/availability',
      },
      apiKey: this.config.googleMapApiKey,
      testing: !this.config.production,
      searchFunction: {
        onlyNetworks: networks,
        onlyStates: this.config.ngFindalab.filterByStates,
        excludeStates: this.config.ngFindalab.excludeStates,
        filterByOrder: '',
        filterByTests: null,
        filterBySite: '',
      },
      inputGroup: {
        container: this.config.ngFindalab.inputGroup.container,
        field: this.config.ngFindalab.inputGroup.field,
        button: this.config.ngFindalab.inputGroup.button,
      },
      lab: {
        phone: {
          show: this.config.ngFindalab.showPhone,
          text: 'Phone:',
        },
        button: {
          show: true,
          text: this.config.ngFindalab.labButtonText,
          class: this.config.styles.testCenter.labButtonClass,
        },
        icon: {
          show: true,
          class: this.config.ngFindalab.labIconClass,
        },
        recommended: {},
      },
      search: {
        title: this.config.ngFindalab.searchTitle,
        description: this.config.ngFindalab.searchDescription,
        button: {
          class: `button ${this.config.styles.testCenter.searchButtonClass}`,
          loadingText: '',
          text: this.config.ngFindalab.searchButtonText,
          icon: {
            show: this.config.ngFindalab.showSearchIcon,
            class: 'fa fa-search',
            loadingClass: 'fa fa-spinner fa-spin',
          },
        },
        placeholder: 'Enter your zip',
        inputType: 'tel',
        showDescription: true,
        messageNoResults: this.config.ngFindalab.searchMessageNoResults,
      },
      userLocation: {
        showOption: true,
        icon: 'fa fa-map-marker',
        msg: this.config.ngFindalab.locateMsg,
        loading: {
          icon: 'fa fa-spin fa-spinner',
          msg: 'Searching current location...',
        },
      },
      dayOfWeekFilter: {
        showOption: this.config.ngFindalab.showDayOfWeekFilter,
        radioAllText: 'All',
        radioDaysText: {
          0: null,
          1: null,
          2: null,
          3: null,
          4: null,
          5: null,
          6: 'Open Saturdays',
        },
      },
      postalCode: zipcode ?? null,
      mobileBreakpoint: 848,
      googleMaps: {
        defaultLat: 39.97712,
        defaultLong: -99.587403,
        geoCoder: null,
        infoWindow: null,
        initialZoom: 4,
        map: null,
        markers: [],
        resultsZoom: this.config.ngFindalab.resultsZoom,
        labMarkerFillColor: this.config.styles.testCenter.googleMapsLabMarkerFillColor,
        recommendedMarkerFillColor: this.config.styles.testCenter.googleMapsRecommendedMarkerFillColor,
        markerHoverFillColor: this.config.styles.testCenter.googleMapsMarkerHoverFillColor,
      },
      inHomeCollection: {
        enabled: this.inHomeCollection,
        addressPageTitle: 'Where would you like us to send a nurse for your sample collection?',
        addressPageDescription: '',
        onlyProviderIds: this.storage.providerIds,
      },
      mapId: this.config.findalabMapId,
    };

    if (this.config.ngFindalab.filterByTestAndSite && !this.hasBetterLab) {
      map.searchFunction.filterByTests = { key: 'id', values: this.storage.tests.map((test) => test.id) };
      map.searchFunction.filterBySite = this.config.siteId;
    }

    if (this.hasBetterLab) {
      map.searchFunction.filterByOrder = this.storage.transactionId;
      map.lab.recommended = {
        show: true,
        class: '',
        text: 'highly recommended',
      };
    }

    return map as Map;
  }

  /**
   *  Loads the map when it's a re-order and also set the center data when it is not a HealthLabs domain.
   */
  private initReorder(): void {
    if (!this.orderService.reorderData) {
      return;
    }

    if (this.orderService.reorderData?.center && !this.domainService.isHealthlabsDomain()) {
      this.storage.center = this.orderService.reorderData.center;
    }

    this.loadMap();
  }

  /**
   * Method that loads all listeners.
   */
  private loadListeners(): void {
    this.listenForLoadingStatusChanges();
    this.listenForTestsSubjectChanges();
    this.listenForResultSelection();
    this.listenForLabIdStatusChanges();
    this.listenForBookingSubmission();
    this.listenForInHomeCollectionSchedule();
  }

  /**
   * Get a Subscription to the loading status changes.
   *
   * @returns a Subscription to loading$ Observable.
   */
  private listenForLoadingStatusChanges(): Subscription {
    return this.loadingService.loading$.subscribe((loadingStatus) => (this.appIsLoading = loadingStatus));
  }

  /**
   * Get a Subscription to tests subject changes.
   *
   * @returns a Subscription to storage.testsSubject Observable.
   */
  private listenForTestsSubjectChanges(): Subscription {
    if (this.domainService.isTreatMyUtiDomain() || this.storage.betterLab) {
      return of([]).subscribe(() => this.loadMap());
    }

    return this.storage.testsSubject.subscribe(() => this.loadMap());
  }

  /**
   * Get a Subscription to the result selection event.
   *
   * @returns a Subscription to resultSelected$ Observable.
   */
  private listenForResultSelection(): Subscription {
    return this.resultService.resultSelected$.subscribe((result: Result) => {
      this.setLabResult(result);
      this.changingLab = false;
      this.pscError = null;
    });
  }

  /**
   * Get a Subscription to the lab id status changes.
   *
   * @returns a Subscription to lab_id checkout form observable.
   */
  private listenForLabIdStatusChanges(): Subscription {
    return this.formService.checkout.get('lab_id').statusChanges.subscribe(() => this.verifyTestsCanBeOrdered());
  }

  /**
   * Get a Subscription to the booking submission event.
   *
   * @returns a Subscription to bookingSubmitted$ Observable.
   */
  private listenForBookingSubmission(): Subscription {
    return this.inHomeService.bookingSubmitted$.subscribe((result: InHomeBookingResult) => {
      this.setInHomeBookingResult(result);
      this.inHomeScheduling = false;
      this.changingLab = false;
    });
  }

  /**
   * Get a Subscription to the scheduling event.
   *
   * @returns a Subscription to scheduling$ Observable.
   */
  private listenForInHomeCollectionSchedule(): Subscription {
    return this.inHomeService.scheduling$.subscribe((status: boolean) => {
      this.inHomeScheduling = status;
      if (this.inHomeScheduling) {
        this.previouslySelectedLab = this.storage.center;
        this.setLabValue(null);
      } else {
        this.setLabValue(this.previouslySelectedLab);
      }
    });
  }

  /**
   * Sets a PSC error variable when the selected lab can not provide the selected HealthLab's tests.
   */
  private verifyTestsCanBeOrdered(): void {
    if (!this.hasLab || !this.config.verifyTestsCanBeOrdered) {
      return;
    }

    this.pscService
      .verifyTestsAreAvailableAtLab(
        this.lab.id,
        this.storage.tests.map((test) => test.id)
      )
      .subscribe((pscs: Result[]) => {
        if (!pscs.map((psc) => psc.id).includes(this.lab?.id)) {
          this.pscError = this.invalidLabError;
          this.changingLab = true;
        }
      });
  }

  /**
   * Set the result control validators based if is an in-home collection or not.
   *
   * @param {boolean} isInHomeCollection The expected control to have the result validator.
   */
  private setResultControlValidators(isInHomeCollection: boolean): void {
    const labControl = this.formService.checkout.get('lab_id');
    const bookingControl = this.formService.checkout.get('booking_id');

    this.formService.resetControl(labControl);
    this.formService.resetControl(bookingControl);
    this.formService.setValidator(isInHomeCollection ? bookingControl : labControl, [Validators.required]);
  }

  /**
   * Sets the lab value on LabControl and local storage.
   *
   * @param {Result} lab The lab to be set.
   */
  private setLabValue(lab: Result): void {
    this.storage.center = lab;
    this.formService.checkout.get('lab_id').setValue(lab?.id ?? null);
  }

  /**
   * Sets the lab value on LabControl and local storage.
   *
   * @param {InHomeBookingResult} inHomeBookingResult The in-home booking result to be set.
   */
  private setInHomeBookingValue(inHomeBookingResult: InHomeBookingResult): void {
    this.storage.inHomeBookingResult = inHomeBookingResult;
    this.formService.checkout.get('booking_id').setValue(inHomeBookingResult?.bookingSlot?.key ?? null);
  }

  /**
   * Sets the lab result.
   *
   * @param {Result} lab The lab.
   */
  private setLabResult(lab: Result): void {
    this.setResultControlValidators(false);
    this.setLabValue(lab);
    this.setInHomeBookingValue(null);
  }

  /**
   * Sets in home booking result.
   *
   * @param {InHomeBookingResult} inHomeBookingResult The in home booking result.
   */
  private setInHomeBookingResult(inHomeBookingResult: InHomeBookingResult): void {
    this.setResultControlValidators(true);
    this.setInHomeBookingValue(inHomeBookingResult);
    this.setLabValue(null);
  }

  /**
   * load the lab or in home booking result.
   */
  private setTestingLocationResult(): void {
    if (this.storage.inHomeBookingResult) {
      this.setInHomeBookingResult(this.storage.inHomeBookingResult);
    } else if (this.storage.center) {
      this.setLabResult(this.storage.center);
    }
  }

  /**
   * Validates the selected lab network.
   *
   * @param {string[]} networks the networks that can support the selected tests
   */
  private validateSelectedLabNetwork(networks: string[]): void {
    if (!this.config.validateSelectedLabNetwork || networks.includes(this.storage.center.network.name)) {
      return;
    }

    this.pscError = this.invalidLabError;
    this.setLabValue(null);
  }

  /**
   * Validates if the In-Home Collection (IHC) provider's networks include any of the specified networks.
   *
   * @param {string[]} networks the list of networks to validate against the provider's networks
   */
  private validateInHomeCollectionProviderNetwork(networks: string[]): void {
    if (!this.config.getNetworksFromTests) {
      return;
    }

    this.ihcService
      .getIHCProviderAssociatedNetworks(this.storage.inHomeBookingResult.bookingSlot.providerId)
      .subscribe((providerNetworks) => {
        if (providerNetworks.map((network) => network.name).some((network) => networks.includes(network))) {
          return;
        }

        this.pscError = this.invalidIHCProviderError;
        this.setLabResult(null);
      });
  }
}
