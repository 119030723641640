import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { PaymentTypes } from '@enums/payment-types';
import { environment } from '@environments/environment.prod';
import { HasBetterLabShowResponse } from '@models/has-better-lab-show-response';
import { MycoplasmaGenitaliumFaq } from '@models/mycoplasma-genitalium-faq';
import { MycoplasmaGenitaliumResponse } from '@models/mycoplasma-genitalium-response';
import { OrderResponse } from '@models/order-response';
import { OrderUpsell } from '@models/order-upsell';
import { UpsellStoreRequest } from '@models/requests/upsell-store.model';
import { Upsell } from '@models/upsell';
import { UpsellStoreResponse } from '@models/upsell-store-response.model';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ErrorHandlerService } from '@services/error-handler.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { MycoplasmaGenitaliumService } from '@services/mycoplasma-genitalium.service';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';
import { PartialResultsDeliveryService } from '@services/partial-results-delivery.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-mycoplasma-genitalium',
  templateUrl: './mycoplasma-genitalium.component.html',
  styleUrls: ['./mycoplasma-genitalium.component.scss'],
})
export class MycoplasmaGenitaliumComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;

  mycoplasmaGenitaliumResponse: MycoplasmaGenitaliumResponse;
  mycoplasmaGenitaliumForm: FormGroup;
  shouldExpand: boolean = false;
  processing: boolean = false;
  rechargeAttempt: boolean = false;
  showPaymentInformation: boolean = false;
  submissionErrors: string[] = [];

  /** The MycoplasmaGenitalium test. */
  readonly mycoplasmaGenitaliumTest: OrderUpsell = environment.mycoplasmaGenitaliumUpsell;

  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private router: Router,
    private storageService: StorageService,
    private orderService: OrderService,
    private errorHandlerService: ErrorHandlerService,
    private partialResultsDeliveryService: PartialResultsDeliveryService,
    private mycoplasmaGenitaliumService: MycoplasmaGenitaliumService,
    private loadingService: LoadingService,
    private navigationService: NavigationService
  ) {}

  /**
   * Gets the price of the MycoplasmaGenitalium test.
   */
  get mycoplasmaGenitaliumPrice(): number {
    return Number(this.mycoplasmaGenitaliumResponse.price);
  }

  /**
   * Gets the order details.
   */
  get order(): OrderResponse {
    return this.storageService.order;
  }

  /**
   * Initializes the component.
   * If the test is ordered, it continues the ordering workflow.
   * If the test is not ordered, it fetches the Mycoplasma Genitalium content and displays it.
   */
  ngOnInit(): void {
    this.loadingService.toggleLoader(true);

    if (this.mycoplasmaGenitaliumService.isMycoplasmaGenitaliumTestOrdered()) {
      this.continueOrderingWorkflow();

      return;
    }

    this.getMycoplasmaGenitaliumContent();
  }

  /**
   * Toggles the content of the MycoplasmaGenitalium content.
   */
  toggleContent(): void {
    this.shouldExpand = !this.shouldExpand;
  }

  /**
   * Toggles the content of the MycoplasmaGenitalium FAQ.
   *
   * @param { MycoplasmaGenitaliumFaq } faq the FAQ to toggle
   */
  toggleFAQ(faq: MycoplasmaGenitaliumFaq): void {
    faq.isExpanded = !faq.isExpanded;
  }

  /**
   * Gets the content for the MycoplasmaGenitalium upsell.
   */
  getMycoplasmaGenitaliumContent(): void {
    this.mycoplasmaGenitaliumService.getMycoplasmaGenitaliumContent().subscribe({
      next: (response) => {
        this.mycoplasmaGenitaliumResponse = response;
        this.mycoplasmaGenitaliumResponse.faq_content.faqs.map((faq) => (faq.isExpanded = false));
        this.loadingService.toggleLoader(false);
      },
      error: () => this.continueOrderingWorkflow(),
    });
  }

  /**
   * Determine workflow to perform recharge or manual payment.
   */
  submit(): void {
    this.submissionErrors = [];
    this.processing = true;

    if (this.rechargeAttempt) {
      this.submitPayment();

      return;
    }

    this.rechargeAttempt = true;

    if (this.storageService.paymentType === PaymentTypes.PayLater) {
      this.displayPaymentInformation();
      this.processing = false;

      return;
    }

    this.submitRecharge();
  }

  /**
   * Continues the ordering workflow.
   */
  continueOrderingWorkflow(): void {
    this.processing = true;
    this.orderService.showOrderBetterLab().subscribe({
      next: this.handleSuccesfulShowOrderBetterLab.bind(this),
      error: this.handleErrorShowOrderBetterLab.bind(this),
    });
  }

  /**
   * Submits the payment.
   */
  private submitPayment(): void {
    this.placeOrderComponent.processing = true;
    this.orderService
      .storeUpsell(
        new UpsellStoreRequest(
          this.mycoplasmaGenitaliumTest,
          undefined,
          this.mycoplasmaGenitaliumForm.get('payment').value,
          this.storageService.patient.name
        )
      )
      .subscribe({
        next: this.handleSuccessfulStoreUpsell.bind(this, this.mycoplasmaGenitaliumTest),
        error: (error) => {
          this.placeOrderComponent.processing = false;
          this.handleRequestError(error);
        },
      });
  }

  /**
   * Submits the recharge.
   */
  private submitRecharge(): void {
    const mycoplasmaGenitaliumUpsell = new Upsell(this.mycoplasmaGenitaliumTest.name);
    this.orderService.storeUpsell(new UpsellStoreRequest(mycoplasmaGenitaliumUpsell, PaymentTypes.Recharge)).subscribe({
      next: this.handleSuccessfulStoreUpsell.bind(this, this.mycoplasmaGenitaliumTest),
      error: (error) => {
        this.displayPaymentInformation();
        this.handleRequestError(error, false);
      },
    });
  }

  /**
   * Displays the payment information.
   */
  private displayPaymentInformation(): void {
    this.initPaymentForm();
    this.showPaymentInformation = true;
  }

  /**
   * Initializes the additional recommended testing form.
   */
  private initPaymentForm(): void {
    this.mycoplasmaGenitaliumForm = this.formBuilder.group({
      tests: new FormGroup({}),
      payment: this.formService.getNewPaymentForm(),
    });
    (this.mycoplasmaGenitaliumForm.controls.tests as FormGroup).addControl(
      this.mycoplasmaGenitaliumTest.slug,
      new FormControl(true)
    );
  }

  /**
   * Handler for the StoreUpsell request when it succeeds.
   *
   * @param { Upsell }              upsell              the upsell test that was successfully placed
   * @param { UpsellStoreResponse } upsellStoreResponse the successful response object
   */
  private handleSuccessfulStoreUpsell(upsell: Upsell, upsellStoreResponse: UpsellStoreResponse): void {
    if (this.mycoplasmaGenitaliumForm) {
      this.storageService.paymentType = this.mycoplasmaGenitaliumForm.get('payment').value.method;
    }

    this.orderService.saveUpsellTestInStorage(this.mycoplasmaGenitaliumTest);

    if (upsellStoreResponse.transaction_id) {
      let upsellTransactionIds = this.storageService.upsell;
      upsellTransactionIds.push(upsellStoreResponse.transaction_id);
      this.storageService.upsell = upsellTransactionIds;
    }

    this.storageService.datalayerPool = [upsell];
    this.continueOrderingWorkflow();
  }

  /**
   * General purpose error handler for failing requests
   *
   * @param { HttpErrorResponse } error The response error object
   * @param { boolean } setSubmissionErrors Whether to set the submission errors or not
   */
  private handleRequestError(error: HttpErrorResponse, setSubmissionErrors: boolean = true): void {
    this.processing = false;
    this.submissionErrors = setSubmissionErrors ? this.errorHandlerService.handleResponseError(error) : [];
  }

  /**
   * Handler for the ShowOrderBetterLab request when it succeeds.
   *
   * @param { HasBetterLabShowResponse } hasBetterLabShowResponse the successful response object
   */
  private handleSuccesfulShowOrderBetterLab(hasBetterLabShowResponse: HasBetterLabShowResponse): void {
    this.loadingService.toggleLoader(false);
    this.processing = false;
    this.storageService.betterLab = hasBetterLabShowResponse.has_better_lab;
    this.router.navigate([this.navigationService.getNextNavigationUrl()]);
  }

  /**
   * Handler for the ShowOrderBetterLab request when it fails.
   *
   * @param { HttpErrorResponse } error The response error object
   */
  private handleErrorShowOrderBetterLab(error: HttpErrorResponse): void {
    this.loadingService.toggleLoader(false);
    this.processing = false;
    this.router.navigate([this.navigationService.getNextNavigationUrl()]);
  }
}
