import { ConsultationRequestOrderStatus } from '@enums/consultation-request-order-status';
import { Account } from '@models/account';
import { ConsultationRequest } from '@models/consultation-request/consultation-request';
import { CustomerAddressResponse } from '@models/customer-address-response';
import { OrderTest } from '@models/order-test';
import { Recharge } from '@models/recharge';

export class ConsultationRequestOrderDetail {
  customer_id: string;
  account: Account;
  address: CustomerAddressResponse;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  date_of_birth: Date;
  tests: OrderTest[];
  lab_order_tests: any[];
  recharge: Recharge;
  gender: string;
  race: string;
  ethnicity: string;
  sex_with: string;
  pregnant: string;
  last_menstrual_date: string;
  due_date: string;
  symptoms: string;
  transaction_id: string;
  hash: string;
  consultationRequest: ConsultationRequest;
  last_prescribed_at: string;
  status: ConsultationRequestOrderStatus;

  constructor(consultation: { [key: string]: any } = {}) {
    Object.assign(this, consultation);
    this.date_of_birth = this.parseDate(consultation.date_of_birth);
  }

  /**
   * Removes the time from the date string & parse a date string into a Date object.
   *
   * @param {string} date The date
   */
  private parseDate(date: string): Date {
    const dateOnlyString = date.replace(' ', 'T');

    return new Date(dateOnlyString);
  }
}
