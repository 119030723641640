import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MedicalHistoryQuestionsComponent } from '@components/consultation-request/medical-history-questions/medical-history-questions.component';
import { DynamicQuestionnaireFormComponent } from '@components/dynamic-forms/dynamic-questionnaire-form/dynamic-questionnaire-form.component';
import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { DynamicFormsService } from '@services/dynamic-forms.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { LoadingService } from '@services/loading.service';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-consultation-request-form',
  styleUrl: './consultation-request-form.component.scss',
  template: '',
})
export abstract class ConsultationRequestFormComponent {
  @ViewChild('medicalQuestionsForm', { static: false })
  protected medicalQuestionsForm: DynamicQuestionnaireFormComponent;
  @ViewChild('medicalHistoryQuestionsForm', { static: false })
  protected medicalHistoryQuestionsForm: MedicalHistoryQuestionsComponent;

  consultationRequestTreatmentType: ConsultationTreatmentTypes;
  questionIds: string[] = [];
  isProcessing: boolean = false;
  errorMessage: string | null = null;
  submissionError: string | null = null;

  abstract get consultationRequestForm(): FormGroup;
  abstract get treatmentPreferences(): string;
  abstract get externalData(): ExternalData;
  protected abstract handleConsultationDetailsSuccess(consultationOrderDetails: ConsultationRequestOrderDetail): void;
  protected abstract getConsultationStatus(): ConsultationStatus;

  /**
   * Gets the current consultation request order.
   */
  get consultationRequestOrder(): ConsultationRequestOrderDetail {
    return this.consultationRequestService.consultationOrderDetail;
  }

  /**
   * Gets the patient information form group.
   */
  get patientInfoForm(): FormGroup {
    return this.consultationRequestForm.get('patient') as FormGroup;
  }

  /**
   * Gets the FormGroup for the pharmacy section.
   */
  get pharmacyForm(): FormGroup {
    return this.consultationRequestForm.get('pharmacy') as FormGroup;
  }

  /**
   * Gets the medical history form.
   */
  get medicalHistoryForm(): FormGroup {
    return this.medicalHistoryQuestionsForm.form;
  }

  constructor(
    protected consultationRequestService: ConsultationRequestService,
    protected dynamicFormsService: DynamicFormsService,
    protected errorHandleService: ErrorHandlerService,
    protected loadingService: LoadingService,
    protected activatedRoute: ActivatedRoute,
    protected sessionStorageService: SessionStorageService
  ) {}

  /**
   * Determines if the patient has disqualifying answers in the questionnaire.
   *
   * @returns {boolean} true if the patient has disqualifying answers, otherwise false
   */
  protected hasDisqualifyingAnswers(): boolean {
    return this.medicalQuestionsForm.questions.some(
      (question) =>
        this.dynamicFormsService.getQuestionVisibleWarnings(question, this.medicalQuestionsForm.form).length > 0
    );
  }

  /**
   * Sets the questions and upsells ids for the current treatment type.
   *
   * @returns {Promise<void | string>} the question ids or an error message
   */
  protected setQuestions(): Promise<object | string> {
    return this.dynamicFormsService
      .getQuestionnaire(this.consultationRequestTreatmentType)
      .then((questionnaire) => {
        this.questionIds = questionnaire.questionIds;

        return questionnaire;
      })
      .catch(() => (this.errorMessage = this.errorHandleService.defaultErrorMessage));
  }

  /**
   * Set the consultation request order from the resolver and handles the error if any.
   */
  protected handleConsultationOrderDetails(): void {
    this.activatedRoute.data.subscribe(({ consultationOrderDetails }) => {
      if (consultationOrderDetails?.error) {
        this.handleConsultationDetailsError(consultationOrderDetails.errorMessage);

        return;
      }

      this.consultationRequestTreatmentType = consultationOrderDetails?.consultationRequest?.treatment_type;
      this.handleConsultationDetailsSuccess(consultationOrderDetails);
    });
  }

  /**
   * Handles the error of the consultation order details resolver.
   *
   * @param {string} errorMessage The error message
   */
  protected handleConsultationDetailsError(errorMessage: string): void {
    this.errorMessage = errorMessage;
    this.loadingService.toggleLoader(false);
  }

  /**
   * Stores the data that is needed for the consultation request completed page.
   */
  protected storeConsultationRequestCompletedData(): void {
    this.sessionStorageService.transactionId = this.consultationRequestOrder.transaction_id;
    this.sessionStorageService.consultationId = this.consultationRequestOrder.consultationRequest.id;
    this.sessionStorageService.hash = this.consultationRequestOrder.hash;
    this.sessionStorageService.patient = {
      name: this.patientInfoForm.get('first_name').value,
      last_name: this.patientInfoForm.get('last_name').value,
      email: this.consultationRequestOrder.email,
      phone: this.consultationRequestOrder.phone,
      birthday: this.patientInfoForm.get('birthday').value,
      gender: this.patientInfoForm.get('gender').value,
      height_feet: this.patientInfoForm.get('height_feet').value,
      height_inches: this.patientInfoForm.get('height_inches').value,
      weight: this.patientInfoForm.get('weight').value,
    };
    this.sessionStorageService.treatmentPreferences = this.treatmentPreferences;
    this.sessionStorageService.consultationStatus = this.getConsultationStatus();
    this.sessionStorageService.pharmacy = this.pharmacyForm.value;
    this.sessionStorageService.medicalHistoryQuestionnaireAnswers = this.medicalHistoryForm.value;
  }
}
