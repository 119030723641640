import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ExternalPaymentScript } from '@interfaces/external-payment-script';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { ErrorHandlerService } from '@services/error-handler.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import { ScriptService } from '@services/script.service';
import braintree from 'braintree-web';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GooglePayService extends ExternalPaymentMethodService implements ExternalPaymentScript {
  constructor(
    protected braintreeService: BraintreeService,
    protected scriptService: ScriptService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(errorHandlerService);
  }

  /**
   * Gets whether Google Pay is available.
   */
  override get isAvailable(): boolean {
    return !!this.globalObject;
  }

  /**
   * Begins a Google Pay session, which will prompt the user to authorize the payment.
   *
   * @param {number} amount the amount to charge the user
   *
   * @returns {Promise<string>} a promise that resolves with the nonce when the payment is authorized or rejects
   * if the payment fails
   */
  override pay(amount: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const paymentDataRequest = this.paymentMethodInstance.createPaymentDataRequest({
        transactionInfo: {
          currencyCode: 'USD',
          totalPriceStatus: 'FINAL',
          totalPrice: amount.toFixed(2),
        },
      });

      this.getGooglePayClient()
        .loadPaymentData(paymentDataRequest)
        .then((paymentData: any) => this.paymentMethodInstance.parseResponse(paymentData))
        .then((result: any) => resolve(result.nonce))
        .catch((err: any) => reject(err));
    });
  }

  /**
   * Render the GooglePay button.
   *
   * @param {HTMLElement} wrapper the element that will append the GooglePay button.
   *
   * @returns {Observable<void>} an Observable that emits a void value when Google Pay Button is successfully initialized
   */
  override renderPaymentButton(wrapper: HTMLElement): Observable<void> {
    return new Observable<void>((observer) => {
      this.braintreeService
        .getClient()
        .then((client: any) =>
          braintree.googlePayment.create({
            client,
            googlePayVersion: this.config.googlePay.apiVersion,
            googleMerchantId: this.config.googlePay.merchantId,
          })
        )
        .then((googlePayInstance: any) => (this.paymentMethodInstance = googlePayInstance))
        .then(() =>
          this.getGooglePayClient().isReadyToPay({
            apiVersion: this.config.googlePay.apiVersion,
            apiVersionMinor: this.config.googlePay.apiVersionMinor,
            allowedPaymentMethods: this.paymentMethodInstance.createPaymentDataRequest().allowedPaymentMethods,
            existingPaymentMethodRequired: true,
          })
        )
        .then((response: any) => {
          if (response.result) {
            wrapper.appendChild(this.getGooglePayButton());

            return observer.next();
          }

          observer.error();
        })
        .catch((err: any) => observer.error(err));
    });
  }

  /**
   * Loads the Google Pay script. It creates the window.google global object.
   *
   * @returns {Observable<void>} an Observable that emits a void value when the Google Pay script is loaded
   */
  initialize(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.globalObject) {
        return observer.next();
      }

      this.scriptService
        .loadScriptWithRetries('https://pay.google.com/gp/p/js/pay.js')
        .then(() => observer.next())
        .catch((err: Event) => {
          Sentry.captureException(err);
          observer.error(err);
        });
    });
  }

  /**
   * Gets the Google Pay object from the window object. Injected by the external script.
   */
  protected override get globalObject(): any {
    return (window as any).google?.payments?.api;
  }

  /**
   * Gets the Google Pay button ready to be rendered.
   *
   * @returns {any} the Google Pay button
   */
  protected getGooglePayButton(): any {
    return this.getGooglePayClient().createButton({
      buttonColor: 'black',
      buttonType: 'checkout',
      buttonLocale: 'en',
      buttonSizeMode: 'fill',
      onClick: () => {},
    });
  }

  /**
   * Gets a new Google Pay client instance.
   *
   * @returns {any} the Google Pay client instance
   */
  private getGooglePayClient(): any {
    return new this.globalObject.PaymentsClient({
      environment: this.config.production ? 'PRODUCTION' : 'TEST',
    });
  }
}
