import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { InHomeBookingResult, InHomeCollectionService, Result, ResultService } from '@Medology/ng-findalab';
import { OrderTest } from '@models/order-test';
import { PlaceOrderRequest } from '@models/place-order-request';
import { ReorderResponse } from '@models/reorder-response';
import { STDcheckLoadCartOptions } from '@models/stdcheck-load-cart-options';
import { STDcheckLoadCartRequest } from '@models/stdcheck-load-cart-request';
import { STDcheckLoadCartResponse } from '@models/stdcheck-load-cart-response';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { AuthorizationService } from '@services/authorization.service';
import { DataLayerService } from '@services/data-layer.service';
import { map, Observable, Subscription, switchMap } from 'rxjs';

import { DomainService } from './domain.service';
import { FormService } from './form.service';
import { OrderService } from './order.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class STDcheckOrderService extends OrderService {
  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    @Inject(DOCUMENT) protected document: Document,
    protected http: HttpClient,
    protected router: Router,
    protected storage: StorageService,
    protected results: ResultService,
    protected formService: FormService,
    protected domainService: DomainService,
    protected authService: AuthorizationService,
    protected inHomeService: InHomeCollectionService,
    protected dataLayerService: DataLayerService
  ) {
    super(
      config,
      document,
      http,
      router,
      storage,
      results,
      formService,
      domainService,
      authService,
      inHomeService,
      dataLayerService
    );

    this.listenForBookingSubmission();
  }

  /**
   * Parses the response by making an additional request to get the coupon details and replacing the coupon object in
   * the response.
   *
   * @param {STDcheckLoadCartResponse} response the API response to parse
   *
   * @returns {Observable<STDcheckLoadCartResponse>} an Observable of the STDcheckLoadCartResponse
   */
  override parseCartResponse(response: STDcheckLoadCartResponse): Observable<STDcheckLoadCartResponse> {
    return this.parseCoupon(response);
  }

  /**
   * Load STDcheck cart
   *
   * @param {STDcheckLoadCartOptions} options The STDcheck options to load the cart
   *
   * @returns {Observable<STDcheckLoadCartResponse>} an Observable of the STDcheckLoadCartResponse.
   */
  override loadCart(options?: STDcheckLoadCartOptions): Observable<STDcheckLoadCartResponse> {
    const url = new URL(document.location.href);
    const request = new STDcheckLoadCartRequest({
      tests: this.storage.tests,
      slug: options?.tests || this.testsFromUrl,
      coupon: this.couponCode,
      center_id:
        Number(url.searchParams.get('loc_id') || url.searchParams.get('center')) || this.storage.center?.id || null,
      state_short_name: url.searchParams.get('state') || this.storage.inHomeBookingResult?.address?.state || null,
    });

    return this.loadCartRequest(request);
  }

  /**
   * Get a Subscription to the lab selection that chooses the tests in each event.
   *
   * @returns a Subscription to resultSelected$ Observable
   */
  override listenForLabSelection(): Subscription {
    return this.results.resultSelected$.subscribe((result: Result) => {
      const request = new STDcheckLoadCartRequest({
        center_id: result.id,
        tests: this.storage.tests,
        coupon: this.storage.coupon?.coupon_code ?? null,
      });

      this.loadCartRequest(request).subscribe();
    });
  }

  /**
   * Get a Subscription to the lab selection that chooses the tests in each event.
   *
   * @returns a Subscription to resultSelected$ Observable
   */
  listenForBookingSubmission(): void {
    this.inHomeService.bookingSubmitted$.subscribe((result: InHomeBookingResult) => {
      const request = new STDcheckLoadCartRequest({
        tests: this.storage.tests,
        coupon: this.storage.coupon?.coupon_code ?? null,
        state_short_name: result.address.state,
      });

      this.loadCartRequest(request).subscribe();
    });
  }

  /**
   * Calculates the total discount amount by adding the coupon amount applied plus the partner discount if applicable.
   *
   * @returns the amount of the discount
   */
  protected override getTotalDiscount(): number {
    return this.storage.hasPartner ? this.partnerDiscount : 0;
  }

  /**
   * Gets the details of the order to reorder.
   *
   * @param {string} reorderId the transaction ID of the order to reorder
   * @param {string} token the account token cookie value
   *
   * @returns {Observable<ReorderResponse>} an observable that emits a ReorderResponse object
   */
  override getReorder(reorderId: string, token: string): Observable<ReorderResponse> {
    return super.getReorder(reorderId, token).pipe(
      switchMap((reorderInfo) => {
        const tests = reorderInfo.reorder.tests.map((test: OrderTest) => ({
          id: test.test_id,
          name: test.customer_tests_name,
          price: test.customer_tests_price,
          slug: null,
          type: test.customer_tests_type,
        }));
        const request = new STDcheckLoadCartRequest({
          center_id: reorderInfo.reorder.center?.id,
          tests,
          coupon: reorderInfo.reorder.coupon?.coupon_code,
        });

        return this.loadCartRequest(request).pipe(map(() => reorderInfo));
      })
    );
  }

  /**
   * Creates the order POST request body and add attributes to the object as required for STDcheck.
   *
   * @param {string} deviceData the device data from Braintree
   *
   * @returns the request body to make a post request to /order
   */
  protected override getOrderRequest(deviceData: string): PlaceOrderRequest {
    const placeOrderRequest = super.getOrderRequest(deviceData);
    placeOrderRequest.tests = this.storage.tests.map((test) => test.id);
    placeOrderRequest.coupon = this.storage.coupon?.coupon_code ?? null;

    if (this.storage.inHomeBookingResult) {
      this.addInHomeCollectionBookingToOrderRequest(placeOrderRequest);
    }

    return placeOrderRequest;
  }

  /**
   * Adds the in-home collection booking data to the order request. Parses the guardian info and adds it to the note.
   *
   * @param {PlaceOrderRequest} placeOrderRequest the order request to add the in-home collection booking data to
   */
  protected addInHomeCollectionBookingToOrderRequest(placeOrderRequest: PlaceOrderRequest): void {
    super.addInHomeCollectionBookingToOrderRequest(placeOrderRequest);
    placeOrderRequest.parseGuardianInfo(this.checkoutForm.value.patient);
  }
}
