import { Component, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Component({
  selector: 'app-consultation-error-page',
  templateUrl: './consultation-error-page.component.html',
})
export class ConsultationErrorPageComponent {
  /**
   * Gets the contact email.
   */
  get contactEmail(): string {
    return this.config.email;
  }

  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}
}
