import { Component, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaymentTypes } from '@enums/payment-types';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { StorageService } from '@services/storage.service';

@Component({
  template: '',
})
export abstract class PaymentTabsCardComponent {
  isPayNowTabSelected: boolean = true;

  constructor(@Inject(APP_CONFIG) protected config: AppConfig, protected storageService: StorageService) {}

  /**
   * Gets the disabled payment methods.
   */
  abstract get disabledPaymentMethods(): PaymentTypes[];

  /**
   * Gets the form group representing payment details.
   */
  abstract get paymentForm(): FormGroup;

  /**
   * Gets whether pay later is enabled.
   */
  get isPayLaterEnabled(): boolean {
    return this.config.enablePayLater;
  }

  /**
   * Updates the payment method to either 'Pay Now' or 'Pay Later'.
   *
   * @param {boolean} isPayNow whether the user is paying now or later
   */
  setPayNow(isPayNow: boolean): void {
    this.paymentForm.get('method').setValue(isPayNow ? PaymentTypes.CreditCard : PaymentTypes.PayLater);
    this.isPayNowTabSelected = isPayNow;
  }
}
