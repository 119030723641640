import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderUpsell } from '@models/order-upsell';

@Component({
  selector: 'app-additional-recommended-testing',
  templateUrl: './additional-recommended-testing.component.html',
  styleUrls: ['./additional-recommended-testing.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdditionalRecommendedTestingComponent {
  @Input() upsellTests: OrderUpsell[];
  @Input() upsellTestsForm: FormGroup;
  @Output() upsellSelectedEvent: EventEmitter<OrderUpsell> = new EventEmitter<OrderUpsell>();

  expandedUpsells: [string] = [''];

  constructor() {}

  /**
   * Get the upsell tests form as FormGroup
   */
  get testsForm() {
    return this.upsellTestsForm.get('tests') as FormGroup;
  }

  /**
   * Check if there is already a recommended test selected.
   *
   * @returns True if the user has already selected a recommended test, false if not
   */
  isAnyTestSelected(): boolean {
    return Object.values(this.testsForm.value).includes(true);
  }

  /**
   * Check if the test in the index is selected.
   *
   * @param slug slug of the form control
   * @returns True if the specific test is selected, false if not
   */
  isTestSelected(slug: string): boolean {
    return this.testsForm.get(slug).value;
  }

  /**
   * If a test was selected, deselect all others.
   *
   * @param event Click event on checkbox label
   * @param upsell The checked upsell
   */
  onCheckboxChange(event: Event, upsell: OrderUpsell): void {
    if (!(event.target as any).checked) {
      return this.upsellSelectedEvent.emit(null);
    }

    for (let controlKey in this.testsForm.controls) {
      this.testsForm.get(controlKey).setValue(upsell.slug === controlKey);
    }

    this.upsellSelectedEvent.emit(upsell);
  }

  /**
   * Checks whether the upsell card should be expanded or not.
   *
   * @param {string} slug The slug of the upsell test
   */
  isExpanded(slug: string): boolean {
    return this.expandedUpsells.includes(slug);
  }

  /**
   * Expands the upsell card.
   *
   * @param {Event}       event  The click event
   * @param {OrderUpsell} upsell The upsell test to expand
   */
  expandUpsellCard(event: Event, upsell: OrderUpsell): void {
    event.preventDefault();

    this.expandedUpsells.push(upsell.slug);
  }
}
