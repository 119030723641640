import { ChangeDetectorRef, Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PaymentTabsCardComponent } from '@components/payment-tabs-card/payment-tabs-card.component';
import { PaymentTypes } from '@enums/payment-types';
import { InHomeCollectionService, ResultService } from '@Medology/ng-findalab';
import { Recharge } from '@models/recharge';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { FormService } from '@services/form.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-payment-step-tabs',
  templateUrl: './payment-step-tabs.component.html',
  styleUrls: ['./payment-step-tabs.component.scss'],
})
export class PaymentStepTabsComponent extends PaymentTabsCardComponent implements OnChanges, OnInit {
  @Input() hasPartner: boolean;

  paymentForm: FormGroup;
  secondaryPaymentForm: FormGroup;
  isInHomeCollection: boolean = false;

  constructor(
    public domainService: DomainService,
    public orderService: OrderService,
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private formService: FormService,
    private inHomeService: InHomeCollectionService,
    private resultService: ResultService
  ) {
    super(config, storageService);
  }

  /**
   * Set payment form, which will be used in the PaymentInfoComponent child
   * component.
   */
  ngOnInit(): void {
    this.paymentForm = this.formService.checkout.get('payment') as FormGroup;
    this.secondaryPaymentForm = this.formService.checkout.get('secondaryPayment') as FormGroup;
    this.payNowValidationOnInHomeCollection();
  }

  /**
   * Handle component changes.
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.setToPayNowWhenHasPartner(changes.hasPartner.currentValue);
  }

  /** @inheritdoc */
  get disabledPaymentMethods(): PaymentTypes[] {
    return [PaymentTypes.Free, ...(!this.config.enableGiftCard ? [PaymentTypes.GiftCard] : [])];
  }

  /** @inheritdoc */
  override get isPayLaterEnabled(): boolean {
    return super.isPayLaterEnabled && !this.isInHomeCollection;
  }

  /**
   * Recharge information
   */
  get recharge(): Recharge {
    return this.orderService.reorderData
      ? this.orderService.reorderData?.recharge
      : this.orderService.rechargeData?.recharge;
  }

  /**
   * Add the validations to the second payment information form.
   */
  onGiftCardApplied(): void {
    if (this.orderService.isSecondaryPaymentNeeded) {
      this.changeDetectorRef.detectChanges();
      this.secondaryPaymentForm.get('method').setValue(PaymentTypes.CreditCard);
    }
  }

  /**
   * Reset the applied gift card in the order service.
   */
  onGiftCardRemoved(): void {
    this.secondaryPaymentForm.get('method').setValue(PaymentTypes.PayLater);
    this.orderService.applyGiftCard(null);
  }

  /**
   * Set pay now when the has partner checkbox is true.
   */
  private setToPayNowWhenHasPartner(hasPartner: boolean): void {
    if (
      hasPartner &&
      (!this.isPayNowTabSelected ||
        [
          PaymentTypes.BitPay,
          PaymentTypes.Paypal,
          PaymentTypes.ApplePay,
          PaymentTypes.GooglePay,
          PaymentTypes.Venmo,
        ].includes(this.paymentForm.get('method').value))
    ) {
      this.setPayNow(true);
    }
  }

  /**
   * Pay now validations triggered on different in home collections statuses.
   */
  private payNowValidationOnInHomeCollection(): void {
    this.isInHomeCollection = this.storageService.hasInHomeCollection;
    this.resultService.results$.subscribe(() => (this.isInHomeCollection = false));
    this.inHomeService.scheduling$.subscribe((status: boolean) => {
      this.isInHomeCollection = status;
      if (status) {
        this.setPayNow(true);
      }
    });
  }
}
