import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LoadingComponent } from '@components/loading/loading.component';

import { HealthlabsLayoutComponent } from './healthlabs-layout/healthlabs-layout.component';
import { HlFooterComponent } from './hl-footer/hl-footer.component';
import { HlStepsComponent } from './hl-steps/hl-steps.component';
import { SiteLayoutComponent } from './site-layout/site-layout.component';
import { StarfishLayoutComponent } from './starfish-layout/starfish-layout.component';
import { StdcheckLayoutComponent } from './stdcheck-layout/stdcheck-layout.component';
import { TreatmyutiLayoutComponent } from './treatmyuti-layout/treatmyuti-layout.component';

@NgModule({
  declarations: [
    StdcheckLayoutComponent,
    HealthlabsLayoutComponent,
    TreatmyutiLayoutComponent,
    StarfishLayoutComponent,
    SiteLayoutComponent,
    HlStepsComponent,
    HlFooterComponent,
    LoadingComponent,
  ],
  exports: [
    StdcheckLayoutComponent,
    HealthlabsLayoutComponent,
    TreatmyutiLayoutComponent,
    StarfishLayoutComponent,
    SiteLayoutComponent,
    LoadingComponent,
  ],
  imports: [CommonModule],
})
export class LayoutModule {}
