import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@services/error-handler.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import braintree from 'braintree-web';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VenmoService extends ExternalPaymentMethodService {
  constructor(protected braintreeService: BraintreeService, protected errorHandlerService: ErrorHandlerService) {
    super(errorHandlerService);
  }

  /**
   * Gets whether Venmo is available.
   */
  override get isAvailable(): boolean {
    return !!this.paymentMethodInstance?.isBrowserSupported();
  }

  /**
   * Begins a Venmo session, which will prompt the user to authorize the payment.
   *
   * @returns {Promise<string>} a promise that resolves with the nonce when the payment is authorized or rejects
   * if the payment fails
   */
  override pay(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.paymentMethodInstance
        .tokenize()
        .then((payload: any) => resolve(payload.nonce))
        .catch((err: any) => {
          if (
            [
              'FRAME_SERVICE_FRAME_CLOSED',
              'VENMO_TOKENIZATION_CANCELED_BY_MERCHANT',
              'VENMO_MOBILE_POLLING_TOKENIZATION_CANCELED',
              'VENMO_DESKTOP_CANCELED',
              'VENMO_CUSTOMER_CANCELED',
              'VENMO_CANCELED',
              'VENMO_APP_CANCELED',
            ].includes(err?.code)
          ) {
            reject({ statusCode: 'CANCELED' });
          } else {
            reject(err);
          }
        });
    });
  }

  /**
   * Render the Venmo button. We just display the Venmo option if the browser supports Venmo.
   * We need try to render the button to initialize the Venmo instance and after that check the browser support.
   *
   * @returns {Observable<void>} an observable that emits void when the Venmo instance is initialized
   */
  override renderPaymentButton(): Observable<void> {
    return new Observable((observer) => {
      this.braintreeService
        .getClient()
        .then((client) =>
          braintree.venmo.create({
            allowAndroidRecreation: false,
            allowDesktopWebLogin: true,
            allowWebviews: false,
            client,
            paymentMethodUsage: 'multi_use',
            requireManualReturn: true,
          })
        )
        .then((venmoInstance: any) => {
          this.paymentMethodInstance = venmoInstance;
          observer.next();
          observer.complete();
        })
        .catch((err: any) => observer.error(err));
    });
  }

  /**
   * Cancels the Venmo tokenization process.
   */
  cancelTokenization(): void {
    this.paymentMethodInstance?.cancelTokenization().catch((err: any) => {
      if (err?.code === 'VENMO_TOKENIZATION_REQUEST_NOT_ACTIVE') {
        return;
      }

      throw err;
    });
  }
}
