import { Component } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-mens-intimate-wash',
  templateUrl: './mens-intimate-wash.component.html',
  styleUrl: './mens-intimate-wash.component.scss',
})
export class MensIntimateWashComponent {
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;
  readonly slug: UpsellSlugs = UpsellSlugs.MensIntimateWash;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }
}
