import { environment } from '@environments/environment';
import { starfishCommonEnvironment } from '@environments/starfish/environment.common';

/**
 * Environment variables for use on the Starfish site, in production environment.
 */
export const starfishEnvironment = {
  ...environment,
  ...starfishCommonEnvironment,
  googleMapApiKey: 'AIzaSyDIEZHrvUCVq39wvL0mBOanghqkHIk8cRE',
  apiUrl: 'https://api.analytecare.com',
  siteUrls: {
    home: 'https://www.starfish.com',
    termsOfService: 'https://www.starfish.com/terms-conditions/',
    myAccount: 'https://my-account.starfish.com',
    tests: 'https://www.starfish.com',
    notFound: 'https://www.starfish.com/404',
  },
  siteId: '18',
};
