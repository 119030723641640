import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlaceOrderComponent } from '@components/place-order/place-order.component';
import { PaymentTypes } from '@enums/payment-types';
import { OrderResponse } from '@models/order-response';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/angular';
import { AccountService } from '@services/account.service';
import { DomainService } from '@services/domain.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { BraintreeService } from '@services/external-payments/braintree.service';
import { FormService } from '@services/form.service';
import { LoadingService } from '@services/loading.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnInit {
  @ViewChild('placeOrder', { static: false }) placeOrderComponent: PlaceOrderComponent;

  isLoading: boolean = false;

  constructor(
    public orderService: OrderService,
    private storageService: StorageService,
    private acRoute: ActivatedRoute,
    private domainService: DomainService,
    private formService: FormService,
    private errorHandlerService: ErrorHandlerService,
    private accountService: AccountService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private loadingService: LoadingService,
    private braintreeService: BraintreeService
  ) {}

  ngOnInit(): void {
    const accountToken = this.storageService.getCookie('account_token');
    this.isLoading = true;

    if (!accountToken) {
      this.initializeOrderFlow();
      this.isLoading = false;
      this.storageService.isBaOrder = false;

      return;
    }

    this.loadingService.toggleLoader(true);
    this.handleBusinessAccountValidation();
    this.handleLoggedAccountRequests(accountToken);
  }

  /**
   * Get the checkout form from the form service.
   */
  get orderForm(): FormGroup {
    return this.formService.checkout;
  }

  /**
   * Returns true when has partner checkbox is checked
   */
  get hasPartner(): boolean {
    return this.storageService.hasPartner;
  }

  /**
   * Return if the domain requires parent consent form
   */
  get shouldEnableParentConsentForm(): boolean {
    return this.domainService.isHealthlabsDomain() || !!this.storageService.inHomeBookingResult;
  }

  /**
   * Returns true when free order is set in localStorage
   */
  get isFree(): boolean {
    return this.storageService.free;
  }

  /**
   * Get the address contact information form from the form service.
   */
  get addressContactInformation(): FormGroup {
    return this.formService.addressContactInformation as FormGroup;
  }

  /**
   * Gets the address form.
   */
  get addressForm(): FormGroup {
    return this.addressContactInformation.get('address') as FormGroup;
  }

  /**
   * Returns true when isBaOrder is set in localStorage
   */
  get isBaOrder(): boolean {
    return this.storageService.isBaOrder;
  }

  /**
   * Returns true when the domain is Healthlabs
   */
  get isHealthlabsDomain(): boolean {
    return this.domainService.isHealthlabsDomain();
  }

  /**
   * Returns true if the order upgrade feature is enabled for this site.
   */
  get isOrderUpgradeEnabled(): boolean {
    return this.config.enableOrderUpgrade;
  }

  /**
   * Gets whether the In-home Collection feature is allowed for this site.
   */
  get isInHomeCollectionAllowed(): boolean {
    return this.config.inHomeCollectionAllowed;
  }

  /**
   * Returns true if the order medical history card should be displayed.
   */
  get isOrderMedicalHistoryEnabled(): boolean {
    return this.config.enableOrderMedicalHistory;
  }

  /**
   * Gets whether the terms of service checkbox should be displayed on the page.
   */
  get isTosOnOrderPageEnabled(): boolean {
    return this.config.enableTosOnOrderPage;
  }

  /**
   * Gets the header title and subtitle.
   */
  get orderPageHeader(): { title: string; subtitle: string } {
    return this.config.titles.orderPageHeader;
  }

  /**
   * Submits the order after checking for submission errors.
   */
  async submitOrder(): Promise<void> {
    this.placeOrderComponent.processing = true;
    let orderResponse: OrderResponse;
    try {
      orderResponse = await this.orderService.submitOrder(this.braintreeService.deviceData);
    } catch (error) {
      this.handleErrorResponse(error);

      return;
    }

    await this.processSecondaryPayment(orderResponse.transaction_id);
    this.orderService.afterOrderPlacement(orderResponse);
  }

  /**
   * Handles the error response from a failed API request.
   *
   * @param {any} error the error response object received from the API
   */
  private handleErrorResponse(error: any): void {
    this.placeOrderComponent.submissionErrors = this.errorHandlerService.handleResponseError(error);
    this.placeOrderComponent.processing = false;
  }

  /**
   * Processes the secondary payment using a gift card.
   *
   * @param {string} transactionId the transaction ID of the order that should be paid
   */
  private async processSecondaryPayment(transactionId: string): Promise<void> {
    if (!this.orderService.isSecondaryPaymentNeeded) {
      return;
    }

    try {
      const transactionResponse = await this.orderService.makeSecondaryPaymentWithGiftCard(transactionId);
      this.storageService.secondaryPaymentTransactionId = transactionResponse.transaction_id;
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Change payment type to free when free order or ba order
   */
  private changePaymentTypeWhenFreeOrder(): void {
    if (this.isFree || this.isBaOrder) {
      this.orderForm.get('payment').get('method').setValue(PaymentTypes.Free);
    }
  }

  /**
   * Auto accept TOS when free order
   */
  private autoAcceptTosWhenFreeOrder(): void {
    if (this.isFree) {
      this.addressContactInformation.get('tos').setValue(true);
    }
  }

  /**
   * Get reorder information
   *
   * @param {string} reorderId The transaction id of the order
   * @param {string} token     The JWT token
   */
  protected getReorderInformation(reorderId: string, token: string): void {
    this.orderService.getReorder(reorderId, token).subscribe({
      next: (response) => {
        this.storageService.authToken = token;
        this.orderService.reorderData = response.reorder;
        this.accountService.validateBaAccount(token);
        if (!this.storageService.zipcode && response.reorder.center?.zip_code) {
          this.storageService.zipcode = response.reorder.center.zip_code;
        }
      },
      error: () => this.accountService.validateBaAccount(token),
    });
  }

  /**
   * Get recharge payment information if the user was previously logged in
   *
   * @param {string} token The JWT token
   */
  protected getRechargeForLoggedUser(token: string): void {
    this.orderService.getPaymentRechargeable(token).subscribe({
      next: (response) => {
        this.storageService.authToken = token;
        this.orderService.rechargeData = response;
        this.accountService.validateBaAccount(token);
      },
      error: () => {
        this.accountService.validateBaAccount(token);
      },
    });
  }

  /**
   * Run common order flow initialization
   */
  private initializeOrderFlow(): void {
    this.storageService.datalayerPool = [];
    this.changePaymentTypeWhenFreeOrder();
    this.autoAcceptTosWhenFreeOrder();
    this.handleInHomeCollectionProviderIds();
  }

  /**
   * Initialize order flow after business account validation finishes
   */
  private handleBusinessAccountValidation(): void {
    this.accountService.businessAccountValidation$.subscribe(() => {
      this.loadingService.toggleLoader(false);
      this.initializeOrderFlow();
      this.isLoading = false;
    });
  }

  /**
   * Handle requests when there is an account_token
   *
   * @param {string} token The JWT token
   */
  private handleLoggedAccountRequests(token: string): void {
    const queryParams = this.acRoute.snapshot.queryParams;

    if (!queryParams.hasOwnProperty('reorderId')) {
      this.getRechargeForLoggedUser(token);

      return;
    }

    this.orderService.clearLastOrder();
    this.getReorderInformation(queryParams.reorderId, token);
  }

  /**
   * Handle enabling customized In-Home Collection provider IDs when the query param is present
   */
  private handleInHomeCollectionProviderIds(): void {
    const providerIds = this.acRoute.snapshot.queryParams['ihc-providers'];

    this.storageService.providerIds = providerIds
      ? providerIds.split(',').map((id) => parseInt(id))
      : this.config.inHomeCollectionProviderIds;
  }
}
