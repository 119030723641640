import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RestrictedNavigationService {
  restrictedNavigationObserver: Observable<boolean>;

  protected restrictedNavigationRoutes: string[] = [
    '/order',
    '/order-address.php',
    '/betterlab.php',
    '/doxy-pep-treatment',
    '/partial-results-delivery',
    '/mycoplasma-genitalium',
  ];

  /**
   * Creates an observer for navigation events based on whether the current route is restricted or not.
   */
  constructor(protected router: Router) {
    this.restrictedNavigationObserver = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map((e: NavigationEnd) => e.urlAfterRedirects.split('?').shift()),
      map((path) => this.restrictedNavigationRoutes.includes(path))
    );
  }
}
