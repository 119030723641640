import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates the type of a file against a list of valid file types.
 *
 * @param {string[]} validFileTypes an array of valid MIME types
 *
 * @returns {ValidatorFn} a validator function that checks if the file type is valid
 */
export function fileTypeValidator(validFileTypes: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;

    if (file?.type === undefined || file?.type === null) {
      return null;
    }

    return validFileTypes.includes(file.type)
      ? null
      : { fileType: { validTypes: validFileTypes, actualType: file.type } };
  };
}
