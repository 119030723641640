export enum ValidatorFunctions {
  Required = 'required',
  Email = 'email',
  Pattern = 'pattern',
  RequiredTrue = 'requiredTrue',
  MaxLength = 'maxLength',
  MinLength = 'minLength',
  FileType = 'fileType',
  MaxFileSize = 'maxFileSize',
}
