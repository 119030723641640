import { Component } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-replenish-your-good-bacteria',
  templateUrl: './replenish-your-good-bacteria.component.html',
  styleUrls: ['./replenish-your-good-bacteria.component.scss'],
})
export class ReplenishYourGoodBacteriaComponent {
  ConsultationTreatmentTypes: typeof ConsultationTreatmentTypes = ConsultationTreatmentTypes;
  readonly slug: UpsellSlugs = UpsellSlugs.Probiotics;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Retrieves the treatment type from the session storage service.
   *
   * @returns {ConsultationTreatmentTypes} the current consultation request treatment type
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.sessionStorageService.treatmentType;
  }
}
