import { Injectable } from '@angular/core';
import { healthlabsEnvironment } from '@environments/healthlabs/environment';
import { starfishEnvironment } from '@environments/starfish/environment';
import { stdcheckEnvironment } from '@environments/stdcheck/environment';
import { treatmyutiEnvironment } from '@environments/treatmyuti/environment';
import { DomainService } from '@services/domain.service';

import { AppConfig } from '../types/config';

@Injectable()
export class ConfigService {
  static readonly config = (service: ConfigService) => service._config;

  private _config: AppConfig;

  constructor(private domainService: DomainService) {}

  loadAppConfig() {
    return new Promise<boolean>((resolve) => {
      this._config = this.getEnvironment();

      resolve(true);
    });
  }

  /**
   * Gets the environment configuration according to the domain we are in.
   *
   * @returns {AppConfig} the environment configuration
   */
  private getEnvironment(): AppConfig {
    if (this.domainService.isSTDCheckDomain()) {
      return stdcheckEnvironment;
    }

    if (this.domainService.isTreatMyUtiDomain()) {
      return treatmyutiEnvironment;
    }

    if (this.domainService.isStarfishDomain()) {
      return starfishEnvironment;
    }

    return healthlabsEnvironment;
  }
}
