import { Component } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-need-a-doctors-note',
  templateUrl: './need-a-doctors-note.component.html',
  styleUrls: ['./need-a-doctors-note.component.scss'],
})
export class NeedADoctorsNoteComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.DoctorsNote;

  constructor() {}
}
