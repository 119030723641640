import { Component, Input } from '@angular/core';
import { PaymentMethodIcon } from '@models/payments/payment-method-icon';

@Component({
  selector: 'app-payment-method-icons',
  templateUrl: './payment-method-icons.component.html',
  styleUrls: ['./payment-method-icons.component.scss'],
})
export class PaymentMethodIconsComponent {
  @Input() icons: PaymentMethodIcon[] = [];
}
