import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { fileSizeValidator } from '@common/file-size-validator';
import { fileTypeValidator } from '@common/file-type-validator';
import { ComparisonOperators } from '@enums/comparison-operators';
import { InputTypes } from '@enums/input-types';
import { LogicalOperators } from '@enums/logical-operators';
import { MissingDataActions } from '@enums/missing-data-actions';
import { ValidatorFunctions } from '@enums/validator-functions';
import { ConsultationQuestionAnswer } from '@models/consultation-request/consultation-question-answer';
import { ExternalData } from '@models/dynamic-forms/external-data';
import { Question } from '@models/dynamic-forms/question';
import { QuestionConditional } from '@models/dynamic-forms/question-conditional';
import { QuestionVisibilityExternalCondition } from '@models/dynamic-forms/question-visibility-external-condition';
import { QuestionVisibilityExternalConditions } from '@models/dynamic-forms/question-visibility-external-conditions';
import { QuestionVisibilityTriggerQuestions } from '@models/dynamic-forms/question-visibility-trigger-questions';
import { QuestionWarning } from '@models/dynamic-forms/question-warning';
import { Questionnaire } from '@models/dynamic-forms/questionnaire';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/browser';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicFormsService {
  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    protected http: HttpClient,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Checks the visibility of a question based on its visibility trigger questions and external conditions.
   *
   * @param {Question} question the question whose visibility is being checked
   * @param {FormGroup} form the form group containing the values of the questions
   * @param {ExternalData} externalData the external data to check against
   *
   * @returns {boolean} true if the question should be visible, false otherwise
   */
  checkQuestionVisibility(question: Question, form: FormGroup, externalData: ExternalData): boolean {
    const visibleByTriggerQuestions = this.isQuestionVisibleByTriggerQuestions(
      question.visibility.triggerQuestions,
      form
    );
    const visibleByExternalConditions = this.isQuestionVisibleByExternalConditions(
      question.visibility.externalConditions,
      externalData
    );

    return question.visibility.logicalOperator === LogicalOperators.And
      ? visibleByTriggerQuestions && visibleByExternalConditions
      : visibleByTriggerQuestions || visibleByExternalConditions;
  }

  /**
   * Retrieves a questionnaire based on the provided treatment type.
   *
   * @param {string} treatmentType the type of treatment to retrieve the questionnaire for
   *
   * @returns {Questionnaire} the questionnaire object
   */
  getQuestionnaire(treatmentType: string): Promise<Questionnaire> {
    return new Promise((resolve, reject) => {
      const storedQuestionnaire = this.sessionStorageService.getQuestionnaireStructure(treatmentType);
      if (storedQuestionnaire) {
        resolve(storedQuestionnaire);

        return;
      }

      this.http.get('assets/questionnaires/questionnaires.json').subscribe({
        next: (data: ArrayLike<any>) => {
          const questionnaire = this.getQuestionnaireOnSuccess(data, treatmentType);
          if (!questionnaire) {
            reject('Error loading questionnaire');

            return;
          }
          this.sessionStorageService.setQuestionnaireStructure(treatmentType, questionnaire);
          resolve(questionnaire);
        },
        error: () => reject('Error loading questionnaire'),
      });
    });
  }

  /**
   * Retrieves questions from the JSON file.
   *
   * If question IDs are provided, it retrieves only the specified questions. Otherwise, it retrieves all questions.
   *
   * @param {string[]} questionIds the IDs of the questions to retrieve (optional)
   * @param {ExternalData} externalData the external data used for replacing placeholders in the questions (optional)
   *
   * @returns {Promise<Question[]>} an array of Question objects
   */
  getQuestions(questionIds: string[] = [], externalData?: ExternalData): Promise<Question[]> {
    return new Promise((resolve, reject) => {
      this.http.get('assets/questionnaires/questions.json').subscribe({
        next: (questionData: any[]) => resolve(this.getQuestionsOnSuccess(questionData, questionIds, externalData)),
        error: () => reject('Error loading questions'),
      });
    });
  }

  /**
   * Gets an array of validator functions based on the provided question's validators.
   *
   * @param {Question} question the question object containing validators
   *
   * @returns {ValidatorFn[]} an array of validator functions
   */
  getQuestionValidators(question: Question): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    question.validators.forEach((validator) => {
      switch (validator.validatorFn.name) {
        case ValidatorFunctions.Required:
          validators.push(Validators.required);

          break;
        case ValidatorFunctions.Email:
          validators.push(Validators.email);

          break;
        case ValidatorFunctions.Pattern:
          validators.push(Validators.pattern(validator.validatorFn.params[0]));

          break;
        case ValidatorFunctions.RequiredTrue:
          validators.push(Validators.requiredTrue);

          break;
        case ValidatorFunctions.MaxLength:
          validators.push(Validators.maxLength(validator.validatorFn.params[0]));

          break;
        case ValidatorFunctions.MinLength:
          validators.push(Validators.minLength(validator.validatorFn.params[0]));

          break;
        case ValidatorFunctions.FileType:
          validators.push(fileTypeValidator(validator.validatorFn.params[0]));

          break;
        case ValidatorFunctions.MaxFileSize:
          validators.push(fileSizeValidator(validator.validatorFn.params[0]));

          break;
      }
    });

    return validators;
  }

  /**
   * Retrieves the error messages for a question based on the current form validation state.
   *
   * @param {Question} question the question whose errors are being retrieved
   * @param {FormGroup} form the form group containing the values and validation states of the questions
   *
   * @returns {string[]} an array of error messages for the question
   */
  getQuestionVisibleErrors(question: Question, form: FormGroup): string[] {
    const controlErrors = form.get(question.id).errors || {};

    return Object.keys(controlErrors).map(
      (key) =>
        question.validators.find((validator) => validator.errorKey === key || validator.validatorFn.name === key)
          .errorMessage
    );
  }

  /**
   * Gets the warning messages for the question based on the current value in the form.
   *
   * @param {Question} question the question object
   * @param {FormGroup} form the form group containing the question's form control
   *
   * @returns {string[]} an array of warning messages
   */
  getQuestionVisibleWarnings(question: Question, form: FormGroup): string[] {
    const value = form.get(question.id).value;

    return question.warnings
      .filter((warning) => this.shouldIncludeWarning(warning, value))
      .map((warning) => warning.message);
  }

  /**
   * Parses the questions and their corresponding answers from storage for a given questionnaire.
   *
   * @param {string} questionnaireId the ID of the questionnaire for which to parse questions and answers
   * @param {ExternalData} externalData the external data used for replacing placeholders in the questions (optional)
   *
   * @returns {Promise<ConsultationQuestionAnswer[]>} a promise that resolves to an array of parsed question-answer pairs
   */
  async parseQuestionsAndAnswersFromStorage(
    questionnaireId: string,
    externalData?: ExternalData
  ): Promise<ConsultationQuestionAnswer[]> {
    const [questions, questionnaire] = await Promise.all([
      this.getQuestions([], externalData),
      this.getQuestionnaire(questionnaireId),
    ]);
    const answers = this.sessionStorageService.getQuestionnaireAnswers(questionnaireId);
    const expectedOrder = questionnaire.questionIds;

    return Object.entries(answers)
      .filter(([, answer]) => !!answer && (!Array.isArray(answer) || answer.length > 0))
      .sort(([idA], [idB]) => expectedOrder.indexOf(idA) - expectedOrder.indexOf(idB))
      .map(([key, answer]) => {
        const question = questions.find((q) => q.id === key);

        return {
          question: question.label || question.placeholder,
          answer: (Array.isArray(answer) ? answer.join(', ') : answer) as string,
        };
      });
  }

  /**
   * Generates a FormGroup based on an array of Question objects.
   *
   * @param {Question[]} questions an array of Question objects
   *
   * @returns {FormGroup} a FormGroup containing form controls corresponding to the questions
   */
  toFormGroup(questions: Question[]): FormGroup {
    const group: any = {};
    questions.forEach((question) => (group[question.id] = this.createFormControl(question)));

    return new FormGroup(group);
  }

  /**
   * Checks if a condition is met based on the given value.
   *
   * @param {QuestionConditional} condition the condition to check
   * @param {any} value the value to check against the condition
   *
   * @returns {boolean} true if the condition is met, false otherwise
   */
  private checkCondition(condition: QuestionConditional, value: any): boolean {
    if (Array.isArray(value)) {
      return value.some((val) => this.checkCondition(condition, val));
    }

    switch (condition.comparisonOperator) {
      case ComparisonOperators.Equal:
        return value === condition.value;
      case ComparisonOperators.NotEqual:
        return value !== condition.value;
      case ComparisonOperators.GreaterThan:
        return value > condition.value;
      case ComparisonOperators.LessThan:
        return value < condition.value;
      case ComparisonOperators.GreaterThanOrEqual:
        return value >= condition.value;
      case ComparisonOperators.LessThanOrEqual:
        return value <= condition.value;
      default:
        return false;
    }
  }

  /**
   * Checks if a condition is met based on external data.
   *
   * @param {QuestionVisibilityExternalCondition} condition the condition to check
   * @param {ExternalData} externalData the external data used for checking the condition
   *
   * @returns {boolean} true if the condition is met, otherwise false
   */
  private checkExternalDataCondition(
    condition: QuestionVisibilityExternalCondition,
    externalData: ExternalData
  ): boolean {
    const value = externalData?.[condition.key];

    return value === undefined ? this.handleMissingData(condition) : this.checkCondition(condition, value);
  }

  /**
   * Creates an array of FormControl instances from a given array of values.
   *
   * @param {any[]} values the values to convert into form controls
   *
   * @returns {FormControl[]} an array of FormControl instances
   */
  private createFormArrayControls(values: any[]): FormControl[] {
    return values?.map((element) => new FormControl(element)) || [];
  }

  /**
   * Creates a form control (either FormControl or FormArray) based on the given question.
   *
   * @param {Question} question the question to create the form control from
   *
   * @returns {FormControl | FormArray} a FormControl or FormArray based on the type of the question
   */
  private createFormControl(question: Question): FormControl | FormArray {
    return question.type === InputTypes.Checkbox
      ? new FormArray(this.createFormArrayControls(question.value), this.getQuestionValidators(question))
      : new FormControl(question.value || '', this.getQuestionValidators(question));
  }

  /**
   * Handles cases where external data is missing.
   *
   * @param {QuestionVisibilityExternalCondition} condition the condition that has missing data
   *
   * @returns {boolean} the result of handling the missing data
   *
   * @throws {Error} throws an error if missing data should be treated as an error
   */
  private handleMissingData(condition: QuestionVisibilityExternalCondition): boolean {
    if (condition.missingDataHandling === MissingDataActions.ThrowError) {
      throw new Error(`External data key "${condition.key}" is required to determine question visibility.`);
    }

    return condition.missingDataHandling !== MissingDataActions.AssumeNotMet;
  }

  /**
   * Determines if a question should be visible based on external conditions.
   *
   * @param {QuestionVisibilityExternalConditions} externalConditions external conditions for visibility check
   * @param {ExternalData} externalData the external data used for checking conditions
   *
   * @returns {boolean} true if the question should be visible, otherwise false
   */
  private isQuestionVisibleByExternalConditions(
    externalConditions: QuestionVisibilityExternalConditions,
    externalData: ExternalData
  ): boolean {
    if (externalConditions.conditions.length === 0) {
      return true;
    }

    return externalConditions.logicalOperator === LogicalOperators.And
      ? externalConditions.conditions.every((condition) => this.checkExternalDataCondition(condition, externalData))
      : externalConditions.conditions.some((condition) => this.checkExternalDataCondition(condition, externalData));
  }

  /**
   * Determines if a question should be visible based on trigger questions.
   *
   * @param {QuestionVisibilityTriggerQuestions} triggerQuestions trigger questions for visibility check
   * @param {FormGroup} form the form containing the questions
   *
   * @returns {boolean} true if the question should be visible, otherwise false
   */
  private isQuestionVisibleByTriggerQuestions(
    triggerQuestions: QuestionVisibilityTriggerQuestions,
    form: FormGroup
  ): boolean {
    if (triggerQuestions.questions.length === 0) {
      return true;
    }

    return triggerQuestions.logicalOperator === LogicalOperators.And
      ? triggerQuestions.questions.every((triggerQuestion) =>
          this.checkCondition(triggerQuestion, form.get(triggerQuestion.id).value)
        )
      : triggerQuestions.questions.some((triggerQuestion) =>
          this.checkCondition(triggerQuestion, form.get(triggerQuestion.id).value)
        );
  }

  /**
   * Determines if a warning should be included based on the current value.
   *
   * @param {QuestionWarning} warning the warning to check
   * @param {any} value the current value to check against
   *
   * @returns {boolean} true if the warning should be included, false otherwise
   */
  private shouldIncludeWarning(warning: QuestionWarning, value: any): boolean {
    return warning.logicalOperator === LogicalOperators.And
      ? warning.triggeringValues.every((triggeringValue) => this.checkCondition(triggeringValue, value))
      : warning.triggeringValues.some((triggeringValue) => this.checkCondition(triggeringValue, value));
  }

  /**
   * Converts the questionnaire JSON data from the response into a Questionnaire object.
   *
   * @param {ArrayLike} data          the JSON data from the response
   * @param {string}    treatmentType the type of treatment to retrieve the questionnaire for
   *
   * @returns {Questionnaire} the questionnaire object
   */
  private getQuestionnaireOnSuccess(data: ArrayLike<any>, treatmentType: string): Questionnaire | null {
    const questionnaireData = Array.from(data).find(
      (questionnaire) => questionnaire.id === this.config.treatmentFormSettings[treatmentType]
    );

    if (!questionnaireData) {
      Sentry.captureException(new Error(`Questionnaire not found for treatment type: ${treatmentType}`));

      return;
    }

    return new Questionnaire(questionnaireData);
  }

  /**
   * Converts the questions JSON data from the response into an array of Question objects.
   *
   * @param {ArrayLike}    questionData the JSON data from the response
   * @param {string[]}     questionIds  the IDs of the questions to retrieve
   * @param {ExternalData} externalData the external data used for replacing placeholders in the questions (optional)
   *
   * @returns {Question[]} an array of Question objects
   */
  private getQuestionsOnSuccess(questionData: any[], questionIds: string[], externalData?: ExternalData): Question[] {
    return questionIds.length === 0
      ? questionData.map((question) => new Question(question, externalData))
      : questionIds.map(
          (id) =>
            new Question(
              questionData.find((question) => question.id === id),
              externalData
            )
        );
  }
}
