import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class QueryParamsService {
  /**
   * Binds the specified query parameters to the given base URL.
   *
   * @param {string} baseUrl the base URL to which the query parameters will be added
   * @param {Record<string, string>} queryParams the query parameters to be appended to the base URL
   *
   * @returns {string} the base URL with the query parameters appended
   */
  public readonly bindQueryParamsToUrl = (baseUrl: string, queryParams: Record<string, string>): string =>
    `${baseUrl}?${new URLSearchParams(queryParams).toString()}`;
}
