import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { OrderSummaryRow } from '@common/order-summary-row';
import { PaymentTypes } from '@enums/payment-types';
import { Treatment } from '@enums/treatment';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-consultation-order-summary',
  templateUrl: './consultation-order-summary.component.html',
  styleUrls: ['./consultation-order-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationOrderSummaryComponent implements OnInit, OnDestroy {
  @Input() consultationRequestPaymentForm: FormGroup;
  @Input() enableDiscountInput: boolean;

  orderSummaryRows: OrderSummaryRow[] = [];

  private subscriptions: Subscription[] = [];

  constructor(public consultationRequestService: ConsultationRequestService) {}

  /**
   * Initialize form listeners.
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.listenForDiscountChanges(),
      this.listenForPaymentMethodChanges(),
      this.listenForTreatmentChanges()
    );

    this.setOrderSummaryRows();
  }

  /**
   * Unsubscribe all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Determine if the order is free.
   *
   * @returns true if the order payment method is set to free, false otherwise
   */
  isOrderFree(): boolean {
    return this.methodControl.value === PaymentTypes.Free;
  }

  /**
   * Get the form payment method control.
   */
  private get methodControl(): AbstractControl {
    return this.consultationRequestPaymentForm.get('payment').get('method');
  }

  /**
   * Get the form discount control.
   */
  private get discountControl(): AbstractControl {
    return this.consultationRequestPaymentForm.get('discount');
  }

  /**
   * Listen for discount amount changes to set the payment method to free
   * if the discount exceeds the subtotal of the order.
   *
   * @returns a Subscription to changes in the form's discount value
   */
  private listenForDiscountChanges(): Subscription {
    return this.discountControl.valueChanges.subscribe((value) => {
      if (value * 100 >= this.consultationRequestService.getSubtotal()) {
        this.methodControl.patchValue(PaymentTypes.Free);
      }

      this.setOrderSummaryRows();
    });
  }

  /**
   * Listen for payment method changes to reset the discount amount.
   *
   * @returns a Subscription to changes in the form's payment method value
   */
  private listenForPaymentMethodChanges(): Subscription {
    return this.methodControl.valueChanges.subscribe(() => {
      this.discountControl.patchValue(0);
      this.setOrderSummaryRows();
    });
  }

  /**
   * Listen for treatment changes to reset the discount amount.
   *
   * @returns a Subscription to changes in the form's treatment value
   */
  private listenForTreatmentChanges(): Subscription {
    return this.consultationRequestService.treatmentControl.valueChanges.subscribe(() => {
      this.discountControl.patchValue(0);
      this.setOrderSummaryRows();
    });
  }

  /**
   * Sets the order summary rows for the consultation request.
   */
  private setOrderSummaryRows(): void {
    this.orderSummaryRows = [
      {
        item: 'Clinical Review',
        amountInCents: ConsultationRequestService.cost,
        shouldDisplayRow: this.consultationRequestService.treatmentControl.value !== Treatment.Partner,
        dataQa: 'your-consultation-row',
      },
      {
        item: 'Clinical Review for your Partner',
        amountInCents: ConsultationRequestService.cost,
        shouldDisplayRow: this.consultationRequestService.treatmentControl.value !== Treatment.Self,
        dataQa: 'partner-consultation-row',
      },
      {
        item: 'Clinical Review Discount',
        amountInCents: -(this.isOrderFree()
          ? this.consultationRequestService.getSubtotal()
          : this.consultationRequestService.getDiscount()),
        shouldDisplayRow: Boolean(this.consultationRequestService.getDiscount() || this.isOrderFree()),
        dataQa: 'consultation-discount-row',
      },
    ];
  }
}
