import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ExternalPaymentError } from '@models/external-payment-error';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig) {}

  /**
   * Default error message to display when an error occurs
   */
  get defaultErrorMessage(): string {
    return `We are experiencing technical difficulties. Please contact customer service at ${this.config.email} for assistance.`;
  }

  /**
   * Handle for laravel api response, returns an array of errors
   *
   * @param {HttpErrorResponse} response Http error response that contains the error code and error messages
   */
  handleResponseError(response: HttpErrorResponse): string[] {
    switch (true) {
      case response.status === 422:
        return Object.keys(response.error)
          .map((errKey) => response.error[errKey])
          .flat();

      case (response.status === 400 || response.status === 403) && response.error?.message?.length > 0:
        return [response.error.message];

      case response.status >= 500 && response.status <= 599:
        return [
          `${this.defaultErrorMessage} <br/> Error Code: ${response.status} Error Message: ${response.error?.message}`,
        ];

      default:
        return [this.defaultErrorMessage];
    }
  }

  /**
   * Parses the error message from the error object.
   *
   * @param {string | ExternalPaymentError} error the error object
   *
   * @returns {string} the error message
   */
  parseExternalPaymentErrorMessage(error: string | ExternalPaymentError): string {
    if (typeof error === 'string') {
      return error;
    }

    if (error?.message && typeof error.message === 'string') {
      return error.message;
    }

    return this.defaultErrorMessage;
  }
}
