import { Component, Inject, OnInit } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { OrderCompleteDetails } from '@models/order-complete-details';
import { OrderSummary } from '@models/order-summary';
import { ReviewsFeature } from '@models/reviews-feature';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import * as Sentry from '@sentry/browser';
import { DataLayerService } from '@services/data-layer.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-order-complete',
  templateUrl: './order-complete.component.html',
})
export class OrderCompleteComponent implements OnInit {
  orderCompleteDetails: OrderCompleteDetails;
  displayShopperApprovedCard: boolean = false;
  treatmentType: ConsultationTreatmentTypes;

  get shouldDisplayShopperApproved(): boolean {
    return !!this.config.shopperApproved.site && !!this.config.shopperApproved.token;
  }

  constructor(
    private dataLayerService: DataLayerService,
    private orderService: OrderService,
    private storage: StorageService,
    @Inject(APP_CONFIG) public config: AppConfig
  ) {}

  ngOnInit(): void {
    if (this.shouldDisplayShopperApproved) {
      this.observeShopperApproved();
    }

    this.treatmentType = this.storage.treatmentType;
    this.dataLayerService.handleDataLayer();
    this.orderCompleteDetails = this.getOrderCompleteDetails();
    this.markOrderCompleted();
  }

  /**
   * Consolidate and return all the order details necessary for the order summary component and shopper approved.
   */
  private getOrderCompleteDetails(): OrderCompleteDetails {
    let payments = [];
    payments.push(this.storage.transactionId);

    if (this.storage.upsell.length > 0) {
      payments = payments.concat(this.storage.upsell);
    }

    if (this.storage.secondaryPaymentTransactionId) {
      payments.push(this.storage.secondaryPaymentTransactionId);
    }

    const summary = new OrderSummary(
      this.storage.tests.map((test) => test.name),
      payments,
      this.storage.orderTotal
    );

    const reviewsFeature = new ReviewsFeature(
      this.storage.transactionId,
      this.storage.patient?.name,
      this.storage.patient?.email
    );

    return new OrderCompleteDetails(
      summary,
      this.storage.transactionId,
      this.storage.free,
      reviewsFeature,
      this.storage.sendSMS,
      this.storage.hasEmail,
      this.storage.paymentType == 'payLater',
      this.storage.order.hash,
      this.storage.inHomeBookingResult,
      this.storage.email
    );
  }

  /**
   * Watch for mutations in the shopperApprovedContainer div and displays the
   * Shopper Approved card when the element with the id shopper_approved is
   * found.
   */
  private observeShopperApproved(): void {
    const observer = new MutationObserver((mutations) => {
      if (
        (this.displayShopperApprovedCard = mutations
          .map((m) => Array.from(m.addedNodes))
          .flat()
          .map((n) => (n as any).id)
          .includes('shopper_approved'))
      ) {
        observer.disconnect();
      }
    });

    observer.observe(document.getElementById('shopperApprovedContainer'), {
      childList: true,
      subtree: true,
    });
  }

  /**
   * Dispatches the request that tells API the order is complete.
   *
   * This request will trigger the logic in the API side that will make sure the requisition is in place.
   */
  markOrderCompleted(): void {
    this.orderService.orderCompleted().subscribe(
      () => this.orderService.clearLastOrder(),
      (error) => {
        Sentry.captureException(error);
        this.orderService.clearLastOrder();
      }
    );
  }
}
