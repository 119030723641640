import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from '@services/navigation.service';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class OrderAddressGuard {
  constructor(
    private router: Router,
    private storageService: StorageService,
    private navigationService: NavigationService
  ) {}

  /**
   * Determines if the order address page should be loaded.
   */
  canActivate(): boolean {
    if (!this.storageService.transactionId) {
      this.router.navigateByUrl('/order');

      return false;
    }

    if (!this.storageService.addressComplete) {
      return true;
    }

    this.router.navigate([this.navigationService.getOrderAddressUrl()]);

    return false;
  }
}
