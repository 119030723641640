import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { environment } from '@environments/environment';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { NavigationService } from '@services/navigation.service';
import { SessionStorageService } from '@services/session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class VaginalCreamAllowedGuard implements CanActivate {
  private bvPillTestIds: number[] = [
    environment.metronidazole.id,
    environment.clindamycin.id,
    environment.letTheDoctorDecide.id,
  ];

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private navigationService: NavigationService,
    private router: Router,
    private sessionStorageService: SessionStorageService
  ) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Promise<boolean | UrlTree>} a promise that resolves to true if the route can be activated, or a UrlTree
   * to redirect otherwise
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const nextPageUrl = await this.navigationService.getConsultationRequestNextPageUrl(
      this.sessionStorageService.treatmentType,
      state.url
    );

    return this.bvPillOrdered() ? true : this.router.createUrlTree([nextPageUrl]);
  }

  /**
   * Checks if the patient has ordered the BV pill.
   *
   * @returns {boolean} true if the user has ordered the BV pill, false otherwise
   */
  private bvPillOrdered(): boolean {
    return this.sessionStorageService.orderedTests.some((test) => this.bvPillTestIds.includes(test.test_id));
  }
}
