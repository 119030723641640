import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { OrderSummaryRow } from '@common/order-summary-row';
import { PaymentTabsCardComponent } from '@components/payment-tabs-card/payment-tabs-card.component';
import { PaymentTypes } from '@enums/payment-types';
import { OrderUpsell } from '@models/order-upsell';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DocumentService } from '@services/document.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-upsell-payment-info',
  templateUrl: './upsell-payment-info.component.html',
  styleUrls: ['./upsell-payment-info.component.scss'],
})
export class UpsellPaymentInfoComponent extends PaymentTabsCardComponent implements AfterViewInit {
  @Input() set selectedUpsell(upsell: OrderUpsell[]) {
    this._selectedUpsell = upsell;
    this.setOrderSummaryRows();
  }
  @Input() upsellTestsForm: FormGroup;
  @Input() enablePayLater: boolean = true;

  orderSummaryRows: OrderSummaryRow[] = [];

  private _selectedUpsell: OrderUpsell[] = [];

  constructor(
    @Inject(APP_CONFIG) protected config: AppConfig,
    protected storageService: StorageService,
    private documentService: DocumentService
  ) {
    super(config, storageService);
  }

  /**
   * Scrolls to the bottom of the page after the view has been initialized.
   */
  ngAfterViewInit(): void {
    this.documentService.scrollToBottom();
  }

  /**
   * Gets the selected upsell.
   */
  get selectedUpsell(): OrderUpsell[] {
    return this._selectedUpsell;
  }

  /** @inheritdoc */
  get paymentForm(): FormGroup {
    return this.upsellTestsForm?.get('payment') as FormGroup;
  }

  /** @inheritdoc */
  get disabledPaymentMethods(): PaymentTypes[] {
    return [PaymentTypes.Free, PaymentTypes.GiftCard];
  }

  /** @inheritdoc */
  override get isPayLaterEnabled(): boolean {
    return super.isPayLaterEnabled && !this.storageService.hasInHomeCollection && this.enablePayLater;
  }

  /**
   * Gets the total price of the selected upsell.
   */
  get total(): number {
    return this.selectedUpsell.reduce((acc, upsell) => acc + (upsell.upsell_price || upsell.price), 0);
  }

  /**
   * Adds the upsell to the order summary component.
   */
  private setOrderSummaryRows(): void {
    const upsell = this.selectedUpsell;
    if (!upsell.length) {
      this.orderSummaryRows = [];

      return;
    }

    this.orderSummaryRows = upsell.map((upsell) => ({
      item: `${upsell.name} ${upsell.name?.toLowerCase() === 'trichomoniasis' ? 'Test' : ''}`,
      amountInCents: upsell.upsell_price || upsell.price,
      shouldDisplayRow: true,
    }));
  }
}
