import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { scrollToFirstInvalidControl } from '@common/scroll-to-first-invalid-control';
import { BillingCountry } from '@enums/billing-countries';
import { PaymentTypes } from '@enums/payment-types';
import { IncompleteOrderFormError } from '@errors/incomplete-order-form-error';
import { ExternalPaymentError } from '@models/external-payment-error';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { DomainService } from '@services/domain.service';
import { ErrorHandlerService } from '@services/error-handler.service';
import { PayService } from '@services/external-payments/pay.service';
import { FormService } from '@services/form.service';
import { OrderService } from '@services/order.service';
import { StorageService } from '@services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.scss'],
})
/**
 * Submits the order and display submit errors.
 */
export class PlaceOrderComponent implements OnInit, OnDestroy {
  @Input() orderForm: FormGroup;
  @Input() useSecondaryPaymentForm: boolean = false;
  @Input() paymentFormPath: string = 'payment';
  @Input() labFormPath: string = 'lab_id';
  @Input() formGroupsToBeValidated: string[] = ['patient', 'medicalHistory', 'serviceAgreement'];
  @Input() addressContactInformation: FormGroup;
  @Input() hideWhatHappensNext: boolean = false;
  // Place order request processing status
  @Input() processing: boolean = false;
  @Input() placeOrderButtonText: string = this.config.buttons.checkout;
  @Input() chargeAmount: number;
  @Input() displayTrustBadge: boolean = true;
  @Output() formSubmitted = new EventEmitter<void>();

  PaymentTypes: typeof PaymentTypes = PaymentTypes;

  // Show/hide gift cart required error message
  requiredGiftCardError: boolean = false;
  // Order Submission Errors
  submissionErrors: string[] = [];

  private _paymentForm: AbstractControl;
  private _secondaryPaymentForm: AbstractControl;
  private _requiredFieldsError: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    public domainService: DomainService,
    private orderService: OrderService,
    private formService: FormService,
    private storageService: StorageService,
    private payService: PayService,
    private changeDetectorRef: ChangeDetectorRef,
    private errorHandlerService: ErrorHandlerService,
    @Inject(APP_CONFIG) private config: AppConfig
  ) {}

  /**
   * Show/hide required field error alert.
   */
  get requiredFieldsError(): boolean {
    return this._requiredFieldsError || !this.canUseExternalPaymentMethod(this.selectedPaymentMethod);
  }

  /**
   * Determine if the path of the laboratory form includes the word 'pharmacy'.
   */
  get shouldSelectPharmacy(): boolean {
    return this.labFormPath.includes('pharmacy');
  }

  /**
   * Whether or not the user checked the terms of service box. If the terms are
   * not found on the form, they are considered accepted.
   */
  get termsAreAccepted(): boolean {
    const termsControl = this.orderForm.get('terms');
    if (!termsControl) {
      return true;
    }

    return termsControl.value;
  }

  /**
   * Whether or not the lab id is valid. If the lab id is not found on the form,
   * it is considered valid.
   */
  get labIdValid(): boolean {
    const labId = this.orderForm.get(this.labFormPath);
    if (!labId) {
      return true;
    }

    return labId.valid;
  }

  /**
   * Hide the checkout button when the user selects PayPal, Apple Pay, Google Pay or BitPay.
   */
  get showCheckoutButton(): boolean {
    return (
      [
        PaymentTypes.CreditCard,
        PaymentTypes.Recharge,
        PaymentTypes.PayLater,
        PaymentTypes.GiftCard,
        PaymentTypes.Free,
      ] as string[]
    ).includes(this.selectedPaymentMethod);
  }

  get isPlaceOrderEnable(): boolean {
    return this.orderService.placeOrderButtonDisable;
  }

  /**
   * Validate if the customer selected Other as zip code.
   */
  get isBillingCountryOther(): boolean {
    return (
      this.selectedPaymentMethod === PaymentTypes.CreditCard &&
      this.paymentForm.get('creditCard')?.get('billingCountry').value === BillingCountry.Other
    );
  }

  /**
   * Get the site phone.
   */
  get sitePhone(): string {
    return this.config.phone;
  }

  /**
   * Initialize payment forms and listeners.
   */
  ngOnInit(): void {
    this._paymentForm = this.orderForm.get(this.paymentFormPath);
    this._secondaryPaymentForm = this.orderForm.get('secondaryPayment');
    this.payService.initializePaymentMethods();
    this.checkoutFormListeners();
  }

  /**
   * Unsubscribe all current subscriptions.
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  /**
   * Whether or not to show the payment button based on the provided payment type.
   *
   * @param {string} paymentType the payment type to check
   *
   * @returns {boolean} true if the payment button should be shown, false otherwise
   */
  shouldShowPaymentMethodButton(paymentType: string): boolean {
    return (
      this.selectedPaymentMethod === paymentType &&
      this.termsAreAccepted &&
      this.labIdValid &&
      this.areAllFormGroupsToBeValidatedValid()
    );
  }

  /**
   * Validate the form and emit the form submit event to the parent component.
   */
  submit(): void {
    if (this.checkForInvalidFormControl()) {
      this._requiredFieldsError = true;
      scrollToFirstInvalidControl();

      return;
    }

    if (
      this.orderForm.get(this.paymentFormPath).get('method').value === PaymentTypes.GiftCard &&
      !this.orderService.giftCardApplied
    ) {
      this.requiredGiftCardError = true;

      return;
    }

    this.submissionErrors = [];
    this.formSubmitted.emit();
  }

  /**
   * Add the necessary form's subscriptions to the subscriptions array.
   */
  checkoutFormListeners(): void {
    this.subscriptions.push(
      this.orderService.giftCardAppliedSubject$.subscribe(() => {
        this.requiredGiftCardError = false;
      }),

      this.formService.$paymentMethodObservable.subscribe((type: string) => {
        if (!this.canUseExternalPaymentMethod(type)) {
          this.orderForm.markAllAsTouched();
        }
      }),

      this.orderForm.valueChanges.subscribe(() => {
        if (this.orderForm.valid) {
          this._requiredFieldsError = false;
        }
      })
    );
  }

  /**
   * Get an error message that is meaningful to the user.
   *
   * @returns a string with the message about the form error.
   */
  getErrorMessage(): string {
    const defaultMessage = new IncompleteOrderFormError().message;

    if (this.shouldSelectPharmacy && !this.areAllFormGroupsToBeValidatedValid()) {
      return defaultMessage;
    }

    if (!this.labIdValid) {
      return this.shouldSelectPharmacy ? 'Please choose a pharmacy location.' : 'Please select a lab.';
    }

    if (!this.termsAreAccepted) {
      return 'You must agree to the Notice of Privacy Practices, Informed Consent, and the Terms & Conditions.';
    }

    return defaultMessage;
  }

  /**
   * Submits the payment using an external payment method.
   *
   * @param {PaymentTypes} service the external payment method to use
   */
  payWithPaymentService(service: PaymentTypes): void {
    this.submissionErrors = [];
    this.processing = true;

    this.payService
      .pay(this.chargeAmount || this.orderService.invoiceAmountInUsd, service)
      .then((nonce: string) => this.handleExternalPaymentSuccess(service, nonce))
      .catch((error: string | ExternalPaymentError) => this.handleExternalPaymentError(error));
  }

  /**
   * Get the currently selected payment method value.
   */
  private get selectedPaymentMethod(): string {
    return this.paymentForm.get('method').value;
  }

  /**
   * Get the payment form to use.
   */
  private get paymentForm(): AbstractControl {
    return this.useSecondaryPaymentForm ? this._secondaryPaymentForm : this._paymentForm;
  }

  /**
   * Checks if the user can proceed to pay with an external payment method.
   *
   * @param {string} paymentMethod the payment method to be checked
   *
   * @returns {boolean} true if the user can proceed, otherwise false
   */
  private canUseExternalPaymentMethod(paymentMethod: string): boolean {
    const externalPaymentMethods: string[] = [
      PaymentTypes.Paypal,
      PaymentTypes.BitPay,
      PaymentTypes.ApplePay,
      PaymentTypes.GooglePay,
      PaymentTypes.Venmo,
    ];

    return !externalPaymentMethods.includes(paymentMethod) || this.shouldShowPaymentMethodButton(paymentMethod);
  }

  /**
   * Handles a successful external payment.
   *
   * @param {PaymentTypes} service the external payment method type
   * @param {string} nonce the payment nonce
   */
  private handleExternalPaymentSuccess(service: PaymentTypes, nonce: string): void {
    this.payService.setNonceToForm(this.paymentForm, nonce, service);
    this.submit();
  }

  /**
   * Handles an external payment error by parsing the error message and adding it to the submission errors.
   *
   * @param {string | ExternalPaymentError} error the external payment error
   */
  private handleExternalPaymentError(error: string | ExternalPaymentError): void {
    this.processing = false;
    if (typeof error !== 'string' && error?.statusCode === 'CANCELED') {
      return;
    }

    this.submissionErrors.push(this.errorHandlerService.parseExternalPaymentErrorMessage(error));
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Check if there are any invalid controls on the form.
   *
   * @returns true if there is at least one invalid control, false otherwise
   */
  private checkForInvalidFormControl(): boolean {
    let hasInvalidControl = false;

    document.querySelector('input[name="sex"]:checked')?.dispatchEvent(new Event('change'));
    this.formGroupsToBeValidated.forEach((formGroupPath) => this.orderForm.get(formGroupPath).updateValueAndValidity());

    if (this.storageService.free && this.addressContactInformation?.invalid) {
      this.addressContactInformation.markAllAsTouched();
      hasInvalidControl = true;
    }

    if (this.orderForm.invalid) {
      this.orderForm.markAllAsTouched();
      hasInvalidControl = true;
    }

    return hasInvalidControl;
  }

  /**
   * Determine if the child form groups to be validated are valid.
   *
   * @returns true if all form groups in the order form to be validated are valid,
   * false otherwise
   */
  private areAllFormGroupsToBeValidatedValid(): boolean {
    return !this.formGroupsToBeValidated
      .map((formGroupPath) => this.orderForm.get(formGroupPath))
      .some((control) => !control.valid);
  }
}
