import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { DateSelection } from '../models/date-selection';

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors => {
    const dateSelection = new DateSelection(
      Number(control.get('month').value),
      Number(control.get('day').value),
      Number(control.get('year').value)
    );

    if (!dateSelection.date) {
      return { invalidDate: 'Please complete the date' };
    }

    const calculatedDate = {
      month: dateSelection.date.getMonth() + 1,
      day: dateSelection.date.getDate(),
      year: dateSelection.date.getFullYear(),
    };

    return dateSelection.month === calculatedDate.month &&
      dateSelection.day === calculatedDate.day &&
      dateSelection.year === calculatedDate.year
      ? null
      : { invalidDate: 'Please enter a valid date' };
  };
}
