import { Injectable } from '@angular/core';
import { MimeTypes } from '@enums/mime-types';
import { SessionStorageService } from '@services/session-storage.service';
import heic2any from 'heic2any';
import { NgxImageCompressService } from 'ngx-image-compress';
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(
    private imageCompressService: NgxImageCompressService,
    private sessionStorageService: SessionStorageService
  ) {
    GlobalWorkerOptions.workerSrc = 'assets/pdfjs/pdf.worker.min.js';
  }

  /**
   * Check if the file type is convertible to an image.
   *
   * @param {File} file The file to check if it is convertible.
   *
   * @returns {boolean} True if the file type is convertible to an image, false otherwise.
   */
  private isConvertibleToImage(file: File): boolean {
    return file.type === MimeTypes.HEIC || file.type === MimeTypes.PDF;
  }

  /**
   * Check if the file type is a compressible image.
   *
   * @param {File} file The file to check if it is compressible.
   *
   * @returns {boolean} True if the file type is a compressible image, false otherwise.
   */
  private isCompressibleImage(file: File): boolean {
    return file.type === MimeTypes.JPEG || file.type === MimeTypes.PNG;
  }

  /**
   * Converts the file to a compressed image.
   *
   * @param {File} file The file to be converted.
   *
   * @returns {Promise<File>} The image file.
   */
  private convertToCompressedImage(file: File): Promise<File> {
    if (file.type === MimeTypes.PDF) {
      return this.convertPdfToCompressedImage(file);
    }

    return this.convertHeicToCompressedImage(file);
  }

  /**
   * Compresses the image file.
   *
   * @param {File} file The file type image to be compressed.
   *
   * @returns {Promise<File>} The compressed file.
   */
  private compressImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      this.readFileAsDataURL(file)
        .then((imageDataURL: string) => this.compressImageDataUrl(imageDataURL))
        .then((imageDataURLCompressed: string) => this.convertDataURLToFile(imageDataURLCompressed, file.name))
        .then((compressedFile: File) => resolve(compressedFile))
        .catch((error: Error) => reject(error));
    });
  }

  /**
   * Compresses a file if it is convertible to an image or if it is a compressible image.
   *
   * If the file cannot be converted or compressed, it captures an error event and returns the original file.
   *
   * @param {File} file the file to be compressed
   *
   * @returns {Promise<File>} a promise that resolves to the compressed file or the original file if compression fails
   */
  getCompressedFile(file: File): Promise<File> {
    if (this.isConvertibleToImage(file)) {
      return this.convertToCompressedImage(file).catch(() => file);
    }

    if (this.isCompressibleImage(file)) {
      return this.compressImage(file).catch(() => file);
    }

    return Promise.resolve(file);
  }

  /**
   * Compress the Image DataURL based on the original image width.
   *
   * @param {string} imageDataURL The image data URL to be compressed.
   */
  private compressImageDataUrl(imageDataURL: string): Promise<string> {
    return this.getScaleFactor(imageDataURL).then((compressRatio: number) =>
      this.imageCompressService.compressFile(imageDataURL, 0, compressRatio)
    );
  }

  /**
   * Calculates the scale factor of the compressed image to conserve storage space.
   *
   * If the image width is less than the expected one, the ratio will be 100.
   *
   * @param {string} imageDataURL  The image data URL to get the image width.
   * @param {number} expectedWidth The expected width of the image in pixels.
   *
   * @returns {Promise<number>} The scale factor for the compressed image.
   */
  private getScaleFactor(imageDataURL: string, expectedWidth: number = 1000): Promise<number> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image.width <= expectedWidth ? 100 : (expectedWidth * 100) / image.width);
      image.onerror = (error: ErrorEvent) => reject(error);
      image.src = imageDataURL;
    });
  }

  /**
   * Converts the PDF first page to a compressed image.
   *
   * @param {File} file The Heic file that will be converted to image.
   *
   * @returns {Promise<File>} The converted image file.
   */
  private convertPdfToCompressedImage(file: File): Promise<File> {
    return file
      .arrayBuffer()
      .then((pdfArrayBuffer: ArrayBuffer) => getDocument(pdfArrayBuffer).promise)
      .then((pdf: PDFDocumentProxy) => pdf.getPage(1))
      .then((firstPage: PDFPageProxy) => this.convertPdfPageToCanvas(firstPage))
      .then((canvas: HTMLCanvasElement) => canvas.toDataURL(MimeTypes.PNG, 1))
      .then((imageDataURL: string) => this.compressImageDataUrl(imageDataURL))
      .then((imageDataURLCompressed: string) =>
        this.convertDataURLToFile(imageDataURLCompressed, this.replaceExtension(file, '.png'))
      )
      .catch((error: Error) => Promise.reject(error));
  }

  /**
   * Converts the PDF page to a canvas with specific width, following the official PDF.js suggestion.
   *
   * @param {PDFPageProxy} page         The PDF page to be converted to canvas.
   * @param {number}       desiredWidth The desired width of the canvas in pixels.
   *
   * @returns {Promise<HTMLCanvasElement>} The canvas element.
   */
  private convertPdfPageToCanvas(page: PDFPageProxy, desiredWidth: number = 1000): Promise<HTMLCanvasElement> {
    const canvas = document.createElement('canvas');
    const originalViewport = page.getViewport({ scale: 1 });
    const viewport = page.getViewport({ scale: desiredWidth / originalViewport.width });

    canvas.height = viewport.height;
    canvas.width = viewport.width;

    return page
      .render({
        canvasContext: canvas.getContext('2d'),
        viewport: viewport,
      })
      .promise.then(() => canvas);
  }

  /**
   * Converts the Heic file to an image.
   *
   * @param {File} file The Heic file that will be converted to image.
   *
   * @returns {Promise<File>} The converted image file.
   */
  private convertHeicToCompressedImage(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      this.readFileAsDataURL(file)
        .then((imageDataURL: string) => this.convertDataURLToBlob(imageDataURL))
        .then((blob: Blob) => heic2any({ blob, toType: MimeTypes.JPEG, quality: 0.5 }))
        .then((blobConverted: Blob) => this.readFileAsDataURL(blobConverted))
        .then((dataURL: string) => this.compressImageDataUrl(dataURL))
        .then((dataURLCompressed: string) =>
          this.convertDataURLToFile(dataURLCompressed, this.replaceExtension(file, '.jpg'))
        )
        .then((fileConverted: File) => resolve(fileConverted))
        .catch((error: Error) => reject(error));
    });
  }

  /**
   * Reads the file as a DataURL.
   *
   * @param {File | Blob} file The file to be read.
   *
   * @returns {Promise<string>} The file as a DataURL.
   */
  private readFileAsDataURL(file: File | Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (progressEvent: ProgressEvent<FileReader>) => resolve(progressEvent.target!.result as string);
      reader.onerror = (error: ProgressEvent<FileReader>) => reject(error);
      reader.readAsDataURL(file);
    });
  }

  /**
   * Converts a DataURL to a Blob.
   *
   * @param {string} dataURL The DataURL to be converted.
   *
   * @returns {Promise<Blob>} The Blob representation of the DataURL.
   */
  private convertDataURLToBlob(dataURL: string): Promise<Blob> {
    return fetch(dataURL).then((response: Response) => response.blob());
  }

  /**
   * Converts a Blob to a File.
   *
   * @param {Blob}   blob The Blob to convert.
   * @param {string} name The file's name.
   *
   * @returns {File} The File converted from the Blob.
   */
  private convertBlobToFile(blob: Blob, name: string): File {
    return new File([blob], name, { type: blob.type });
  }

  /**
   * Converts a DataURL to a File
   *
   * @param {string} dataURL The DataURL to convert.
   * @param {string} name    The file's name.
   *
   * @returns {Promise<File>} The File converted from the DataURL.
   */
  private convertDataURLToFile(dataURL: string, name: string): Promise<File> {
    return this.convertDataURLToBlob(dataURL).then((blob: Blob) => this.convertBlobToFile(blob, name));
  }

  /**
   * Returns the new file name extension.
   *
   * @param {File}   file      The file to update the extension.
   * @param {string} extension The new file extension.
   *
   * @returns {string} The updated file name.
   */
  private replaceExtension(file: File, extension: string): string {
    return file.name.replace(/\.[a-zA-Z0-9]+$/, extension);
  }
}
