// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone {
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  min-height: 75px;
  gap: 8px;
}
.dropzone:hover {
  border-color: #b5b5b5;
  cursor: pointer;
}

.uploaded-file-name {
  word-break: break-all;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
