import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validates the size of a file against a maximum size.
 *
 * @param {number} maxSizeInBytes the maximum allowable size of the file in bytes
 *
 * @returns {ValidatorFn} a validator function that checks if the file size is within the limit
 */
export function fileSizeValidator(maxSizeInBytes: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const file = control.value;

    if (file?.size === undefined || file?.size === null) {
      return null;
    }

    return file.size <= maxSizeInBytes ? null : { fileSize: { maxSize: maxSizeInBytes, actualSize: file.size } };
  };
}
