import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PaymentTypes } from '@enums/payment-types';
import { UpsellStoreRequest } from '@models/requests/upsell-store.model';
import { NavigationService } from '@services/navigation.service';
import { OrderService } from '@services/order.service';
import { SessionStorageService } from '@services/session-storage.service';
import { TreatmentConsultationQuestionnaireService } from '@services/treatment-consultation-questionnaire.service';
import { from, map, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConsultationUpsellPaymentGuard implements CanActivate {
  constructor(
    private navigationService: NavigationService,
    private orderService: OrderService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private treatmentConsultationQuestionnaireService: TreatmentConsultationQuestionnaireService
  ) {}

  /**
   * Determines whether the current route can be activated.
   *
   * @param {ActivatedRouteSnapshot} route the route to be activated
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Observable<boolean | UrlTree>} an observable that emits true if the route can be activated, or a UrlTree
   * to redirect otherwise
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.submitUpsells().pipe(
      switchMap(() => from(this.navigateToNextPage(state))),
      catchError(() => of(true))
    );
  }

  /**
   * Constructs the request payload for storing an upsell associated with the current consultation.
   *
   * @returns {UpsellStoreRequest} the request payload for storing an upsell
   */
  private getUpsellStoreRequest(): UpsellStoreRequest {
    return new UpsellStoreRequest(
      this.treatmentConsultationQuestionnaireService.getUnorderedUpsells(),
      PaymentTypes.Recharge
    );
  }

  /**
   * Navigates to the next page in the consultation flow based on the current treatment type and URL.
   *
   * @param {RouterStateSnapshot} state the current router state
   *
   * @returns {Promise<UrlTree>} a UrlTree representing the next page URL
   */
  private async navigateToNextPage(state: RouterStateSnapshot): Promise<UrlTree> {
    const nextPageUrl = await this.navigationService.getConsultationRequestNextPageUrl(
      this.sessionStorageService.treatmentType,
      state.url
    );

    return this.router.createUrlTree([nextPageUrl]);
  }

  /**
   * Submits the upsells associated with the current consultation.
   *
   * @returns {Observable<void>} an observable that completes when the upsells are submitted
   */
  private submitUpsells(): Observable<void> {
    return this.orderService.storeUpsell(this.getUpsellStoreRequest(), this.sessionStorageService.transactionId).pipe(
      map((response) => {
        if (response?.transaction_id) {
          this.sessionStorageService.upsell = [response.transaction_id];
        }
      })
    );
  }
}
