import { NgClass, NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Question } from '@models/dynamic-forms/question';

@Component({
  selector: 'app-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf, NgClass],
})
export class RadioInputComponent {
  @Input() form: FormGroup;
  @Input() question: Question;
}
