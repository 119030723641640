import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '@services/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  constructor(private storage: StorageService) {}

  /**
   * Get an options object with the Authorization header of type Bearer with the token passed
   * as a parameter or the storage token as default.
   *
   * @param token authorization token
   *
   * @returns options object for http requests
   */
  getOptionsWithAuthHeader(token = this.storage.authToken): { headers: HttpHeaders } {
    return { headers: new HttpHeaders({ Authorization: `Bearer ${token}` }) };
  }
}
