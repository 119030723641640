import { Component } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';

@Component({
  selector: 'app-clotrimazole-vaginal-cream',
  templateUrl: './clotrimazole-vaginal-cream.component.html',
  styleUrl: './clotrimazole-vaginal-cream.component.scss',
})
export class ClotrimazoleVaginalCreamComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.ClotrimazoleVaginalCream;

  constructor() {}
}
