import { Injectable } from '@angular/core';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { InHomeBookingResult } from '@Medology/ng-findalab';
import { AnalyteCareCouponDto } from '@models/analyte-care-coupon-dto';
import { Center } from '@models/center';
import { Coupon } from '@models/coupon';
import { Questionnaire } from '@models/dynamic-forms/questionnaire';
import { QuestionnaireAnswers } from '@models/dynamic-forms/questionnaire-answers';
import { ExtraCharge } from '@models/extra-charge';
import { Fee } from '@models/fee';
import { OrderResponse } from '@models/order-response';
import { OrderTest } from '@models/order-test';
import { Patient } from '@models/patient';
import { Test } from '@models/test';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // To be compatible with legacy keys: ngStorage-token, etc.
  protected prefix = 'ngStorage';
  protected storage = localStorage;

  testsSubject = new ReplaySubject<Test[]>();

  // tests getter and setter
  get tests(): Test[] {
    return this.get('tests') ?? [];
  }

  set tests(tests: Test[]) {
    this.set('tests', tests);
    this.testsSubject.next(tests);
  }

  // coupon getter and setter
  get coupon(): Coupon {
    return this.get('coupon');
  }

  set coupon(coupon: Coupon) {
    this.set('coupon', coupon);
  }

  // discount getter and setter
  get discount(): AnalyteCareCouponDto {
    return this.get('discount');
  }

  set discount(discount: AnalyteCareCouponDto) {
    this.set('discount', discount);
  }

  // fee getter and setter
  get fee(): Fee {
    return this.get('fee');
  }

  set fee(fee: Fee) {
    this.set('fee', fee);
  }

  protected pscKeys = [
    'id',
    'title',
    'country',
    'address',
    'address2',
    'city',
    'state',
    'zip_code',
    'network',
    'phone',
  ];

  // center getter and setter
  get center(): Center {
    return this.get('center');
  }

  set center(center: Center) {
    const refinedCenter = {};
    for (const key in center) {
      if (this.pscKeys.includes(key)) {
        refinedCenter[key] = center[key];
      }
    }
    this.set('center', center ? refinedCenter : null);
  }

  // email getter and setter
  get email(): string {
    return this.get('email');
  }

  set email(email: string) {
    this.set('email', email);
  }

  // Auth token getter and setter
  get authToken(): string {
    return this.get('auth-token');
  }

  set authToken(authToken: string) {
    this.set('auth-token', JSON.stringify(authToken));
  }

  // data layer pool getter and setter
  get datalayerPool(): any[] {
    return this.get('datalayer_pool') ?? [];
  }

  set datalayerPool(datalayerPool: any[]) {
    this.set('datalayer_pool', datalayerPool);
  }

  // isBaOrder getter and setter
  get isBaOrder(): boolean {
    return this.get('isBaOrder', false);
  }

  set isBaOrder(isBaOrder: boolean) {
    this.set('isBaOrder', isBaOrder, false);
  }

  // transaction_id getter and setter
  get transactionId(): string {
    return this.get('transaction_id', false) ?? '';
  }

  set transactionId(transactionId: string) {
    this.set('transaction_id', transactionId, false);
  }

  // order detail getter and setter
  get order(): OrderResponse {
    return this.get('order', false) ?? '';
  }

  set order(order: OrderResponse) {
    this.set('order', order, false);
  }

  // Secondary payment transaction_id getter and setter
  get secondaryPaymentTransactionId(): string {
    return this.get('secondary_payment_transaction_id', false);
  }

  set secondaryPaymentTransactionId(transactionId: string) {
    this.set('secondary_payment_transaction_id', transactionId, false);
  }

  // Better Lab flag getter and setter
  get betterLab(): Boolean {
    return this.get('betterlab');
  }

  set betterLab(betterLab: Boolean) {
    this.set('betterlab', betterLab);
  }

  // Lab Changed flag getter and setter
  get labChanged(): string {
    return this.get('lab_changed');
  }

  set labChanged(labChanged: string) {
    this.set('lab_changed', labChanged);
  }

  // Free order flag getter and setter
  get free(): boolean {
    return this.get('free');
  }

  set free(free: boolean) {
    this.set('free', free);
  }

  // Order payment type flag getter and setter
  get paymentType(): string {
    return this.get('paymentType');
  }

  set paymentType(paymentType: string) {
    this.set('paymentType', JSON.stringify(paymentType));
  }

  // Order has partner getter and setter
  get hasPartner(): boolean {
    return this.get('hasPartner');
  }

  set hasPartner(hasPartner: boolean) {
    this.set('hasPartner', hasPartner);
  }

  /**
   * Get inHomeCollection from localstorage
   */
  get inHomeCollection(): boolean {
    return this.get('inHomeCollection');
  }

  /**
   * Set inHomeCollection to localstorage
   */
  set inHomeCollection(enabled: boolean) {
    this.set('inHomeCollection', enabled);
  }

  /**
   * Gets if the order address page has already been completed from local storage.
   */
  get addressComplete(): boolean {
    return !!this.get('address_complete') ?? false;
  }

  /**
   * Set the status of the order address page in local storage.
   *
   * @param {boolean} addressComplete
   */
  set addressComplete(addressComplete: boolean) {
    this.set('address_complete', addressComplete, true);
  }

  // orderType getter and setter
  get orderType(): 'email' | 'phone' | 'none' {
    return this.get('order_type', false);
  }

  set orderType(orderType: 'email' | 'phone' | 'none') {
    this.set('order_type', orderType, false);
  }

  /**
   * Retrieves patient information in local storage to be use in order-complete page.
   */
  get patient(): Patient {
    return this.get('patient');
  }

  /**
   * Saves patient information from local storage
   *
   * @param {object} patient Patient information to be stored
   */
  set patient(patient: Patient) {
    this.set('patient', patient);
  }

  /**
   * Retrieve flag used to identify if the user allowed us to message them
   */
  get sendSMS(): boolean {
    return !!this.get('sendSMS') ?? false;
  }

  /**
   * Saves sendSMS flag to local storage
   *
   * @param {boolean} sendSMS User allowed sms messages
   */
  set sendSMS(sendSMS: boolean) {
    this.set('sendSMS', sendSMS);
  }

  /**
   * Retrieve flag used to determine if patient has informed their email
   */
  get hasEmail(): boolean {
    return !!this.get('hasEmail') ?? false;
  }

  /**
   * Saves flag used to determine if patient has informed their email
   *
   * @param {boolean} hasEmail Patient informed their email
   */
  set hasEmail(hasEmail: boolean) {
    this.set('hasEmail', hasEmail);
  }

  /**
   * Retrieve order total information from local storage
   */
  get orderTotal(): number {
    return this.get('orderTotal');
  }

  /**
   * Saves the order total in local storage
   *
   * @param {number} total Order total
   */
  set orderTotal(total: number) {
    this.set('orderTotal', total);
  }

  /**
   * Retrieve upsell transaction id
   */
  get upsell(): string[] {
    return this.get('upsell_transaction_id', false) ?? [];
  }

  /**
   * Saves upsell transaction id if exists
   *
   * @param {string} upsell Order total
   */
  set upsell(upsell: string[]) {
    this.set('upsell_transaction_id', upsell, false);
  }

  /**
   * Get the zipcode
   */
  get zipcode(): string {
    return this.get('zip_code');
  }

  /**
   * Saves the zipcode
   *
   * @param {string} zipcode the zipcode
   */
  set zipcode(zipcode: string) {
    this.set('zip_code', JSON.stringify(zipcode));
  }

  /**
   * Save in-home booking result
   * @param inHomeBookingResult
   */
  set inHomeBookingResult(inHomeBookingResult: InHomeBookingResult) {
    this.set('in-home-booking-result', inHomeBookingResult);
  }

  /**
   * Get in-home booking result
   */
  get inHomeBookingResult(): InHomeBookingResult {
    return this.get('in-home-booking-result');
  }

  /**
   * Determines if in home collection is selected.
   */
  get hasInHomeCollection(): boolean {
    return !!this.inHomeBookingResult;
  }

  /**
   * Retrieves the answers for the medical history questionnaire.
   *
   * @returns {QuestionnaireAnswers} the answers of the medical history questionnaire
   */
  get medicalHistoryQuestionnaireAnswers(): QuestionnaireAnswers {
    return this.getQuestionnaireAnswers('medicalHistory');
  }

  /**
   * Stores the answers for the medical history questionnaire.
   *
   * @param {QuestionnaireAnswers} answers the answers to be stored for the medical history questionnaire
   */
  set medicalHistoryQuestionnaireAnswers(answers: QuestionnaireAnswers) {
    this.setQuestionnaireAnswers('medicalHistory', answers);
  }

  /**
   * Retrieves the answers for a specific questionnaire.
   *
   * @param {string} questionnaireId the ID of the questionnaire
   *
   * @returns {QuestionnaireAnswers} the answers of the specified questionnaire
   */
  getQuestionnaireAnswers(questionnaireId: string): QuestionnaireAnswers {
    return this.get(`questionnaire-${questionnaireId}`);
  }

  /**
   * Stores the answers for a specific questionnaire.
   *
   * @param {string}               questionnaireId the ID of the questionnaire
   * @param {QuestionnaireAnswers} answers         the answers to be stored for the specified questionnaire
   */
  setQuestionnaireAnswers(questionnaireId: string, answers: QuestionnaireAnswers): void {
    this.set(`questionnaire-${questionnaireId}`, answers);
  }

  /**
   * Retrieves the structure of a specific questionnaire.
   *
   * @param {string} questionnaireId the ID of the questionnaire
   *
   * @returns {Questionnaire} the structure of the specified questionnaire
   */
  getQuestionnaireStructure(questionnaireId: string): Questionnaire {
    return this.get(`questionnaire-structure-${questionnaireId}`);
  }

  /**
   * Stores the structure of a specific questionnaire.
   *
   * @param {string} questionnaireId the ID of the questionnaire
   * @param {Questionnaire} structure the structure to be stored for the specified questionnaire
   */
  setQuestionnaireStructure(questionnaireId: string, structure: Questionnaire): void {
    this.set(`questionnaire-structure-${questionnaireId}`, structure);
  }

  /**
   * Set the provider ids for in-home collection.
   */
  set providerIds(providerIds: number[]) {
    this.set('provider-ids', providerIds);
  }

  /**
   * Get the provider ids for in-home collection.
   */
  get providerIds(): number[] {
    return this.get('provider-ids');
  }

  /**
   * Stores the treatment type of the consultation request.
   *
   * @param {ConsultationTreatmentTypes} treatmentType the treatment type of the consultation request
   */
  set treatmentType(treatmentType: ConsultationTreatmentTypes) {
    this.set('treatmentType', treatmentType);
  }

  /**
   * Gets the consultation request treatment type.
   */
  get treatmentType(): ConsultationTreatmentTypes {
    return this.get('treatmentType');
  }

  /**
   * Stores the extra charges in the local storage.
   *
   * @param {ExtraCharge[]} extraCharges the extra charges to store
   */
  set extraCharges(extraCharges: ExtraCharge[]) {
    this.set('extraCharges', extraCharges);
  }

  /**
   * Gets the extra charges from the local storage.
   */
  get extraCharges(): ExtraCharge[] {
    return this.get('extraCharges') ?? [];
  }

  // orderedTests getter and setter
  get orderedTests(): OrderTest[] {
    return this.get('orderedTests') ?? [];
  }

  set orderedTests(orderedTests: OrderTest[]) {
    this.set('orderedTests', orderedTests);
  }

  /**
   * Get the key from localStorage.
   */
  protected get(key: string, usePrefix: boolean = true): any {
    const value = this.storage.getItem(`${usePrefix ? this.prefix + '-' : ''}${key}`);

    return this.parseResult(value);
  }

  /**
   * Set the key with value on localStorage.
   */
  protected set(key: string, value: any, usePrefix: boolean = true): void {
    this.storage.setItem(`${usePrefix ? this.prefix + '-' : ''}${key}`, this.stringifyStorageValue(value));
  }

  /**
   * Get cookie by key
   *
   * @param {string} key The key index to identify the cookie
   */
  public getCookie(key: string): any {
    const cookie = document.cookie.match(new RegExp('(^| )' + key + '=([^;]+)'));

    return cookie ? cookie[2] : null;
  }

  /**
   * Remove the key from localStorage if exists.
   */
  public remove(key: string, usePrefix: boolean = true): void {
    localStorage.removeItem(`${usePrefix ? this.prefix + '-' : ''}${key}`);
  }

  /**
   * Clears from storage all the keys related to the consultation request completed page.
   */
  removeConsultationRequestCompletedData(): void {
    this.patient = null;
    this.transactionId = null;
  }

  /**
   * Parses a value from storage
   *
   * @param {string} value The value to parse.
   */
  protected parseResult(value: string): any {
    try {
      return JSON.parse(value);
    } catch (error) {
      if (!(error instanceof SyntaxError)) {
        throw error;
      }
    }

    return value;
  }

  /**
   * Stringify storage value
   *
   * @param {any} value The value to stringify.
   */
  protected stringifyStorageValue(value: any): string {
    return typeof value === 'string' ? value : JSON.stringify(value);
  }
}
