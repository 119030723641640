import { Inject, Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PaymentTypes } from '@enums/payment-types';
import { PaymentMethodIcon } from '@models/payments/payment-method-icon';
import { Recharge } from '@models/recharge';
import { SelectableOption } from '@models/selectable-option';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ApplePayService } from '@services/external-payments/apple-pay.service';
import { BitPayService } from '@services/external-payments/bitpay.service';
import { ExternalPaymentMethodService } from '@services/external-payments/external-payment-method.service';
import { GooglePayService } from '@services/external-payments/google-pay.service';
import { PaypalService } from '@services/external-payments/paypal.service';
import { VenmoService } from '@services/external-payments/venmo.service';

@Injectable({
  providedIn: 'root',
})
export class PayService {
  constructor(
    private applePayService: ApplePayService,
    private bitPayService: BitPayService,
    @Inject(APP_CONFIG) private config: AppConfig,
    private googlePayService: GooglePayService,
    private paypalService: PaypalService,
    private venmoService: VenmoService
  ) {}

  /**
   * Retrieves the list of available payment options, filtering out disabled ones.
   *
   * @param {PaymentTypes[]} disabledPaymentMethods an array of payment methods to be excluded from the available options
   * @param {Recharge} [recharge] an optional recharge object containing details about the recharge payment option
   *
   * @returns {SelectableOption[]} an array of selectable payment options that are available for use
   */
  getAvailablePaymentOptions(disabledPaymentMethods: PaymentTypes[] = [], recharge?: Recharge): SelectableOption[] {
    return [
      { value: PaymentTypes.CreditCard, available: true },
      { value: PaymentTypes.ApplePay, available: this.isExternalPaymentMethodAvailable(PaymentTypes.ApplePay) },
      { value: PaymentTypes.BitPay, available: this.isExternalPaymentMethodAvailable(PaymentTypes.BitPay) },
      { value: PaymentTypes.GooglePay, available: this.isExternalPaymentMethodAvailable(PaymentTypes.GooglePay) },
      { value: PaymentTypes.Paypal, available: true },
      { value: PaymentTypes.Venmo, available: this.isExternalPaymentMethodAvailable(PaymentTypes.Venmo) },
      { value: PaymentTypes.GiftCard, label: `${this.config.domain} Gift Card`, available: true },
      {
        value: PaymentTypes.Recharge,
        label: `Recharge: ${recharge?.detail?.display_name ?? ''}`,
        available: !!recharge,
      },
      { value: PaymentTypes.Free, label: 'Free', available: true },
    ]
      .map((method) => ({
        ...method,
        label: method.label || method.value,
        available: method.available && !disabledPaymentMethods.includes(method.value),
      }))
      .filter((method) => method.available);
  }

  /**
   * Retrieves an array of icons that correspond to the provided payment methods.
   *
   * @param {PaymentTypes[]} paymentMethods an array of payment methods to match icons with
   *
   * @returns {PaymentMethodIcon[]} an array of payment method icons filtered by the specified payment methods
   */
  getPaymentMethodIcons(paymentMethods: PaymentTypes[]): PaymentMethodIcon[] {
    return [
      {
        paymentType: PaymentTypes.CreditCard,
        icons: [
          { name: 'Visa', src: 'cc-visa.svg' },
          { name: 'Mastercard', src: 'cc-mastercard.svg' },
          { name: 'Discover', src: 'cc-discover.svg' },
          { name: 'American Express', src: 'cc-amex.svg' },
        ],
      },
      { paymentType: PaymentTypes.ApplePay, icons: [{ name: 'Apple Pay', src: 'apple-pay.svg' }] },
      { paymentType: PaymentTypes.Venmo, icons: [{ name: 'Venmo', src: 'venmo.svg' }] },
      { paymentType: PaymentTypes.Paypal, icons: [{ name: 'PayPal', src: 'paypal.png' }] },
      {
        paymentType: null,
        icons: [
          { name: 'HSA', src: 'cc-hsa.svg' },
          { name: 'FSA', src: 'cc-fsa.svg' },
        ],
      },
      { paymentType: PaymentTypes.BitPay, icons: [{ name: 'Bitcoin/Bitcoin Cash', src: 'bit-pay.png' }] },
      { paymentType: PaymentTypes.GooglePay, icons: [{ name: 'Google Pay', src: 'google-pay.png' }] },
    ]
      .filter((option) => !option.paymentType || paymentMethods.includes(option.paymentType))
      .flatMap((option) => option.icons);
  }

  /**
   * Initializes the payment methods.
   *
   * We need to render the Venmo button to initialize the Venmo instance here, because we need to have the instance to
   * check the browser support.
   */
  initializePaymentMethods(): void {
    this.venmoService.renderPaymentButton().subscribe();

    this.googlePayService.initialize().subscribe();

    if (this.config.enableBitPay) {
      this.bitPayService.initialize().subscribe();
    }
  }

  /**
   * Gets whether the payment method is available.
   *
   * @param {PaymentTypes} paymentType the payment method to check
   *
   * @returns {boolean} true if the payment method is available, false otherwise
   */
  isExternalPaymentMethodAvailable(paymentType: PaymentTypes): boolean {
    return this.getExternalPaymentService(paymentType).isAvailable;
  }

  /**
   * Charges the user using the specified payment service.
   *
   * @param {number} amount the amount to charge
   * @param {PaymentTypes} paymentType the payment method to use
   *
   * @returns {Promise<string>} a promise that resolves with the nonce when the payment is authorized or rejects if the
   * payment fails
   */
  pay(amount: number, paymentType: PaymentTypes): Promise<string> {
    return this.getExternalPaymentService(paymentType).pay(amount);
  }

  /**
   * Sets the nonce value for the external payment method to the form control.
   *
   * @param {AbstractControl} paymentMethodControl the form control where the nonce will be set
   * @param {string} nonce the nonce value to set
   * @param {PaymentTypes} paymentType the payment method to use
   */
  setNonceToForm(paymentMethodControl: AbstractControl, nonce: string, paymentType: PaymentTypes): void {
    return this.getExternalPaymentService(paymentType).setNonceToForm(paymentMethodControl, nonce);
  }

  /**
   * Gets the external payment service to use based on the payment type.
   *
   * @param {PaymentTypes} paymentType the payment method to use
   *
   * @returns {ExternalPaymentMethodService} the external payment service to use
   */
  private getExternalPaymentService(paymentType: PaymentTypes): ExternalPaymentMethodService {
    switch (paymentType) {
      case PaymentTypes.ApplePay:
        return this.applePayService;
      case PaymentTypes.BitPay:
        return this.bitPayService;
      case PaymentTypes.GooglePay:
        return this.googlePayService;
      case PaymentTypes.Paypal:
        return this.paypalService;
      case PaymentTypes.Venmo:
        return this.venmoService;
      default:
        throw new Error('Invalid payment type');
    }
  }
}
