import { NgForOf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Question } from '@models/dynamic-forms/question';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  standalone: true,
  imports: [ReactiveFormsModule, NgForOf],
})
export class SelectInputComponent {
  @Input() form: FormGroup;
  @Input() question: Question;
}
