import { AbstractControl } from '@angular/forms';
import { Address } from '@models/address';
import { Phone } from '@models/phone';

export class AddressUpdateRequest {
  address: Address;
  phone: Phone;
  agreeTerms: boolean;

  constructor(addressContactInformation: AbstractControl = null) {
    if (!addressContactInformation) {
      return;
    }

    this.address = new Address(addressContactInformation.get('address').value);
    this.phone = new Phone(
      addressContactInformation.get('phone.number').value,
      addressContactInformation.get('phone.voicemail_allowed').value,
      addressContactInformation.get('phone.sms_allowed').value
    );
    this.agreeTerms = addressContactInformation.get('tos').value;
  }
}
