import { Component } from '@angular/core';
import { CustomerTestsTypes } from '@enums/customer-tests-types';
import { QuestionnaireAnswers } from '@enums/questionnaire-answers';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SelectableOption } from '@models/selectable-option';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-vaginal-cream',
  templateUrl: './vaginal-cream.component.html',
  styleUrls: ['./vaginal-cream.component.scss'],
})
export class VaginalCreamComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.MetronidazoleVaginalGel;
  readonly options: SelectableOption[] = [
    new SelectableOption('I prefer taking oral medication.', QuestionnaireAnswers.No),
    new SelectableOption('I prefer Metronidazole Vaginal Gel 0.75% (Metrogel).', UpsellSlugs.MetronidazoleVaginalGel),
    new SelectableOption('I prefer Clindamycin Vaginal Cream 2% (Cleocin).', UpsellSlugs.ClindamycinVaginalCream),
  ];

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Set the treatment preferences on the session storage based on the vaginal cream option selected on continue button press.
   *
   * @param {string} optionSelected The option selected by the patient.
   */
  continueButtonPressed(optionSelected: string): void {
    this.sessionStorageService.treatmentPreferences =
      optionSelected === QuestionnaireAnswers.No
        ? this.sessionStorageService.orderedTests.find(
            (test) => test.customer_tests_type === CustomerTestsTypes.Individual
          ).customer_tests_name
        : optionSelected;
  }
}
