import { ConsultationStatus } from '@enums/consultation-status';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationTypes } from '@enums/consultation-types';
import { ConsultationRequestAdditionalInfo } from '@models/consultation-request/consultation-request-additional-info';
import { ConsultationRequestRequest } from '@models/consultation-request/consultation-request-request';
import { OrderResponse } from '@models/order-response';

export class StdPreventionConsultationRequest extends ConsultationRequestRequest {
  type = ConsultationTypes.Asynchronous;
  treatment_type = ConsultationTreatmentTypes.StdPrevention;
  consultation_status: ConsultationStatus = ConsultationStatus.Incomplete;
  num_treatments: number = 1;
  /**
   * Investigate the reason to have the string type for additional_info here and on parent and why not using the type.
   * Create a custom additional info type for each consultation type.
   *
   * @Issue: https://github.com/Medology/ng-checkout/issues/1952
   */
  additional_info: string | ConsultationRequestAdditionalInfo;

  /**
   * Extracts & set consultation data from the order.
   *
   * @param {OrderResponse} order order response
   */
  setAttributesFromOrder(order: OrderResponse): void {
    this.date_of_birth = order.date_of_birth;
    this.email = order.email;
    this.first_name = order.first_name;
    this.last_name = order.last_name;
    this.order_id = order.transaction_id;
    this.sex = order.gender;
  }
}
