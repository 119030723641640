import { Component, OnInit } from '@angular/core';
import { STDcheckLoadCartOptions } from '@models/stdcheck-load-cart-options';
import { STDcheckLoadCartRequest } from '@models/stdcheck-load-cart-request';
import { Test } from '@models/test';
import { UpgradeResponse } from '@models/upgrade-response';
import { OrderService } from '@services/order.service';
import { STDcheckOrderService } from '@services/stdcheck-order.service';
import { StorageService } from '@services/storage.service';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
})
export class UpgradeComponent implements OnInit {
  upgrade: Test;
  upgradeTotal: number;

  childTests = [
    'HIV Type 1',
    'Herpes 1',
    'Hepatitis A',
    'Hepatitis C',
    'Gonorrhea',
    'HIV Type 2',
    'Herpes 2',
    'Hepatitis B',
    'Chlamydia',
    'Syphilis',
  ];

  /**
   * Get the current order total.
   */
  get currentTotal(): number {
    const testsTotalAmount = this.order.getTestsTotal();

    return Math.max(0, testsTotalAmount - this.order.getTotalCoupons(testsTotalAmount));
  }

  /**
   * Get the upgrade request.
   */
  get upgradeRequest(): STDcheckLoadCartRequest {
    return new STDcheckLoadCartRequest({
      tests: this.storage.tests,
      coupon: this.order.couponCode,
    });
  }

  constructor(private storage: StorageService, private order: OrderService) {}

  /**
   * Initialize the component.
   */
  ngOnInit(): void {
    this.storage.testsSubject.subscribe(() => this.getUpgrade(this.upgradeRequest));
  }

  /**
   * Upgrade the order.
   */
  onUpgrade(): void {
    (this.order as STDcheckOrderService).loadCart(new STDcheckLoadCartOptions(this.upgrade.slug)).subscribe();
  }

  /**
   * Check if 'hiv-rna' exists in the current tests.
   */
  hasHivRnaTest(): boolean {
    return this.storage.tests.some((test: Test) => test.slug === 'hiv-rna');
  }

  /**
   * Get available upgrade.
   */
  getUpgrade(request: STDcheckLoadCartRequest): void {
    this.order.getUpgrade(request).subscribe({
      next: this.onSuccess.bind(this),
      error: this.onError.bind(this),
    });
  }

  /**
   * Handle successful response.
   */
  onSuccess(response: UpgradeResponse): void {
    this.upgrade = response.upgrade;
    this.upgradeTotal = response.upgradeTotal;
  }

  /**
   * Handle error response.
   */
  onError(): void {
    this.upgrade = null;
    this.upgradeTotal = 0;
  }
}
