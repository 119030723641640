import { Component } from '@angular/core';
import { UpsellSlugs } from '@enums/upsell-slugs';
import { SessionStorageService } from '@services/session-storage.service';

@Component({
  selector: 'app-follow-up-std-test',
  templateUrl: './follow-up-std-test.component.html',
  styleUrl: './follow-up-std-test.component.scss',
})
export class FollowUpStdTestComponent {
  readonly slug: UpsellSlugs = UpsellSlugs.FollowUpStdTest;
  showLabFinderModal = false;

  constructor(private sessionStorageService: SessionStorageService) {}

  /**
   * Gets the name of the ordered test.
   */
  get orderTestName() {
    return this.sessionStorageService.getTreatmentPreferenceName();
  }

  /**
   * Toggle the find a lab modal.
   */
  toggleLabFinderModal() {
    this.showLabFinderModal = !this.showLabFinderModal;
  }
}
