import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DoxyPepResponse } from '@models/doxy-pep-response';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DoxyPepService {
  constructor(@Inject(APP_CONFIG) private config: AppConfig, private http: HttpClient) {}

  /**
   * Get the DoxyPep content.
   *
   * @returns an Observable of the DoxyPepResponse
   */
  getDoxyPepContent(): Observable<DoxyPepResponse> {
    return this.http
      .get(`${this.config.stdcheckPage}/json-doxy-pep`, { responseType: 'text' })
      .pipe(map((responseText) => JSON.parse(responseText)));
  }
}
