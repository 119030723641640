/**
 * Enum for the different types of customer tests.
 */
export enum CustomerTestsTypes {
  Individual = 'Individual',
  Panel = 'Panel',
  UpsellIndividual = 'Upsell Individual',
  UpsellPanel = 'Upsell Panel',
  Discount = 'Discount',
  ExtraCharge = 'Extra Charge',
}
