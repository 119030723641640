import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConsultationTreatmentTypes } from '@enums/consultation-treatment-types';
import { ConsultationRequestOrderDetail } from '@models/consultation-request/consultation-request-order-detail';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { ConsultationRequestService } from '@services/consultation-request.service';
import { LoadingService } from '@services/loading.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-consultation-request',
  templateUrl: './consultation-request.component.html',
  styleUrls: ['./consultation-request.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsultationRequestComponent implements OnInit {
  errorMessage: string;

  protected readonly ConsultationTreatmentTypes = ConsultationTreatmentTypes;

  constructor(
    @Inject(APP_CONFIG) private config: AppConfig,
    private consultationRequestService: ConsultationRequestService,
    private activatedRoute: ActivatedRoute,
    public loadingService: LoadingService,
    private titleService: Title
  ) {}

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this.titleService.setTitle(this.config.titles.consultationRequest);
    this.handleConsultationOrderDetails();
  }

  /**
   * Gets the consultation request order from the service.
   *
   * @returns {ConsultationRequestOrderDetail} the consultation request order
   */
  get consultationOrder(): ConsultationRequestOrderDetail {
    return this.consultationRequestService.consultationOrderDetail;
  }

  /**
   * Gets the headline for the checkout page.
   *
   * @returns {string} the headline for the checkout page
   */
  get headlineCheckout(): string {
    return this.config.titles.headlineCheckoutScheduled;
  }

  /**
   * Gets the headline class from the config.
   *
   * @returns {string} the headline class
   */
  get headlineClass(): string {
    return this.config.styles.consultationRequest.headlineClass;
  }

  /**
   Checks if the treatment type is UTI.

   @returns true if the treatment type is UTI, false otherwise
   */
  get isTreatmentTypeUti(): boolean {
    return this.consultationOrder?.consultationRequest.treatment_type === ConsultationTreatmentTypes.Uti;
  }

  /**
   Checks if the treatment type is Nurse.

   @returns true if the treatment type is Nurse, false otherwise
   */
  get isTreatmentTypeNurse(): boolean {
    return this.consultationOrder?.consultationRequest.treatment_type === ConsultationTreatmentTypes.Nurse;
  }

  /**
   * Gets the consultation request order from the resolver and handles the error if any.
   *
   * @returns {Subscription} a Subscription to the resolver data
   */
  private handleConsultationOrderDetails(): Subscription {
    return this.activatedRoute.data.subscribe(({ consultationOrderDetails }) => {
      if (consultationOrderDetails?.error) {
        this.errorMessage = consultationOrderDetails.errorMessage;
      }

      this.loadingService.toggleLoader(false);
    });
  }
}
