import { Inject, Injectable } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Params, Router } from '@angular/router';
import { ConsultationRequest } from '@models/consultation-request/consultation-request';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { PhoneService } from '@services/phone.service';
import { QueryParamsService } from '@services/query-params.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleOnceService {
  constructor(private readonly queryParamsService: QueryParamsService, private readonly phoneService: PhoneService) {}

  /**
   * Builds the schedule consultation URL with the necessary query params.
   *
   * @param {string} consultationId the consultation request id
   * @param {string} scheduleOnceId the schedule once id
   * @param {string} orderId the order id
   * @param {string} hash the order hash
   * @param {string} phone the phone number
   *
   * @returns {string} the URL with the query params
   */
  getScheduleConsultationUrl(
    consultationId: string,
    scheduleOnceId: string,
    orderId: string,
    hash: string,
    phone: string
  ): string {
    return this.queryParamsService.bindQueryParamsToUrl('/consultation/schedule-consultation', {
      order_id: orderId,
      hash: hash,
      consultationId: consultationId,
      scheduleOnceId: scheduleOnceId,
      contact: this.phoneService.formatPhoneNumber(phone),
    });
  }
}
