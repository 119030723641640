import { Component, Inject, OnInit } from '@angular/core';
import { HlTestCategory } from '@models/hl-test-category';
import { APP_CONFIG, AppConfig } from '@modules/config/types/config';
import { HealthLabsOrderService } from '@services/healthlabs-order.service';
import { OrderService } from '@services/order.service';

@Component({
  selector: 'app-find-tests-modal',
  templateUrl: './find-tests-modal.component.html',
  styleUrls: ['./find-tests-modal.component.scss'],
})
export class FindTestsModalComponent implements OnInit {
  testCategories: HlTestCategory[] = [];

  constructor(@Inject(APP_CONFIG) protected config: AppConfig, private orderService: OrderService) {}

  /**
   * Get modal display state
   */
  get shouldDisplayModal(): boolean {
    return (this.orderService as HealthLabsOrderService).findTestsModalState;
  }

  ngOnInit(): void {
    this.healthlabsTestCategories();
  }

  /**
   * Toggle find tests modal display state
   *
   * @param {boolean} status The modal display state
   */
  toggleFindTestsModal(status: boolean): void {
    (this.orderService as HealthLabsOrderService).findTestsModalState = status;
  }

  /**
   * Redirect to Healthlabs search page
   *
   * @param {string} searchTerm The search term
   */
  onSearch(searchTerm: string): void {
    window.location.href = `${this.config.siteUrls.tests}/search?searchQuery=${encodeURIComponent(searchTerm)}`;
  }

  /**
   * Get healthlabs test categories
   */
  private healthlabsTestCategories(): void {
    (this.orderService as HealthLabsOrderService).getTestCategories().subscribe((response) => {
      this.testCategories = response.sort((a, b) => a.name.localeCompare(b.name));
    });
  }
}
